import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { DateTime } from 'luxon';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { AssistantChatService } from '../../services/assistant-chat.service';

@Component({
    selector: 'app-chat-threads',
    templateUrl: './chat-threads.component.html',
    styleUrls: ['./chat-threads.component.scss'],
})
export class ChatThreadsComponent implements AfterViewInit {
    tutorialService = inject(AppTutorialService);

    @ViewChild('threadsWrapper') private threadsWrapper!: ElementRef;

    constructor(public assistantChatService: AssistantChatService) {
        this.assistantChatService.threadsComponent = this;
    }

    ngAfterViewInit(): void {
        this.assistantChatService.fetchThreads();
    }

    /** Comprobar si el listado de conversaciones debe agregar automáticamente mas conversaciones */
    checkThreadsScroll() {
        const container = this.threadsWrapper.nativeElement;

        if (
            container.scrollHeight <= container.clientHeight &&
            !this.assistantChatService.loadingStates.loadingThreads.value
        ) {
            this.assistantChatService.fetchNextThreads();
        }
    }

    /**
     * Calcular si el thread actual está en un día diferente con respecto al anterior
     */
    isNewDayThread(currentDate: string, previousDate?: string) {
        if (!previousDate)
            return {
                condition: true,
                date: DateTime.fromISO(currentDate).toFormat('dd/MM/yyyy'),
            };

        const current = DateTime.fromISO(currentDate);
        const previous = DateTime.fromISO(previousDate);

        return {
            condition: !current.hasSame(previous, 'day'),
            date: current.toFormat('dd/MM/yyyy'),
        };
    }
}
