export const SEARCH_TITLES = {
  DOCUMENTS: 'SEARCH_DOCUMENTS_STRING',
  STATEMENT: 'SEARCH_STATEMENT_STRING',
  LAWS: 'SEARCH_LAWS_STRING',
  VOTES: 'SEARCH_VOTES_STRING',
  TESIS: 'SEARCH_TESIS_STRING',
  STATE_LAWS: 'SEARCH_STATE_LAWS_STRING'
};

export const PROCESSING_SUMMARY_MESSAGES: string[] = [
  'Realizando un análisis del documento',
  'Creando resumen',
  'Nuestra IA está revisando el documento',
  '¡En breve tendrás el resultado!',
  'Estamos generando el resumen, gracias por tu paciencia',
  'Preparando el resumen, por favor espera unos segundos más',
  'Estamos procesando la información, ¡casi listo!',
  'Estamos trabajando en ello, gracias por esperar',
  'Resumen en proceso, esto no debería tomar mucho tiempo',
  'Haciendo un resumen rápido, gracias por esperar un momento más',
  '¡Tu resumen está en camino! Gracias por esperar mientras lo generamos',
  'Estamos procesando la información, ¿por qué no revisas tus correos mientras tanto?',
  'Tu resumen está en proceso, ¡gracias por tu paciencia!',
];

export const INVALID_SUMMARY_STRINGS = [
  '--processing--',
  '--null--',
  '--same_as_doc--',
];

export const SEARCH_TYPES = {
  // SEMANTIC: {
  //   text: '🧠',
  //   color: 'bg-indigo-500',
  //   position: 1,
  //   value: 'semantic',
  //   name: 'Búsqueda Semántica',
  //   description:
  //     'Haz clic para saber más sobre los tipos de búsqueda disponibles',
  // },
  KEY_WORD: {
    text: '🔍🔠',
    color: 'bg-sky-500',
    position: 1,
    value: 'match',
    name: 'Búsqueda por Palabra Clave',
    description:
      'Haz clic para saber más sobre los tipos de búsqueda disponibles',
  },
  EXACT_KEY_WORD: {
    text: '🔍🎯',
    color: 'bg-teal-500',
    position: 2,
    value: 'match_phrase',
    name: 'Búsqueda por Palabra Clave Exacta',
    description:
      'Haz clic para saber más sobre los tipos de búsqueda disponibles',
  },
};
