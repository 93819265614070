<div class="overflow-auto bg-pearl-100">
  <ng-container *ngFor="let folder of folders; let i = index; trackBy:trackByFn">
    <!-- Carpeta o documento -->
    <div class="-mt-px first:mt-0 group flex gap-0.5 items-center hover:bg-indigo-50 p-2"
      [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative selected':  container?.selection?.has(folder)}">
      <button
        class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible group-[.selected]:visible peer-aria-expanded:visible"
        (click)="container?.openFolder(folder)">
        <i class="fa-solid fa-chevron-right text-[0.75rem]" [ngClass]="folder.open ? 'fa-rotate-90' : ''"></i>
      </button>
      <button type="button" class="flex items-center gap-2 border border-transparent grow"
        [attr.aria-expanded]="folder.open" (click)="container?.onFolderClick(folder)"
        (dblclick)="container?.openFolder(folder)"
        [disabled]="(container?.loadingFolder | async) || container?.existingIds?.includes(folder.studio_file_id) || folder.studio_file_id == originalId">
        <ng-container
          *ngIf="!(container?.loadingFolder | async) || container?.selectedFolder?.studio_file_id != folder.studio_file_id">
          <i class="fa-regular text-[1rem] fa-folder" [ngClass]="folder.color?.replace('bg-', 'text-')"></i>
        </ng-container>
        <ng-container
          *ngIf="(container?.loadingFolder | async) && container?.selectedFolder?.studio_file_id == folder.studio_file_id">
          <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
        </ng-container>
        <span class="font-medium text-slate-600 truncate text-sm">{{ folder.name | titlecase }}</span>
        <div class="px-3 ml-auto pointer-events-none flex items-center">
          <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
            [ngClass]="container?.selection?.has(folder) ? 'visible': 'invisible'">
            <i class="fa-solid fa-check text-[0.9rem]"></i>
          </div>
        </div>
      </button>
    </div>

    <!-- Espacio para mostrar las sub-carpetas dentro una carpeta -->
    <div class="pl-4">
      <app-studio-folder-data [folders]="folder.folders" *ngIf="folder.folders && folder.open"
        [parentId]="folder.studio_file_id" [originalId]="originalId"/>
    </div>
  </ng-container>

  <!-- Empty state si no hay carpetas dentro de la carpeta actual -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-2"
    *ngIf="!folders[0] && (!isLoading() || parentId && container?.selectedFolder?.studio_file_id != parentId)">
    <div class="bg-indigo-100 size-8 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[0.8rem]"></i>
    </div>
    <span class="text-center font-medium text-sm">No encontramos documentos en esta ubicación</span>
  </div>
</div>