import {
    Component,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UisrTools } from '@utils/uisr-tools';
import { BehaviorSubject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SOURCES_WITH_IDS } from 'src/app/features/search/constants/sources-with-ids.constants';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { ResultSourceService } from 'src/app/shared/services/result-source.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { AddToCollectionComponent } from 'src/app/features/search/components/add-to-collection/add-to-collection.component';
import { SearchResult } from 'src/app/features/search/interfaces/collections.interfaces';
import { PreviewResultComponent } from 'src/app/features/search/components/preview-result/preview-result.component';

export interface SearchObjectDoc {
    doc_id: string;
    index: string;
    name: string;
    pdfUrl?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-amparo-search-object',
    templateUrl: './amparo-search-object.component.html',
    host: {
        class: 'text-slate-800 mb-1',
    },
})
export class AmparoSearchObjectComponent implements OnInit {
    private readonly translate = inject(TranslateService);
    private readonly dialog = inject(DialogService);
    private readonly api = inject(UisrApiServiceV2);
    private readonly collectionsService = inject(CollectionsService);
    private readonly sourceService = inject(ResultSourceService);
    private readonly router = inject(Router);

    totalWidth: number = window.innerWidth;
    loading = new BehaviorSubject(false);
    doc?: any;
    messages: any = {};

    @Input({ required: true }) doc_id!: string;
    @Input({ required: true }) index!: string;

    @Output() add_to_collection = new EventEmitter<SearchObjectDoc>();

    @HostListener('window:resize', []) updateVisiblePages() {
        this.totalWidth = window.innerWidth;
    }

    ngOnInit() {
        this.getMessages();
        this.getSearchObject();
    }

    private getSearchObject() {
        this.api
            .post(RESOURCES.searchDetail, {
                opensearchId: this.doc_id,
                index: this.index,
                collection: true,
            })
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: any) => {
                    this.doc = res.data;
                    if (this.doc.header) {
                        this.doc.header = this.parseHeader(this.doc.header);
                        this.doc.header = UisrTools.removeDuplicateProperties(
                            this.doc.header,
                            this.doc
                        );
                        this.doc.header = UisrTools.removeEmptyProperties(
                            this.doc.header
                        );
                    }
                    this.doc = UisrTools.removeEmptyProperties(this.doc);
                    this.doc.readableSource = SOURCES_WITH_IDS.find(
                        (s) => s.index === this.index
                    )?.label;

                    if (this.doc.name) {
                        this.doc.name = this.sourceService.parseResultTitle(
                            this.doc.name
                        );
                    }

                    if (this.doc.augmented_name) {
                        this.doc.augmented_name =
                            this.sourceService.parseResultTitle(
                                this.doc.augmented_name
                            );
                    }
                },
            });
    }

    private parseHeader(header: string) {
        const obj: any = {};
        header.split('\n').forEach((line: string) => {
            const [key, value] = line.split(': ');
            obj[key] = value;
        });

        return obj;
    }

    private getMessages() {
        this.messages = this.translate.instant([
            'FILE_NOT_AVAILABLE',
            'SEARCH_OBJECT_NOT_AVAILABLE',
        ]);
    }

    /** Abre el documento para visualización */
    openDocument() {
        if (!this.doc) return;

        if (this.doc.urlPDF) {
            this.openPdf(this.doc.urlPDF);
        } else {
            this.previewResult();
        }
    }

    /** Abre el documento en un modal */
    openPdf(urlPdf: any): void {
        this.dialog.openSidePanel(PdfViewerModalComponent, {
            data: urlPdf,
        });
    }

    /** Descarga el documento si está disponible como PDF */
    downloadDocument() {
        if (!this.doc?.urlPDF) return;

        this.downloadPdf(this.doc.urlPDF);
        return;
    }

    /** Descarga el PDF desde una URL */
    private downloadPdf(url: string): void {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.download = `${this.doc?.name || 'documento'}.pdf`;
        anchor.click();
    }

    /** Agrega el documento a una colección */
    addToCollections() {
        const parsedResult = {
            id: this.doc_id,
            index: this.index,
            name: this.doc?.augmented_name || this.doc?.name,
        };
        this.dialog
            .openDialog(AddToCollectionComponent, {
                data: parsedResult,
            })
            .subscribe((res) => {
                if (res) {
                    this.collectionsService.loadUserCollections();
                }
            });
    }

    /** Abre el documento en un modal */
    previewResult() {
        const parsedResult: SearchResult = {
            id: this.doc_id,
            index: this.index,
            name: this.doc?.augmented_name ?? this.doc?.name,
            analysisUrl: this.redirectHref(),
        };
        this.dialog.openSidePanel(PreviewResultComponent, {
            data: parsedResult,
        });
    }

    /** Redirige a la vista de detalle del documento */
    redirectHref(): string {
        const params = new URLSearchParams({
            index: this.index,
            returnUrl: this.router.url,
        });
        return `${location.origin}/search/b-search/analysis/${
            this.doc_id
        }?${params.toString()}`;
    }
}
