import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject } from 'rxjs';
import { SOURCES_WITH_IDS } from 'src/app/features/search/constants/sources-with-ids.constants';
import { SelectCollectionTabComponent } from './select-collection-tab/select-collection-tab.component';
import { SelectDossierTabComponent } from './select-dossier-tab/select-dossier-tab.component';
import { SelectStudioFileTabComponent } from './select-studio-file-tab/select-studio-file-tab.component';
import { SimpleDocPickerService } from './simple-doc-picker.service';
import { UploadFileTabComponent } from './upload-file-tab/upload-file-tab.component';
import { SelectTemplateTabWrapperComponent } from './select-template-tab/select-template-tab-wrapper/select-template-tab-wrapper.component';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';

@UntilDestroy()
@Component({
    selector: 'app-simple-doc-picker',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        NgxTippyModule,
        SelectCollectionTabComponent,
        SelectDossierTabComponent,
        SelectStudioFileTabComponent,
        UploadFileTabComponent,
        SelectTemplateTabWrapperComponent,
    ],
    templateUrl: './simple-doc-picker.component.html',
})
export class SimpleDocPickerComponent {
    readonly data? = inject(DIALOG_DATA, { optional: true });
    readonly dialogRef? = inject(DialogRef, { optional: true });
    readonly docPickerService = inject(SimpleDocPickerService);

    loading = new BehaviorSubject(false);
    tabs = [
        { label: 'Asuntos', action: 'dossiers' },
        { label: 'Colecciones', action: 'collections' },
        { label: 'Estudio', action: 'studio' },
        { label: 'Machotes', action: 'templates' },
        { label: 'Subir', action: 'upload', icon: 'fa-arrow-up-from-bracket' },
    ];
    selectedTab = this.tabs[0].action;
    /** Puede ser el nombre de una colección o un dossier que reemplaza los tabs mientras esté seleccionado */
    selectedItem?: string;

    @Input() sizeLimit?: number;
    @Input() prompt?: string;
    @Input() extensions?: string[] = [];
    selectedDossier?: DossierDTO;

    constructor() {
        if (this.data) {
            this.prompt = this.data.prompt;
            this.extensions = this.data.extensions || [];
            if (this.data.existing?.length) {
                this.docPickerService.existing =
                    this.docPickerService.buildSelectableItems(
                        this.data.existing
                    );
            }
            this.sizeLimit = this.data.sizeLimit || undefined;
        }
    }

    /** Se ejecuta cuando el usuario confirma la selección, emite los ids de los documentos seleccionados */
    onSubmit() {
        // Los documentos seleccionados desde un dossier se mandan completos junto al tipo dossierFile
        let selectionArray: any[] = Array.from(this.docPickerService.selection);

        // Los documentos seleccionados desde una colección se mandan con el index de la fuente, su id de openSearch y el tipo searchObject
        selectionArray = selectionArray.map((element) => {
            if (element.type != 'searchObject') return element;
            return {
                id: element._id,
                index: SOURCES_WITH_IDS.find((s) => s.index == element._index)
                    ?.id,
                openSearchId: element._id,
                type: 'searchObject',
                name: element.heading,
            };
        });

        this.docPickerService.selection = [];
        this.dialogRef?.close(selectionArray);
    }

    trackByFn(index: any) {
        return index;
    }

    /** Permite cambiar la pestaña seleccionada en el componente solo si la nueva pestaña es diferente a la actual */
    changeTab(action: string) {
        if (this.selectedTab != action) {
            this.selectedTab = action;
        }
    }

    /** selectedItem es el nombre de la colección o dossier seleccionado, reemplaza los tabs para que se muestre solo el nombre */
    onItemChange(item: string | undefined) {
        this.selectedItem = item;
    }

    onDossierItemChange(item: DossierDTO | undefined) {
        this.selectedItem = item?.name;
        this.selectedDossier = item;
    }

    addDossierToSelection() {
        this.docPickerService.updateSelection(this.selectedDossier);
    }
}
