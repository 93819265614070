import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RESOURCES } from '@constants/resource-service.constants';
import { APIResponse } from '@interfaces/api.interface';
import { DocPickerSelection } from '@interfaces/doc-picker.interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UisrTools } from '@utils/uisr-tools';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    Subject,
    switchMap
} from 'rxjs';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { FormatDownloadFilter } from 'src/app/features/library/interfaces/format-file-download.interfaces';
import {
    FormatFile,
    FormatFileFilter,
} from 'src/app/features/library/interfaces/format-files.interfaces';
import {
    FormatFolder,
    FormatFolderFilter,
} from 'src/app/features/library/interfaces/format-folders.interfaces';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { TemplateDataComponent } from './template-data/template-data.component';

@UntilDestroy()
@Component({
    selector: 'app-select-template-tab',
    templateUrl: './select-template-tab.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        NgxTippyModule,
        TemplateDataComponent,
    ],
})
export class SelectTemplateTabComponent {
    private readonly api = inject(UisrApiServiceV2);
    private readonly docPickerService = inject(SimpleDocPickerService);

    private appendFavorites = false;
    private appendItems = false;
    readonly rootFolderName = 'useful_formatos';
    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    items: any[] = [];
    favorites: any[] = [];
    selectedFolder?: FormatFolder;
    searchControl: FormControl<null | string> = new FormControl(null);
    filter: FormatFileFilter = {};
    filterSubject = new Subject<FormatFileFilter>();
    loadingFavorites = new BehaviorSubject(false);
    favoritesFilterSubject = new Subject<FormatDownloadFilter>();
    favoritesConfig = structuredClone(PAGINATION_CONFIG);
    favoritesFilter: FormatFileFilter = {
        pageNumber: 1,
        pageSize: 4,
        orderBy: 'displayName',
        order: 'DESC',
    };
    searchPaginationConfig = structuredClone(PAGINATION_CONFIG);

    @Input() sizeLimit?: number;
    /** Habilita un botón de regresar que emite un evento cuando se presiona */
    @Input() allowReturn = false;

    /** Se emite cuando el botón de regresar se presiona */
    @Output() returnClicked = new EventEmitter<void>();

    ngOnInit() {
        this.getFolderData();
        this.subscribeToSearch();
        this.subscribeToFavoritesFilter();
        this.loadFavorites();
        this.subscribeToFilter();
    }

    loadFavorites() {
        let filter: FormatFileFilter = {
            pageNumber: 1,
            pageSize: 4,
        };
        this.applyFavoritesFilter(filter);
    }

    /** Al realizar la búsqueda, se obtienen las carpetas buscando en todo el estudio */
    subscribeToSearch() {
        this.searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                untilDestroyed(this)
            )
            .subscribe({
                next: (val) => {
                    if (val) {
                        this.onSearch();
                    } else {
                        this.getFolderData();
                    }
                },
            });
    }

    onSearch() {
        if (this.searchControl.value) {
            this.applyFilter({
                searchValue: this.searchControl.value || undefined,
            });
            this.applyFavoritesFilter({ pageNumber: 1, pageSize: 4 });
        } else {
            this.getFolderData();
            this.loadFavorites();
        }
    }

    applyFavoritesFilter(newFilter: FormatFileFilter) {
        newFilter = UisrTools.removeEmptyProperties(newFilter);

        this.favoritesFilter = {
            ...this.favoritesFilter,
            ...newFilter,
            isFavorite: true,
        };
        this.favoritesFilter.searchValue =
            this.searchControl.value ?? undefined;

        this.favoritesFilter = UisrTools.removeEmptyProperties(
            this.favoritesFilter
        );

        this.favoritesFilterSubject.next(this.favoritesFilter);
    }

    subscribeToFavoritesFilter() {
        this.favoritesFilterSubject
            .pipe(
                distinctUntilChanged(),
                switchMap((filter) => {
                    this.favoritesConfig.currentPage = filter.pageNumber || 1;
                    this.favoritesConfig.itemsPerPage = filter.pageSize || 4;
                    return this.api
                        .get(RESOURCES.formatFile, filter)
                        .pipe(loadingState(this.loadingFavorites));
                }),
                untilDestroyed(this)
            )
            .subscribe((res: APIResponse<FormatFile[]>) => {
                this.favoritesConfig.totalItems = res.total || 0;

                if (this.appendFavorites) {
                    this.favorites = this.docPickerService.buildSelectableItems(
                        [...this.favorites, ...res.data]
                    );
                } else {
                    this.favorites = this.docPickerService.buildSelectableItems(
                        [...res.data]
                    );
                }

                this.appendFavorites = false;
            });
    }

    subscribeToFilter() {
        this.filterSubject
            .pipe(
                distinctUntilChanged(),
                switchMap((filter) => {
                    this.searchPaginationConfig.currentPage =
                        filter.pageNumber || 1;
                    this.searchPaginationConfig.itemsPerPage =
                        filter.pageSize || 10;
                    return this.api
                        .get(RESOURCES.formatFile, filter)
                        .pipe(loadingState(this.loading));
                }),
                untilDestroyed(this)
            )
            .subscribe((res: APIResponse<FormatFile[]>) => {
                if (this.appendItems) {
                    this.items = this.docPickerService.buildSelectableItems([
                        ...this.items,
                        ...res.data,
                    ]);
                } else {
                    this.items = this.docPickerService.buildSelectableItems([
                        ...res.data,
                    ]);
                }

                this.appendItems = false;
                this.searchPaginationConfig.totalItems = res.total || 0;
            });
    }

    applyFilter(filter: FormatFileFilter) {
        this.filter = {
            ...this.filter,
            ...filter,
        };

        this.filter = UisrTools.removeEmptyProperties(this.filter);

        this.filterSubject.next(this.filter);
    }

    getFolderData(folder?: FormatFolder) {
        const currentFolder = folder?.name || this.rootFolderName;
        this.selectedFolder = undefined;
        const filter: FormatFolderFilter = {
            name: currentFolder,
        };

        if (folder) {
            this.selectedFolder = folder;
            filter.idParentFolder = Number(this.selectedFolder.idFolder);
        }

        this.api
            .get(`${RESOURCES.formatFolder}/get-by-name`, filter)
            .pipe(
                loadingState(this.loading),
                loadingState(this.loadingFolder),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res: APIResponse<any>) => {
                    if (folder) {
                        folder.folders = [
                            ...res.data.folders,
                            ...this.docPickerService.buildSelectableItems(
                                res.data.files
                            ),
                        ];
                        folder.open = true;
                    } else {
                        this.items = [
                            ...res.data.folders,
                            ...this.docPickerService.buildSelectableItems(
                                res.data.files
                            ),
                        ];
                    }

                    this.selectedFolder = undefined;
                },
            });
    }

    viewMoreFavorites() {
        this.appendFavorites = true;
        this.applyFavoritesFilter({
            pageNumber: this.favoritesConfig.currentPage + 1,
        });
    }

    viewMoreSearchResults() {
        this.appendItems = true;
        this.applyFilter({
            pageNumber: this.searchPaginationConfig.currentPage + 1,
        });
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al hacer click en una carpeta, se agrega o elimina del arreglo de elementos seleccionados */
    onItemClick(item: DocPickerSelection<FormatFile> | FormatFolder) {
        if ('idFolder' in item) {
            if (item.folders && item.open) {
                item.open = false;
            } else if (!item.folders) {
                this.getFolderData(item);
            } else {
                item.open = true;
            }
        } else {
            this.docPickerService.updateSelection(item);
        }
    }
}
