import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BlockTypes } from 'src/app/core/interfaces/blockType.interface';

@Injectable({
  providedIn: 'root',
})
export class UisrApiServiceV2 {
  httpClient: HttpClient;
  constructor(private _httpClient: HttpClient) {
    this.httpClient = this._httpClient;
  }

  get(resource: string, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .get<any>(resource, { params: params, headers: { blockType: blockType.toString(), path: resource } })
      .pipe(map((res: any) => res));
  }

  put(resource: string, data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .put<any>(resource, data, { params: params, headers: { blockType: blockType.toString(), path: resource } })
      .pipe(map((res: any) => res));
  }

  patch(resource: string, data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .patch<any>(resource, data, { params: params, headers: { blockType: blockType.toString(), path: resource } })
      .pipe(map((res: any) => res));
  }

  post(resource: string, data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .post(resource, data, { params: params, headers: { blockType: blockType.toString(), path: resource } })
      .pipe(map((res: any) => res));
  }

  delete(resource: string, data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .delete(resource, { params: params, body: data, headers: { blockType: blockType.toString(), path: resource } })
      .pipe(map((res: any) => res));
  }

  getFile(resource: string, body: any, paramsObject?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<Blob> {
    const params = new HttpParams({ fromObject: paramsObject });
    const headers = new HttpHeaders({ blockType: blockType.toString(), path: resource });

    return this.httpClient
      .post<Blob>(resource, body, { params, headers, responseType: 'blob' as 'json' })
  }

  postFile(resource: string, data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    let formData = new FormData();
    formData.append('file', data);
    return this.httpClient
      .post(resource, formData, { params: params, headers: { blockType: blockType.toString(), path: resource, responseType: 'arraybuffer' } })
      .pipe(map((res: any) => res));
  }

  getCertificate(resource: string, body: any, paramsObject?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<Blob> {
    const params = new HttpParams({ fromObject: paramsObject });
    const headers = new HttpHeaders({ blockType: blockType.toString(), path: resource });

    return this.httpClient
      .get<Blob>(resource, { params, headers, responseType: 'blob' as 'json' })
  }

  getBlobByGet(resource: string, paramsObject?: any): Observable<Blob> {
    const params = new HttpParams({ fromObject: paramsObject });
    return this.httpClient.get(resource, { params, responseType: 'blob' });
  }
  
}
