<div class="@container bg-white text-slate-800 rounded-lg border border-slate-200 shadow-sm mb-1 flex flex-col"
  *ngIf="doc && !(loading | async)">
  <div class="flex min-w-0 gap-4 p-4 ">
    <div class="rounded w-24 h-28 bg-slate-50 border border-slate-200 flex flex-col px-3 py-2 cursor-pointer shrink-0"
      (click)="editDocx()">
      <div class="flex items-center justify-center my-auto text-xl">
        📄⚖️
      </div>
    </div>
    <div class="flex flex-col min-w-0 grow">
      <span class="truncate text-left leading-5 text-slate-800 font-semibold gap-4 text-xl"
        [ngxTippy]="doc.name.replace('.docx', '')">
        {{ doc.name.replace('.docx', '') }}
      </span>
      <div class="text-sm text-slate-500">{{doc.created_at | date:"dd.MM.yyyy"}} • {{doc.extension}}</div>
      <div class="flex items-center gap-2 mt-8 overflow-x-auto min-w-0" *ngIf="!chatService.componentStates.editor">
        <button (click)="editDocx()"
          class="flex items-center gap-2 px-2 py-1 border border-[#4F48ED] rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-file-pen"></i>
          <span class="hidden @md:inline-block">Editar</span>
        </button>
        <button (click)="downloadOrPreview()"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-download"></i>
          <span class="hidden @md:inline-block">Descargar</span>
        </button>
        <button (click)="copyTo()"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800"
          ngxTippy="Copiar a otra área">
          <i class="fa-regular fa-copy"></i>
          <span class="hidden @md:inline-block">Copiar</span>
        </button>
      </div>
      <div class="flex items-center gap-2 mt-8 overflow-x-auto min-w-0">
        <span class="text-sm font-medium text-slate-600" *ngIf="chatService.componentStates.editor">
          Editando archivo
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(loading | async)">
  <div class="border-2 border-slate-200 rounded-lg p-2 animate-pulse flex gap-2">
    <div class="w-32 h-36 rounded-lg bg-slate-200"></div>
    <div class="flex flex-col gap-1">
      <div class="w-40 h-6 rounded-md bg-slate-200"></div>
      <div class="w-20 h-6 rounded-md bg-slate-200"></div>
    </div>
  </div>
</div>