<div class="overflow-y-auto bg-pearl-100 max-h-[85svh] sticky top-0 z-10">
    <div class="relative">
        <label for="modal-search" class="sr-only">{{'SEARCH_PLACEHOLDER_STRING' | translate}}</label>
        <input id="modal-search" [(ngModel)]="collectionsSearchValue" (ngModelChange)="collectionSearch($event)" class="w-full bg-pearl-100 border-0 focus:ring-transparent placeholder-slate-400 dark:placeholder-slate-200 appearance-none py-3 pl-12 px-5" type="search" placeholder="Buscar...">
        <button class="absolute inset-0 right-auto group" type="submit" aria-label="Buscar">
            <svg class="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-6 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"></path>
                <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"></path>
            </svg>
        </button>
    </div>
</div>
<div class="overflow-auto  max-h-[calc(85svh-7.5rem)]" *ngIf="!(loadingCollections| async)">
    <cdk-accordion class="flex border-t border-slate-200 flex-col" *ngIf="!isFavorite()">
        <cdk-accordion-item [expanded]="true" class="block" role="button" tabindex="0"
            #accordionItem="cdkAccordionItem" [attr.aria-expanded]="accordionItem.expanded">
            <div class="flex justify-between items-center gap-2 border-b border-slate-200 py-2 bg-gray-100/50" (click)="accordionItem.toggle()">
                <div class="flex pl-5 flex-col justify-between">
                    <h3 class="text-sm leading-snug text-slate-800 font-semibold">
                        {{'DEFAULT_STRING' | translate}}
                    </h3>
                </div>
                <button class="hover:border-slate-300 pr-5 text-slate-400 items-center">
                    <i class="fa-solid fa-angle-down" [ngClass]="{'rotate-180 transition-all duration-300': accordionItem.expanded}"></i>
                </button>
            </div>
            <div
                [ngClass]="{'mt-2': accordionItem.expanded}"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.aria-labelledby]="'accordion-header-'">
                <ul class="text-sm my-3 px-5" *ngIf="userCollections()[0] && !isFavorite()">
                    <li *ngFor="let collection of userCollections(); let i = index">
                        <ng-container *ngIf="collection.fk_id_type_collection == 1">
                            <div (click)="markCollectionAsActive(collection)" [ngClass]="{'active': collection.active}"
                                class="bg-pearl-100 dark:hover:bg-mirage shadow-sm rounded border border-slate-200 dark:border-slate-700 p-2 hover:text-primary-100 active:text-primary-100 active:bg-indigo-50 mb-1 cursor-pointer secondary-icon hover:primary-icon active:primary-icon">
                                <div class="sm:flex sm:justify-between sm:items-start">
                                    <!-- Left side -->
                                    <div class="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
                                        <div class="flex items-center">
                                            <i class="fa-duotone fa-rectangle-history fa-lg mr-3"></i>
                                            <span>{{ collection.name_collection | titlecase }}</span>
                                        </div>
                                    </div>
                                    <!-- Right side -->
                                    <div class="flex items-center justify-end space-x-2 text-slate-400" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="'Resultados Existentes'">
                                        <i class="fa-duotone fa-memo-circle-check primary-icon" style="font-size: 1rem;"></i>
                                        <div class="text-sm text-slate-500">{{collection.search_objects.length}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </li>
                </ul>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
    <cdk-accordion class="flex border-t border-slate-200 flex-col" [ngClass]="!collectionsSearchValue && userCollections()[1] || ( matchingCollections && collectionsSearchValue) ? '' : 'hidden' ">
        <cdk-accordion-item
            [expanded]="true"
            #accordionItem="cdkAccordionItem" class="block" role="button" tabindex="0"
            [attr.aria-expanded]="accordionItem.expanded">
            <div class="flex justify-between items-center gap-2 border-b border-slate-200  py-2 bg-gray-100/50" (click)="accordionItem.toggle()">
                <div class="flex pl-5 flex-col justify-between">
                    <h3 class="text-sm leading-snug text-slate-800 font-semibold">
                        {{'MY_COLLECTIONS' | translate}}
                    </h3>
                </div>
                <div class="flex gap-2 justify-end">
                    <button class="btn-sm dark:text-primary-100 flex justify-end right-0 text-primary-100 hover:text-primary-300 border-slate-200"
                        ngxTippy data-tippy-content="Crear Colección" [tippyProps]="{placement: 'top'}" (click)="addCollection()">
                        <i class="fa-duotone fa-rectangle-history-circle-plus" style="font-size: 1rem;"></i>
                    </button>

                    <button class="hover:border-slate-300 pr-5 justify-end text-slate-400 items-center">
                        <i class="fa-solid fa-angle-down" [ngClass]="{'rotate-180 transition-all duration-300': accordionItem.expanded}"></i>
                    </button>
                </div>
            </div>
            <div
                [ngClass]="{'mt-2': accordionItem.expanded}"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.aria-labelledby]="'accordion-header-'">
                <ul *ngIf="userCollections()[0]" class="text-sm my-3 px-5">
                    <li *ngFor="let collection of userCollections(); let i = index" [attr.id]="'collection-search-' + collection.id_collection_user">
                        <div *ngIf="collection.fk_id_type_collection != 1">
                            <div (click)="markCollectionAsActive(collection)" [ngClass]="{'active': collection.active}"
                                class="bg-pearl-100 dark:hover:text-primary-100 dark:text-pearl-100 dark:hover:bg-mirage shadow-sm rounded border border-slate-200 p-2 hover:text-primary-100 active:text-primary-100 dark:active:text-primary-100 dark:active:bg-mirage active:bg-indigo-50 mb-1  cursor-pointer secondary-icon hover:primary-icon active:primary-icon">
                                <div class="flex justify-between items-start">
                                    <div class="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
                                        <div class="flex items-center gap-2">
                                            <span>{{ collection.name_collection | titlecase }}</span>
                                            <div class="grid place-items-center">
                                                <i *ngIf="includesResult(collection)" class="fa-duotone fa-circle-check bookmark-primary-icon" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="'El resultado ya existe en esta colección.'"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-end space-x-2 text-slate-400" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="'Resultados Existentes'">
                                        <i class="fa-duotone fa-memo-circle-check primary-icon" style="font-size: 1rem;"></i>
                                        <div class="text-sm text-slate-500">{{collection.search_objects.length}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
    <cdk-accordion class="flex flex-col border-t border-slate-200 gap-3" [ngClass]="dossierCollections()[0] && !collectionsSearchValue || (matchingDossierCollections && collectionsSearchValue) ? '' : 'hidden'">
        <cdk-accordion-item
            [expanded]="true"
            #accordionItem="cdkAccordionItem" class="block" role="button" tabindex="0"
            [attr.aria-expanded]="accordionItem.expanded">
            <div class="flex justify-between items-center gap-2 border-b border-slate-200 bg-gray-100/50 py-2" (click)="accordionItem.toggle()">
                <div class="flex pl-5 flex-col justify-between">
                    <h3 class="text-sm leading-snug text-slate-800 font-semibold">
                        {{'MY_MATTERS' | translate}}
                    </h3>
                </div>
                <button class="hover:border-slate-300 pr-5 text-slate-400 items-center">
                    <i class="fa-solid fa-angle-down" [ngClass]="{'rotate-180 transition-all duration-300': accordionItem.expanded}"></i>
                </button>
            </div>
            <div
                [ngClass]="{'mt-2': accordionItem.expanded}"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.aria-labelledby]="'accordion-header-'">
                <ul class="text-sm my-3 px-5" *ngIf="dossierCollections().length > 0">
                    <li *ngFor="let collection of dossierCollections(); let i = index" [attr.id]="'collection-search-' + collection.id_collection_user">
                        <div (click)="markCollectionAsActive(collection)" [ngClass]="{'active': collection.active}"
                            class="bg-pearl-100 dark:hover:text-primary-100 dark:text-pearl-100 dark:hover:bg-mirage shadow-sm rounded border border-slate-200 p-2 hover:text-primary-100 active:text-primary-100 dark:active:text-primary-100 dark:active:bg-mirage active:bg-indigo-50 mb-1 cursor-pointer secondary-icon hover:primary-icon active:primary-icon">
                            <div class="sm:flex sm:justify-between sm:items-start">
                                <div class="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
                                    <div class="flex items-center gap-2">
                                        <span>{{ collection.name_collection | titlecase }}</span>
                                        <div class="grid place-items-center">
                                            <i *ngIf="includesResult(collection)" class="fa-duotone fa-circle-check bookmark-primary-icon" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="'El resultado ya existe en esta colección.'"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center justify-end space-x-2 text-slate-400" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="'Resultados Existentes'">
                                    <i class="fa-duotone fa-memo-circle-check primary-icon" style="font-size: 1rem;"></i>
                                    <div class="text-sm text-slate-500">{{collection.search_objects.length}}</div>
                                </div>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
    <div class="flex justify-end pr-5" [ngClass]="isFavorite() ? 'border-t border-slate-200 pt-4' : 'mt-4'" *ngIf="!dossierCollections()[0] && !userCollections()[1] && !collectionsSearchValue || ( !matchingDossierCollections && !matchingCollections && collectionsSearchValue)">
        <button class="btn-sm dark:text-primary-100 flex right-0 text-primary-100 hover:text-primary-300 border-slate-200"
            ngxTippy data-tippy-content="Crear Colección" [tippyProps]="{placement: 'top'}" (click)="addCollection()">
            <i class="fa-duotone fa-rectangle-history-circle-plus" style="font-size: 1rem;"></i>
        </button>
    </div>
    <div class="mt-2 mb-4 flex flex-col items-center gap-1 mx-auto" *ngIf=" !dossierCollections()[0] && !userCollections()[1] && !collectionsSearchValue || ( !matchingDossierCollections && !matchingCollections && collectionsSearchValue)">
        <i class="fa-duotone fa-folder-open primary-icon" style="font-size:1.4rem;"></i>
        <span class="text-center text-sm">{{ 'NO_MATTERS_COLLECTIONS_FOUND' | translate }}</span>
    </div>
</div>
<div class="overflow-auto px-5 pt-4 max-h-[calc(85svh-11.4rem)]" *ngIf="(loadingCollections | async) && (loading)">
    <div class="flex justify-between items-center gap-2 animate-pulse mb-3 w-full" *ngFor="let _ of [].constructor(3)">
        <div class="space-y-2 grow">
            <div class="h-6 rounded bg-gray-300 w-6/12"></div>
            <div class="h-6 rounded bg-gray-300 w-4/12"></div>
        </div>
        <div class="h-6 w-6 rounded p-2 bg-gray-300"></div>
    </div>
</div>
<div class="px-5 py-4 border-t bg-pearl-100 border-slate-200   sticky bottom-0">
    <div class="flex flex-wrap justify-end space-x-2">
        <button [loadingElement]="resources.addDocumentToCollection" [disableOnly]="true" class="uisr-secondary-button" (click)="dialogRef.close()">{{ 'CANCEL_STRING' | translate | titlecase}}</button>
        <button [loadingElement]="resources.addDocumentToCollection" class="uisr-primary-button" (click)="addDocumentToCollection()">{{ 'ADD_STRING' | translate }}</button>
    </div>
</div>