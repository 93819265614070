import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SelectStudioFileTabComponent } from '../../select-studio-file-tab/select-studio-file-tab.component';
import { SelectTemplateTabComponent } from '../select-template-tab.component';
import { STUDIO_FOLDER_TYPES } from 'src/app/features/law-firm/constants/studio-folder-types.constants';

@Component({
    selector: 'app-select-template-tab-wrapper',
    standalone: true,
    imports: [
        CommonModule,
        SelectTemplateTabComponent,
        SelectStudioFileTabComponent,
    ],
    templateUrl: './select-template-tab-wrapper.component.html',
})
export class SelectTemplateTabWrapperComponent {
    selectedFolderType?: string;
    /** Tipos de machotes, se categorizan porque los machotes subidos por el usuario, se guardan en el asunto y se deben vectorizar
     *  Los machotes de MiDespacho, no se vectorizan y se guardan en la tabla de tbl_format_files
     */
    machotesTypes = [
        {
            label: 'Predefinidos MiDespacho',
            value: 'mi-despacho',
        },
        {
            label: 'Despacho',
            value: 'compartido',
        },
        {
            label: 'Personales',
            value: 'personal',
        },
    ];
    readonly machotesDespachoType = STUDIO_FOLDER_TYPES.SHARED_MACHOTES;
    readonly machotesPersonalType = STUDIO_FOLDER_TYPES.PERSONAL_MACHOTES;

    @Input() sizeLimit?: number;
    @Input() extensions?: string[] = [];

    selectType(type: string) {
        if (this.selectedFolderType === type) {
            this.selectedFolderType = undefined;
        } else {
            this.selectedFolderType = type;
        }
    }
    
    onReturnClicked() {
        this.selectedFolderType = undefined;
    }
}
