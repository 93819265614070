import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryViewerData } from '../../interfaces/summary-viewer.interfaces';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-summary-viewer',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgxTippyModule],
  templateUrl: './summary-viewer.component.html',
})
export class SummaryViewerComponent {
  summary: any;
  title: any;

  constructor(
    @Inject(DIALOG_DATA) public data: SummaryViewerData,
    public dialogRef: DialogRef
  ) {
    this.summary = data.summary;
    this.title = data.title;
  }
}
