<li class="-mx-2 -intro-x">
    <div class="group flex items-center p-2 rounded relative"
        [ngClass]="isActive ? 'bg-indigo-100 active' : 'hover:bg-white'">
        <button
            class="rounded whitespace-nowrap overflow-hidden text-left focus:outline-none font-medium grow text-sm text-slate-800"
            (click)="onActivateThread()" [ngxTippy]="thread.name">
            <span *ngIf="!thread?.typedId">{{ thread.name }}</span>
            <div *ngIf="thread?.typedId" class="truncate">
                <span [id]="thread.typedId"></span>
                &nbsp;
            </div>
        </button>

        <button (click)="onDeleteThread()" [ngxTippy]="'Eliminar'"
            [ngClass]="deleting.value ? 'grid':'hidden group-hover:grid'"
            class="absolute z-10 bottom-0 top-0 to-transparent right-0 bg-gradient-to-l w-14 from-70% from-white group-[.active]:from-indigo-100 ml-auto place-content-center rounded font-medium text-slate-400 hover:text-rose-500">
            <i class="fa-regular" [ngClass]="deleting.value ? 'fa-spinner-third animate-spin' : 'fa-trash-xmark'"
                style="font-size: 1rem;"></i>
        </button>
        <div
            class="absolute z-[1] bottom-0 top-0 to-transparent right-0 bg-gradient-to-l w-8 from-70% from-white group-[.active]:from-indigo-100 ml-auto place-content-center rounded font-medium pointer-events-none">
        </div>
    </div>
</li>