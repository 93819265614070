<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center"
  *ngIf="dialogRef && !embedded">
  <span class="font-semibold text-primary-100" translate="COPY_DOCUMENT"></span>
  <!-- Cerrar el modal -->
  <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
    class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
    (click)="dialogRef.close()" id="close-doc-picker">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid text-[1rem] fa-xmark"></i>
  </button>
</div>

<div [ngClass]="{'pt-5': !embedded}" class="max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl" id="doc-picker-container">
  <div class="py-2 px-5 flex border-b border-slate-200">
    <!-- Input de búsqueda -->
    <div class="relative grow">
      <label for="simple-doc-picker-search" class="sr-only" translate="SEARCH_PLACEHOLDER_STRING"></label>
      <input (keydown.enter)="$event.preventDefault();onSearch()" id="simple-doc-picker-search"
        [formControl]="searchControl" class="form-input w-full !pr-14" type="search"
        placeholder="Busca una carpeta por su nombre">
      <div class="absolute flex gap-2 right-3 top-2/4 -translate-y-2/4">
        <button type="submit" *ngIf="searchControl.value" aria-label="Limpiar" ngxTippy data-tippy-content="Limpiar"
          (click)="clearSearch()" class="px-2 border-r border-slate-200" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone error-icon fa-xmark text-[0.8rem]" style="--fa-secondary-opacity: 0.8 !important"></i>
        </button>
        <button (click)="onSearch()" type="submit" aria-label="Buscar" ngxTippy
          data-tippy-content="Clic aquí para buscar" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone fa-magnifying-glass secondary-icon text-[0.8rem]"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Empty state si no hay carpetas -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
    *ngIf="!folders[0] && !(loading | async)">
    <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[2rem]"></i>
    </div>
    <span class="text-center font-medium" translate="NO_FOLDERS_FOUND"></span>
  </div>

  <!-- Listado de carpetas -->
  <div class="pl-5 pr-4 overflow-auto">
    <app-studio-folder-data [folders]="folders" [originalId]="originalId"/>
  </div>

  <!-- Loader de carpetas -->
  <div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5" *ngIf="(loading | async)">
    <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
      <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
      <div class="flex flex-col gap-1 *:bg-gray-200 grow">
        <div class="w-9/12 h-3 rounded"></div>
        <div class="w-5/12 h-3 rounded"></div>
      </div>
    </div>
  </div>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2"
  *ngIf="dialogRef && !embedded">
  <button [disabled]="loading | async" class="uisr-secondary-button" (click)="dialogRef.close()">
    <span translate="CANCEL_STRING"></span>
  </button>
  <button class="uisr-primary-button flex justify-center items-center gap-1" (click)="onSubmit()"
    [disabled]="!selection.size">
    <span translate="COPY_STRING"></span>
  </button>
</div>