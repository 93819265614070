export const INDEX_LIST = {
    CJF: 'cjf-documents',
    SCJN: 'scjn-documents',
    LEYES: 'leyes-federales-documents',
    LEYES_ESTATALES: 'leyes-estatales-documents',
};

export const SOURCES_WITH_IDS = [
    {
        label: 'Todas',
        id: 0,
    },
    {
        label: 'CJF',
        id: 2,
        index: INDEX_LIST.CJF,
    },
    {
        label: 'SCJN',
        id: 1,
        index: INDEX_LIST.SCJN,
    },
    {
        label: 'Leyes',
        id: 3,
        index: INDEX_LIST.LEYES,
    },
    {
        label: 'Leyes Estatales',
        id: 4,
        index: INDEX_LIST.LEYES_ESTATALES,
    },
];
