import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { QuickPromptTemplate } from '../quick-prompt-template-selection.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-quick-prompt-template-preview',
    standalone: true,
    imports: [CommonModule, NgxTippyModule, TranslateModule],
    templateUrl: './quick-prompt-template-preview.component.html',
})
export class QuickPromptTemplatePreviewComponent {
    private readonly dialogRef = inject(DialogRef);
    private readonly dialogData = inject(DIALOG_DATA);

    promptInfo?: QuickPromptTemplate;

    ngOnInit() {
        this.promptInfo = this.dialogData.promptInfo;
    }

    close() {
        this.dialogRef.close();
    }
}
