<div
    class="relative bg-slate-100 max-h-[100svh] overflow-y-auto overflow-x-hidden flex flex-col transition-transform duration-300 ease-in-out w-svw max-w-3xl">
    <button (click)="dialogRef.close()" class="z-20 absolute top-4 left-4 opacity-80 hover:opacity-100">
        <span class="sr-only">{{ 'CLOSE_RESULT' | translate }}</span>
        <i class="fa-duotone fa-circle-xmark error-icon" style="font-size:2rem"></i>
    </button>
    <div class="relative min-h-[100svh]" *ngIf="resultDetail && index && !loading()">
        <div class="relative h-28 bg-gradient-to-bl from-indigo-50 to-indigo-200 overflow-hidden"></div>
        <div class="px-4 pb-4">
            <div class="-mt-16 mb-6 sm:mb-3">
                <div class="flex flex-col items-center sm:flex-row sm:justify-between sm:items-end">
                    <div class="inline-flex mb-4 sm:mb-0 bg-pearl-100 rounded-full z-10">
                        <img class="rounded-full border-4 h-32 w-32" [ngClass]="sourceService.getBorderColor(index)"
                            [src]="sourceService.getLogo(index)" alt="Fuente" />
                    </div>
                    <div class="flex space-x-2 sm:mb-2">
                        <a role="button"
                            class="group bg-white p-1.5 rounded border border-slate-200 hover:border-slate-300 shadow-sm min-w-[2.375rem] grid place-content-center"
                            [ngxTippy]="'Abrir en una nueva pestaña'" target="_blank" [href]="data.analysisUrl"
                            [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i class="fa-duotone fa-arrow-up-right-from-square secondary-icon group-hover:bookmark-primary-icon"
                                style="font-size: 1.1rem;"></i>
                        </a>
                        <button *ngIf="isFavorite()"
                            class="group bg-white p-1.5 rounded border border-slate-200 hover:border-slate-300 shadow-sm min-w-[2.375rem] grid place-content-center"
                            [ngxTippy]="'Eliminar de favoritos.'" (click)="manageFavorite(resultDetail)"
                            [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i class="error-icon fa-duotone fa-bookmark-slash opacity-75 group-hover:opacity-100"
                                style="font-size: 1.1rem;"></i>
                        </button>
                        <button *ngIf="!isFavorite()"
                            class="group bg-white p-1.5 rounded border border-slate-200 hover:border-slate-300 shadow-sm min-w-[2.375rem] grid place-content-center"
                            [ngxTippy]="'Agregar a favoritos.'" (click)="manageFavorite(resultDetail)"
                            [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i class="secondary-icon group-hover:bookmark-primary-icon fa-duotone fa-bookmark"
                                style="font-size: 1.1rem;"></i>
                        </button>
                        <button
                            class="group bg-white p-1.5 rounded border border-slate-200 hover:border-slate-300 shadow-sm min-w-[2.375rem]"
                            [ngxTippy]="'Agregar a una colección.'" (click)="addToCollections(resultDetail)"
                            [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i class="secondary-icon group-hover:bookmark-primary-icon fa-duotone fa-grid-2-plus"
                                style="font-size: 1.1rem;"></i>
                        </button>
                        <button
                            class="group bg-white p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm min-w-[2.375rem]"
                            [ngxTippy]="'Reportar Resultado'" (click)="startReport()"
                            [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i
                                class="fa-duotone fa-brake-warning report-icon group-hover:report-icon-hover dark:secondary-icon dark:hover:primary-icon"></i>
                        </button>
                        <button class="uisr-primary-button" *ngIf="resultDetail.downloadedPDF"
                            [ngxTippy]="resultDetail.numPagesPDF ? ('Número de Páginas: ' + resultDetail.numPagesPDF) : null"
                            (click)="openPdf(resultDetail.urlPDF)" [tippyProps]="{placement: 'top', hideOnClick: true}">
                            <i class="fa-duotone fa-file-pdf"
                                style="font-size: 1.1rem; --fa-primary-color: #FBFDFE; --fa-secondary-color: #ffffff;"></i>
                            <span class="ml-2">{{ 'VIEW_PDF_STRING' | translate }}</span>
                        </button>
                        <button *ngIf="resultDetail.urlPDF && [leyesEstatales, leyesFederales].includes(index)"
                            class="uisr-primary-button" (click)="openPdf(resultDetail.urlPDF)">
                            <i class="fa-duotone fa-file-pdf"
                                style="font-size: 1.1rem; --fa-primary-color: #FBFDFE; --fa-secondary-color: #ffffff;"></i>
                            <span class="ml-2">{{ 'VIEW_PDF_STRING' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <header class="text-center sm:text-left mb-6">
                <div class="inline-flex items-start">
                    <h1 class="text-2xl text-slate-800 font-bold">
                        {{sourceService.parseResultTitle((resultDetail.augmented_name || resultDetail.name))}}</h1>
                </div>
                <p *ngIf="resultDetail.numExpediente"
                    class="w-full font-semibold text-slate-800 whitespace-nowrap text-ellipsis inline-block truncate"
                    title="{{resultDetail.numExpediente | titlecase}}">
                    Expediente: {{ resultDetail.numExpediente }}
                </p>
                <p *ngIf="resultDetail.numero"
                    class="w-full font-semibold text-slate-800 whitespace-nowrap text-ellipsis inline-block truncate"
                    title="{{resultDetail.numero | titlecase}}">
                    Registro Digital: {{ resultDetail.numero }}
                </p>
                <p *ngIf="resultDetail.version"
                    class="w-full font-semibold text-slate-800 whitespace-nowrap text-ellipsis inline-block truncate"
                    title="{{resultDetail.version | titlecase}}">
                    Reforma: {{ resultDetail.version }}
                </p>
                <ul *ngIf="index == 'cjf-documents' && (resultDetail.circuito || resultDetail.tipoOrgano || resultDetail.especialidadOrgano)"
                    class="inline-flex flex-wrap text-sm font-medium">
                    <li *ngIf="resultDetail.circuito"
                        class="after:content-['/'] last:after:hidden after:text-slate-400 after:px-2">
                        <span>{{ resultDetail.circuito | titlecase }}</span>
                    </li>
                    <li *ngIf="resultDetail.tipoOrgano"
                        class="after:content-['/'] last:after:hidden after:text-slate-400 after:px-2">
                        <span>{{ resultDetail.tipoOrgano }}</span>
                    </li>
                    <li *ngIf="resultDetail.especialidadOrgano"
                        class="after:content-['/'] last:after:hidden after:text-slate-400 after:px-2">
                        <span>{{ resultDetail.especialidadOrgano }}</span>
                    </li>
                </ul>
                <div class="w-full mt-2 gap-2 flex flex-wrap items-center"
                    *ngIf="resultDetail.keywords && resultDetail.keywords[0]">
                    <div class="text-xs font-semibold w-full">{{'KEY_WORDS_STRING' | translate}}: </div>
                    <ng-container *ngFor="let keyword of resultDetail.keywords; let i = index">
                        <span *ngIf="keyword.length >= 1"
                            class="text-xs inline-flex font-medium bg-slate-200 text-slate-500 rounded-full text-center px-2.5 py-1 border">
                            {{ keyword }}
                        </span>
                    </ng-container>
                </div>
            </header>
            <div class="relative mb-6">
                <div class="absolute bottom-0 w-full h-px bg-slate-200" aria-hidden="true"></div>
                <ul
                    class="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
                    <li
                        class="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                        <a role="button" class="block pb-3 whitespace-nowrap"
                            [ngClass]="currentTab == 0 ? 'text-primary-100 dark:text-primary-100 border-b-2 border-indigo-500' : 'text-slate-500 hover:text-slate-600'"
                            (click)="changeTab(0)">{{ 'INFORMATION_STRING' | translate }}</a>
                    </li>
                    <li *ngIf="index == 'scjn-documents' && resultDetail.body"
                        class="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8">
                        <a role="button" class="block pb-3 whitespace-nowrap"
                            [ngClass]="currentTab == 1 ? 'text-primary-100 dark:text-primary-100 border-b-2 border-indigo-500' : 'text-slate-500 hover:text-slate-600'"
                            (click)="changeTab(1)">{{ 'CONTENT_STRING' | translate }}</a>
                    </li>
                </ul>
            </div>

            <div class="flex flex-col gap-5 max-w-full grow" *ngIf="currentTab == 0">
                <div *ngIf="resultDetail.fastSummary && !invalidSummaries.has(resultDetail.fastSummary)">
                    <h2 class="text-slate-800 font-semibold mb-2">{{ 'SUMMARY_STRING' | translate }}</h2>
                    <div
                        class="text-sm bg-pearl-100 border-slate-200   dark:border-slate-700 p-4 border rounded-sm shadow-sm">
                        <p class="text-justify">{{resultDetail.fastSummary}}</p>
                    </div>
                </div>
                <div *ngIf="resultDetail.summary && [leyesFederales, leyesEstatales].includes(index)">
                    <h2 class="text-slate-800 font-semibold mb-2">{{ 'SUMMARY_STRING' | translate }}</h2>
                    <div
                        class="text-sm bg-pearl-100 border-slate-200   dark:border-slate-700 p-4 border rounded-sm shadow-sm">
                        <p class="text-justify">{{resultDetail.summary}}</p>
                    </div>
                </div>
                <div *ngIf="index != leyesFederales">
                    <h2 class="text-slate-800 font-semibold mb-2">{{ 'DETAILS_STRING' | translate }}</h2>
                    <div
                        class="bg-pearl-100 p-4 rounded-sm shadow-sm w-full grid grid-cols-12 place-content-start border border-slate-200">
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.sentidoSentencia">
                            <h3 class="font-medium text-slate-800">Sentido de la Sentencia:</h3>
                            <div>{{resultDetail.sentidoSentencia}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.fechaSentencia">
                            <h3 class="font-medium text-slate-800">Fecha de Sentencia:</h3>
                            <div>{{resultDetail.fechaSentencia | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.magistrados && resultDetail.magistrados[0]">
                            <h3 class="font-medium text-slate-800">Magistrados:</h3>
                            <div class="space-y-1">
                                <ng-container *ngFor="let magistrado of resultDetail.magistrados">
                                    <div>{{ magistrado }}</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="h-max text-sm col-span-12 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.organo">
                            <h3 class="font-medium text-slate-800">Órgano:</h3>
                            <div>{{resultDetail.organo}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.asunto">
                            <h3 class="font-medium text-slate-800">Tipo de Asunto:</h3>
                            <div>{{resultDetail.asunto}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.subMateria">
                            <h3 class="font-medium text-slate-800">Sub-Materia:</h3>
                            <div>{{resultDetail.subMateria}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.actosReclamados">
                            <h3 class="font-medium text-slate-800">Actos Reclamados:</h3>
                            <div>{{resultDetail.actosReclamados}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.actosReclamadosEspecificos">
                            <h3 class="font-medium text-slate-800">Actos Reclamados Específicos:</h3>
                            <div>{{resultDetail.actosReclamadosEspecificos}}</div>
                        </div>
                        <div class="text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.estado">
                            <h3 class="font-medium text-slate-800">Estado:</h3>
                            <div>{{resultDetail.estado}}</div>
                        </div>
                        <div class="text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.doc_type">
                            <h3 class="font-medium text-slate-800">Tipo:</h3>
                            <div>{{resultDetail.doc_type | titlecase}}</div>
                        </div>
                        <div class="text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.ciudad">
                            <h3 class="font-medium text-slate-800">Ciudad:</h3>
                            <div>{{resultDetail.ciudad}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.numPagesPDF">
                            <h3 class="font-medium text-slate-800">Páginas en el PDF:</h3>
                            <div>{{resultDetail.numPagesPDF}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.numero">
                            <h3 class="font-medium text-slate-800">Registro Digital:</h3>
                            <div>{{resultDetail.numero}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.epoca">
                            <h3 class="font-medium text-slate-800">Época:</h3>
                            <div>{{resultDetail.epoca | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.tipo">
                            <h3 class="font-medium text-slate-800">Tipo:</h3>
                            <div>{{resultDetail.tipo | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.tipo">
                            <h3 class="font-medium text-slate-800">Fuente:</h3>
                            <div>{{resultDetail.fuente | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail['año']">
                            <h3 class="font-medium text-slate-800">Año:</h3>
                            <div>{{resultDetail['año']}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.documentType">
                            <h3 class="font-medium text-slate-800">Tipo de Documento:</h3>
                            <div>{{(resultDetail.documentType.toLowerCase() == 'ejecutoria' ? 'sentencia' :
                                resultDetail.documentType) | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.instancia">
                            <h3 class="font-medium text-slate-800">Instancia:</h3>
                            <div>{{resultDetail.instancia | titlecase}}</div>
                        </div>
                        <div class="h-max text-sm col-span-12 md:col-span-6 border border-slate-200 p-2 -mb-px -ml-px"
                            *ngIf="resultDetail.publicacion">
                            <h3 class="font-medium text-slate-800">Publicación:</h3>
                            <div>{{resultDetail.publicacion}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="resultDetail.precedente">
                    <h2 class="text-slate-800 font-semibold mb-2">{{ 'PRECEDING_STRING' | translate }}</h2>
                    <div class="bg-pearl-100   p-4 border border-slate-200">
                        <div class="border border-slate-200 divide-y divide-slate-200">
                            <div class="p-2 flex flex-col" *ngIf="resultDetail.precedente.asunto">
                                <span class="text-xs font-semibold">Asunto: </span>
                                <span class="text-xs">{{resultDetail.precedente.asunto}}</span>
                            </div>
                            <div class="p-2 flex flex-col" *ngIf="resultDetail.precedente.numExpediente">
                                <span class="text-xs font-semibold">Número de Expediente: </span>
                                <span class="text-xs">{{resultDetail.precedente.numExpediente}}</span>
                            </div>
                            <div class="p-2 flex flex-col" *ngIf="resultDetail.precedente.precedente_str">
                                <span class="text-xs font-semibold">Precedente: </span>
                                <span class="text-xs">{{resultDetail.precedente.precedente_str}}</span>
                            </div>
                            <div class="p-2 flex flex-col" *ngIf="resultDetail.precedente.ponente">
                                <span class="text-xs font-semibold">Ponente: </span>
                                <span class="text-xs">{{resultDetail.precedente.ponente}}</span>
                            </div>
                            <div class="p-2 flex flex-col"
                                *ngIf="resultDetail.precedente.organo && index != 'scjn-documents'">
                                <span class="text-xs font-semibold">Órgano: </span>
                                <span class="text-xs">{{resultDetail.precedente.organo}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="resultDetail.attributes">
                    <h2 class="text-slate-800 font-semibold mb-2">{{ 'ATTRIBUTES_STRING' | translate }}</h2>
                    <div class="bg-pearl-100 border-slate-200 p-4 border rounded-sm shadow-sm grid grid-cols-12">
                        <ng-container *ngFor="let attribute of resultDetail.attributes; let i = index">
                            <ng-container *ngIf="!visibleAttributes || (i + 1) <= visibleAttributes">
                                <div *ngIf="attribute.value && getVarTypeOf(attribute.value) == 'string'"
                                    class="col-span-12 xl:col-span-6 2xl:col-span-4 p-2 border border-slate-200 -mb-px -ml-px">
                                    <div class="text-xs font-semibold">{{attribute.label.replace('.', '') | titlecase}}:
                                    </div>
                                    <div class="text-xs">{{attribute.value.replace('Ver más', '')}}</div>
                                </div>
                                <div *ngIf="attribute.value && getVarTypeOf(attribute.value) != 'string' && attribute.value[0]"
                                    class="col-span-12 xl:col-span-6 2xl:col-span-4 p-2 border border-slate-200 -mb-px -ml-px">
                                    <div class="text-xs font-semibold">{{attribute.label | titlecase}}:</div>
                                    <div *ngFor="let individualValue of attribute.value; let i = index" class="text-xs">
                                        {{individualValue + (i == attribute.value.length - 1 ? "" : ",")}}</div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button
                            class="col-span-12 mt-3 flex gap-2 justify-center text-sm font-semibold text-slate-600 py-2 px-3"
                            (click)="changeVisibleAttributes()">
                            <span>{{visibleAttributes ? 'Ver Todos' : 'Ver Menos'}}</span>
                            <ng-icon class="text-2xl text-slate-400"
                                [ngClass]="!visibleAttributes ? 'rotate-180' : 'rotate-0'"
                                name="matExpandMoreOutline"></ng-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-justify" *ngIf="currentTab == 1">
                <div
                    class="bg-pearl-100   p-4 border border-slate-200 dark:border-slate-700 rounded-sm shadow-sm whitespace-pre-line">
                    <span *ngFor="let fragment of resultDetail.body">{{fragment.fragment + " "}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full h-[100svh] flex items-center justify-center" *ngIf="loading()">
        <div class="flex gap-2 justify-center pointer-events-none text-indigo-500 font-semibold">
            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
            </svg>
            <span [translate]="'GETTING_DETAIL'"></span>
        </div>
    </div>
</div>