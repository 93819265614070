import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { STORAGE_OBJECT_TYPES } from 'src/app/features/law-firm/constants/storage-object-types.constants';
import { FormatFile } from 'src/app/features/library/interfaces/format-files.interfaces';
import { FormatFolder } from 'src/app/features/library/interfaces/format-folders.interfaces';
import { FileSizePipe } from 'src/app/shared/pipes/get-file-size.pipe';
import { SimpleDocPickerService } from '../../simple-doc-picker.service';
import { SelectTemplateTabComponent } from '../select-template-tab.component';
import { DocPickerSelection } from '@interfaces/doc-picker.interfaces';
import { DialogService } from 'src/app/core/services/dialog.service';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';
import { RESOURCES } from '@constants/resource-service.constants';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { APIResponse } from '@interfaces/api.interface';
import mime from 'mime';

@Component({
    selector: 'app-template-data',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        NgxTippyModule,
    ],
    templateUrl: './template-data.component.html',
    host: {
        class: 'contents',
    },
    providers: [FileSizePipe],
})
export class TemplateDataComponent {
    private readonly fileSizePipe = inject(FileSizePipe);
    private readonly dialog = inject(DialogService);
    private readonly api = inject(UisrApiServiceV2);
    readonly docPickerService = inject(SimpleDocPickerService);
    readonly container = inject(SelectTemplateTabComponent);
    readonly documentType = STORAGE_OBJECT_TYPES.DOCUMENT;
    rootFolderName = 'useful_formatos';

    /** Contiene todos los elementos que se deben mostrar en el listado */
    @Input({ required: true }) items: any[] = [];
    @Input() parentId?: number;
    @Input() sizeLimit?: number;
    @Input() selectedFolder?: FormatFolder;

    trackByFn(index: any) {
        return index;
    }

    isLoading() {
        return (
            this.container.loading.value || this.container.loadingFolder.value
        );
    }

    isTooBig(file: DocPickerSelection<FormatFile> | FormatFolder) {
        return (
            'size' in file &&
            this.sizeLimit &&
            file.size &&
            +file.size > this.sizeLimit
        );
    }

    getTooBigTooltip(
        file?: DocPickerSelection<FormatFile> | FormatFolder
    ): string | null {
        if (file && 'size' in file && this.sizeLimit && this.isTooBig(file)) {
            const sizeLimit = this.fileSizePipe.transform(this.sizeLimit);
            return `<p class='text-pretty'>El archivo excede el tamaño máximo permitido de ${sizeLimit}</p>`;
        }
        return null;
    }

    getExtension(file: DocPickerSelection<FormatFile> | FormatFolder) {
        if (this.isFolder(file)) {
            return '';
        } else {
            return (file as FormatFile).extension.split('.').pop() || '';
        }
    }

    getFolderItems(file: DocPickerSelection<FormatFile> | FormatFolder) {
        return 'folders' in file ? file.folders : [];
    }

    isFolder(item: DocPickerSelection<FormatFile> | FormatFolder) {
        return 'idFolder' in item;
    }

    openPreview(file: DocPickerSelection<FormatFile>) {
        // No abrir el pdf en dispositivos móviles si se hace clic en el header (para permitir abrir el modal de opciones)
        let pathAux = file.path;
        pathAux = pathAux.replace('.docx', '.pdf');
        pathAux = pathAux.replace('.doc', '.pdf');
        pathAux = pathAux.replace(this.rootFolderName, 'useful_formatos_pdf');

        if (file.pdfAvailable) {
            this.dialog
                .openSidePanel(PdfViewerModalComponent, {
                    data: { ...file, path: pathAux },
                })
                .subscribe((res: unknown) => {
                    if (res && typeof res === 'object' && 'download' in res) {
                        this.downloadFile(file);
                    }
                });
        }
    }

    downloadFile(file: FormatFile): void {
        this.api.get(`${RESOURCES.formatFileContent}${file.idFile}`).subscribe({
            next: (res: APIResponse<string>) => {
                const type = mime.getType(file.extension);
                const src = `data:${type};base64,${res.data}`;
                const link = document.createElement('a');
                link.href = src;
                link.download = `${file.name}${file.extension}`;
                link.click();
                link.remove();
            },
        });
    }
}
