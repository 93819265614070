<div>
  <p class="p-4 border-l-4 border-indigo-500 bg-slate-100 rounded-lg !mb-1" [innerHTML]="parsedText"></p>
  <div class="group/actions flex parent-div">
    <!-- Opciones que solo se habilitan si hay un editor de texto abierto -->
    <button ngxTippy="Insertar" (click)="insertIntoEditor()"
      [disabled]="(chatService.loadingStates.parsingHtmlToSfdt | async)"
      class="group size-7 grid place-content-center rounded text-slate-500 hover:text-slate-700 opacity-80 group-hover:opacity-100">
      <i class="fa-solid fa-arrow-up-left" *ngIf="!(chatService.loadingStates.parsingHtmlToSfdt | async)"
        style="font-size: 0.9rem"></i>
      <i class="fa-solid fa-spinner-third animate-spin" *ngIf="(chatService.loadingStates.parsingHtmlToSfdt | async)"
        style="font-size: 0.9rem"></i>
    </button>
  </div>
</div>