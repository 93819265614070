import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';
import { SimpleDocPickerComponent } from '../simple-doc-picker.component';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { DocPickerSelection } from '@interfaces/doc-picker.interfaces';

@Component({
    selector: 'app-simple-search-object-data',
    standalone: true,
    imports: [CommonModule, ExtToIconPipe],
    templateUrl: './simple-search-object-data.component.html',
})
export class SimpleSearchObjectDataComponent {
    readonly docPickerService = inject(SimpleDocPickerService);
    readonly container = inject(SimpleDocPickerComponent);

    @Input() objects: any[] = [];

    @Output() onSelect = new EventEmitter<any>();

    onSelectClick(object: any) {
        this.onSelect.emit(object);
    }

    isSelected(object: DocPickerSelection) {
        return (
            this.docPickerService.isSelected(object) ||
            this.docPickerService.isExisting(object)
        );
    }
}
