import {
    Component,
    computed,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ViewDocComponent } from '../view-doc/view-doc.component';
import { SimpleDocPickerComponent } from '../../../simple-doc-picker/simple-doc-picker.component';
import { ALLOWED_AMPARO_EXTS } from '../../amparo-ext.constants';
import { environment } from 'src/environments/environment';
import { ASSISTANT_STEPS } from 'src/app/core/constants/assistant-steps.steps';
import { DocPickerSelection } from '@interfaces/doc-picker.interfaces';
import { FormatFile } from 'src/app/features/library/interfaces/format-files.interfaces';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';
import { RESOURCES } from '@constants/resource-service.constants';
import mime from 'mime';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { APIResponse } from '@interfaces/api.interface';

@Component({
    selector: 'app-chat-thread-header',
    templateUrl: './chat-thread-header.component.html',
    host: {
        class: 'contents',
    },
})
export class ChatThreadHeaderComponent {
    private readonly dialog = inject(DialogService);
    readonly chatService = inject(AssistantChatService);
    readonly tutorialService = inject(AppTutorialService);
    private readonly api = inject(UisrApiServiceV2);

    steps = ASSISTANT_STEPS;
    rootFolderName = 'useful_formatos';

    @Input() threadName = '';
    @Input() showLogo = false;

    @Output() closeClick = new EventEmitter();

    @ViewChild('threadDocs') threadDocs!: any;
    @ViewChild('docsButton') docsButton!: any;

    groupedDocuments = computed(() => this.getGroupedDocuments());

    getGroupedDocuments(): Array<{
        key: string;
        items: any[];
        icon: string;
        name: string;
    }> {
        const groups = this.chatService.documents().reduce((groups, doc) => {
            const category = doc.type;
            if (!groups[category]) {
                groups[category] = [];
            }
            groups[category].push(doc);
            return groups;
        }, {} as { [key: string]: any[] });

        return Object.keys(groups).map((key) => ({
            key,
            items: groups[key],
            icon: this.getCategoryIcon(key),
            name: this.getCategoryName(key),
        }));
    }

    docsProcessing() {
        return this.chatService.documents()?.some((d) => d.isProcessing);
    }

    /** Visualizar un modal con la información de un documento procesado (resumen, keywords...) */
    viewDoc(doc: any) {
        if (doc.type == 'docTemplate') {
            this.viewTemplate(doc);
            return;
        }
        this.dialog.openDialog(ViewDocComponent, { data: { doc } });
    }

    viewTemplate(file: DocPickerSelection<FormatFile>) {
        // No abrir el pdf en dispositivos móviles si se hace clic en el header (para permitir abrir el modal de opciones)
        let pathAux = file.path;
        pathAux = pathAux.replace('.docx', '.pdf');
        pathAux = pathAux.replace('.doc', '.pdf');
        pathAux = pathAux.replace(this.rootFolderName, 'useful_formatos_pdf');

        if (file.pdfAvailable) {
            this.dialog
                .openSidePanel(PdfViewerModalComponent, {
                    data: { ...file, path: pathAux },
                })
                .subscribe((res: unknown) => {
                    if (res && typeof res === 'object' && 'download' in res) {
                        this.downloadFile(file);
                    }
                });
        }
    }

    downloadFile(file: FormatFile): void {
        this.api.get(`${RESOURCES.formatFileContent}${file.idFile}`).subscribe({
            next: (res: APIResponse<string>) => {
                const type = mime.getType(file.extension);
                const src = `data:${type};base64,${res.data}`;
                const link = document.createElement('a');
                link.href = src;
                link.download = `${file.name}${file.extension}`;
                link.click();
                link.remove();
            },
        });
    }

    /** Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados*/
    viewDocs() {
        this.dialog
            .openDialog(SimpleDocPickerComponent, {
                data: {
                    prompt: 'Agrega contexto a tu conversación con Amparo IA. Selecciona asuntos, agrega documentos o jurisprudencia desde tus colecciones.',
                    extensions: ALLOWED_AMPARO_EXTS,
                    existing: this.chatService.documents(),
                    sizeLimit: environment.amparoSizeLimit,
                },
            })
            .subscribe((selection: any) => {
                if (selection) {
                    this.chatService.addDocs(selection);
                }
            });
    }

    /** Realiza el llamado al servicio del tutorial para empezar el tour del asistente */
    startTour() {
        this.tutorialService.startTour(this.steps, undefined, 'assistant-tour');
    }

    onCloseClick() {
        this.closeClick.emit();
    }

    iconByModel(speed: string) {
        switch (speed) {
            case 'baja':
                return 'fa-light fa-gauge-low';
            case 'alta':
                return 'fa-light fa-gauge-max';
            case 'media':
                return 'fa-light fa-gauge-high';
            default:
                return 'fa-light fa-gauge-simple';
        }
    }

    getCategoryIcon(category: string): string {
        switch (category) {
            case 'dossierFile':
            case 'studioFile':
                return '📄';
            case 'dossier':
                return '📁';
            case 'searchObject':
                return '⚖️';
            case 'docTemplate':
            case 'machoteStudio':
                return '📋';
            default:
                return '';
        }
    }
    getCategoryName(category: string): string {
        switch (category) {
            case 'dossierFile':
            case 'studioFile':
                return 'Documento';
            case 'dossier':
                return 'Asunto';
            case 'searchObject':
                return 'Sentencia/Ley';
            case 'docTemplate':
            case 'machoteStudio':
                return 'Machote';
            default:
                return '';
        }
    }

    viewDocList() {
        this.dialog.openDropdown(this.threadDocs, this.docsButton);
    }
}
