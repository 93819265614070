import { Component, inject, Input } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { AssistantChatService } from '../../../services/assistant-chat.service';

@Component({
    selector: 'app-text-suggestion-widget',
    templateUrl: './text-suggestion-widget.component.html',
})
export class TextSuggestionWidgetComponent {
    private readonly markdown = inject(MarkdownService);
    readonly chatService = inject(AssistantChatService);

    private _text: string = '';

    @Input()
    set text(value: string) {
        this._text = value;
        this.parseText();
    }

    get text(): string {
        return this._text;
    }

    parsedText: string = '';

    private parseText() {
        this.parsedText = this.markdown.parse(this.text);
    }
    protected insertIntoEditor() {
        this.chatService.onContentEmitted.next(this.parsedText);
    }
}
