import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject, distinctUntilKeyChanged, filter } from 'rxjs';
import { WorkspaceModel } from 'src/app/features/law-firm/models/workspace.models';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { RESOURCES } from '../../constants/resource-service.constants';
import { APIResponse } from '../../interfaces/api.interface';
import { MenuItem } from '../../interfaces/menu-item.interface';
import { UserData } from '../../models/user-data';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { UisrAnalyticsService } from '../../services/analytics.service';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@UntilDestroy()
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        NgxTippyModule,
        RouterModule,
        MenuItemComponent,
    ],
    host: { class: 'contents' },
})
export class SidebarComponent implements OnInit, OnChanges {
    private readonly subscriptionService = inject(SubscriptionService);
    readonly authService = inject(UisrAuthService);

    sidebarExpanded?: boolean;
    menuItems: MenuItem[] = [];
    userData? = {} as UserData;
    public resources = RESOURCES;
    loadingData = new BehaviorSubject(false);
    lastInnerWidth: number = 0;

    //Workspace
    oldProfilePic = '';
    workspace: WorkspaceModel | null = null;

    //Loader workspace info
    public loadingWorkspace = new BehaviorSubject(true);

    @Input() sidebarOpen: boolean = false;
    @ViewChild('sideBar') sideBar!: ElementRef;

    @HostListener('document:keydown.escape', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.sidebarOpen = false;
            localStorage.setItem('sidebarExpanded', 'false');
        }
    }

    @HostListener('window:resize', []) onWindowResize() {
        this.updateSidebarStatus();
    }

    constructor(
        private store: Store,
        private router: Router,
        public apiService: UisrApiServiceV2,
        private analyticsService: UisrAnalyticsService
    ) {
        this.store
            .pipe(
                select(UserDataFull),
                distinctUntilKeyChanged('idWorkspace'),
                untilDestroyed(this)
            )
            .subscribe((data) => {
                this.userData = data;
            });

        this._filterMenuItems();
    }

    ngOnInit(): void {
        this.onSubscriptionChange();
        this.onMenuChange();

        window.addEventListener(
            'workspaceModified',
            this.setInitialData.bind(this)
        );

        if (this.userData?.idWorkspace || this.userData?.id_workspace_member) {
            this.setInitialData();
        }

        this.updateSidebarStatus();
        this.sidebarExpanded = localStorage.getItem('sidebarExpanded')
            ? localStorage.getItem('sidebarExpanded') == 'true'
            : true;
        this.sidebarOpen = localStorage.getItem('sidebarOpen')
            ? localStorage.getItem('sidebarOpen') == 'true'
            : true;
        this.expandOrCollapseSidebar(this.sidebarExpanded);

        this.authService.sidebarPreferences();

        this.router.events
            .pipe(
                filter((event: any) => {
                    return event.routerEvent instanceof NavigationEnd;
                }),
                untilDestroyed(this)
            )
            .subscribe(() => this.updateSidebarStatus());
    }

    onSubscriptionChange() {
        this.subscriptionService.subscription$
            .pipe(untilDestroyed(this))
            .subscribe(() => this._filterMenuItems());
    }

    private _filterMenuItems() {
        const isClient = this.subscriptionService.isClient();
        const portal = 'CLIENT_PORTAL_STRING';

        this.menuItems = this.authService.menuOptions.value.filter((item) =>
            isClient ? item.label === portal : item.label !== portal
        );
    }

    onMenuChange() {
        this.authService.menuOptions
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this._filterMenuItems();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes &&
            changes['sidebarOpen'] &&
            changes['sidebarOpen'].currentValue != undefined
        ) {
            localStorage.setItem(
                'sidebarOpen',
                changes['sidebarOpen'].currentValue.toString()
            );
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('workspaceModified', (event) =>
            this.setInitialData.bind(this)
        );
    }

    updateSidebarStatus() {
        this.lastInnerWidth = window.innerWidth;

        if (window.innerWidth == this.lastInnerWidth) return;

        if (window.innerWidth >= 1535) {
            this.sidebarExpanded = true;
            this.expandOrCollapseSidebar(true);
        } else if (window.innerWidth < 1024) {
            this.sidebarOpen = false;
            localStorage.setItem('sidebarOpen', 'false');
        }
    }

    changeSidebarStatus(): void {
        this.sidebarExpanded = !this.sidebarExpanded;
        this.expandOrCollapseSidebar(this.sidebarExpanded);
    }

    expandOrCollapseSidebar(isOpen: boolean): void {
        const body = document.querySelector('body');
        if (isOpen) {
            body!.classList.add('sidebar-expanded');
        } else {
            body!.classList.remove('sidebar-expanded');
        }

        localStorage.setItem('sidebarExpanded', String(isOpen));

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }

    openOrCloseSidebar(): void {
        this.sidebarExpanded = true;
        this.expandOrCollapseSidebar(true);
        setTimeout(() => {
            this.sidebarOpen = !this.sidebarOpen;
            localStorage.setItem('sidebarOpen', this.sidebarOpen.toString());
        }, 0);
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    setInitialData() {
        this.apiService
            .get(`${this.resources.workspaceByUser}`, null)
            .pipe(loadingState(this.loadingData), untilDestroyed(this))
            .subscribe({
                next: async (res: APIResponse<WorkspaceModel>) => {
                    if (res.success) {
                        this.oldProfilePic = res.data.photoWorkspace || '';
                        this.workspace = res.data;
                    }
                },
            });
    }

    /** Envía el evento de click en la opción de configuración del despacho a GA */
    trackSettingsClick() {
        this.analyticsService.trackMenuClick(
            'Configuración Despacho',
            this.userData!
        );
    }
}
