import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Injectable, effect, signal } from '@angular/core';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { SummaryViewerComponent } from 'src/app/features/search/components/summary-viewer/summary-viewer.component';
import { SEARCH_TYPES } from 'src/app/features/search/constants/search-strings.constants';
import { INDEX_LIST } from 'src/app/features/search/constants/sources-with-ids.constants';
import { PdfViewerModalComponent } from '../components/pdf-viewer-modal/pdf-viewer-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ResultSourceService {
    private titleCasePipe = new TitleCasePipe();
    private upperCasePipe = new UpperCasePipe();

    searchType = signal<any>(SEARCH_TYPES.KEY_WORD);

    constructor(private dialog: Dialog, private overlay: Overlay) {
        effect(() =>
            localStorage.setItem(
                'keepSearchType',
                JSON.stringify(this.searchType())
            )
        );

        if (localStorage.getItem('keepSearchType')) {
            const type = JSON.parse(localStorage.getItem('keepSearchType')!);

            if (type && type.value != 'semantic') {
                this.searchType.set(type);
            }
        }
    }

    getVarTypeOf(value: any): string {
        return typeof value;
    }

    getSourceId(index: string): number {
        switch (index) {
            case INDEX_LIST.LEYES_ESTATALES:
                return 4;
            case INDEX_LIST.LEYES:
                return 3;
            case INDEX_LIST.SCJN:
                return 1;
            case INDEX_LIST.CJF:
                return 2;
            default:
                return 1;
        }
    }

    getLogo(index: string): string {
        switch (index) {
            case INDEX_LIST.LEYES:
                return 'assets/images/mex-flag.png';
            case INDEX_LIST.SCJN:
                return 'assets/images/scjn-logo.png';
            case INDEX_LIST.CJF:
                return 'assets/images/cjf-logo.png';
            case INDEX_LIST.LEYES_ESTATALES:
                return 'assets/images/mex-flag.png';
            default:
                return 'assets/images/scjn-logo.png';
        }
    }

    getSourceName(index: string): string {
        switch (index) {
            case INDEX_LIST.LEYES_ESTATALES:
                return 'Leyes Estatales';
            case INDEX_LIST.LEYES:
                return 'Leyes';
            case INDEX_LIST.SCJN:
                return 'SCJN';
            case INDEX_LIST.CJF:
                return 'CJF';
            default:
                return 'Leyes';
        }
    }

    getFullSourceName(index: string): string {
        switch (index) {
            case INDEX_LIST.LEYES_ESTATALES:
                return 'Leyes Estatales';
            case INDEX_LIST.LEYES:
                return 'Leyes Federales de México';
            case INDEX_LIST.SCJN:
                return 'Suprema Corte de Justicia de la Nación';
            case INDEX_LIST.CJF:
                return 'Consejo de la Judicatura Federal';
            default:
                return 'Consejo de la Judicatura Federal';
        }
    }

    getDocumentType(type: string): string {
        let formattedType = type == 'ejecutoria' ? 'sentencia' : type;
        return `${this.titleCasePipe.transform(formattedType)}`;
    }

    getBorderColor(index: string): string {
        switch (index) {
            case INDEX_LIST.LEYES:
            case INDEX_LIST.LEYES_ESTATALES:
                return 'border-[#007438]';
            case INDEX_LIST.SCJN:
                return 'border-scjn';
            case INDEX_LIST.CJF:
                return 'border-[#240b61]';
            default:
                return 'border-scjn';
        }
    }

    parseResultTitle(title: string): string {
        const cleanedTitle = title.replace(
            /^[^a-zA-Z0-9áéíóúüÁÉÍÓÚÜñÑ(]+|[^a-zA-Z0-9áéíóúüÁÉÍÓÚÜñÑ)]+$/g,
            ''
        );
        return this.upperCasePipe.transform(cleanedTitle);
    }

    viewSummary(result: any) {
        const summary = result._source.fastSummary || result._source.summary;
        if (!summary) return;
        this.dialog.open(SummaryViewerComponent, {
            data: {
                summary: summary,
                title: this.parseResultTitle(
                    result._source.augmented_name || result._source.name
                ),
            },
            hasBackdrop: true,
            panelClass: ['app-custom-panel'],
        });
    }

    openPDF(url: string) {
        this.dialog.open(PdfViewerModalComponent, {
            hasBackdrop: true,
            panelClass: [
                'app-side-panel',
                'animate__animated',
                'animate__slideInLeft',
                'animate__faster',
                'relative',
                'min-w-[48rem]',
                'h-[100svh]',
            ],
            data: url,
            positionStrategy: this.overlay
                .position()
                .global()
                .right('0')
                .bottom('0'),
        });
    }

    parseAttributes(result: any) {
        let returnAttr: any = result.attributes;
        if (returnAttr && typeof returnAttr == 'object') {
            returnAttr = UisrTools.removeDuplicateProperties(
                returnAttr,
                result
            );
            returnAttr = UisrTools.removeEmptyProperties(returnAttr);
            returnAttr = Object.keys(returnAttr).map((key) => ({
                label: key,
                value: returnAttr[key],
            }));
        }

        return returnAttr;
    }
}
