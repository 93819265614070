<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center">
    <div class="font-semibold text-primary-100">
        {{ 'SUMMARY_STRING' | translate }}
    </div>
    <!-- Cerrar el modal -->
    <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
        class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
        (click)="dialogRef.close()">
        <span class="sr-only" translate="CLOSE_STRING"></span>
        <i class="fa-solid text-[1rem] fa-xmark"></i>
    </button>
</div>
<div class="overflow-auto flex flex-col gap-3 p-5 max-h-[calc(85svh-11.4rem)]">
    <p class="text-center max-w-[30em] mx-auto font-medium text-lg text-slate-800" [innerHTML]="data.title"></p>
    <p class="text-justify hyphens-auto max-w-[30em] mx-auto" [innerHTML]="data.summary"></p>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0">
    <div class="flex flex-wrap justify-end space-x-2">
        <button class="uisr-secondary-button close-overlay-directive" (click)="dialogRef.close()">
            {{'CLOSE_STRING' | translate}}
        </button>
    </div>
</div>