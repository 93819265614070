import {
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChatThreadComponent } from 'src/app/shared/components/assistant-chat/components/chat-thread/chat-thread.component';
import { AssistantChatService } from 'src/app/shared/components/assistant-chat/services/assistant-chat.service';

@Component({
    selector: 'app-amparo-for-docs',
    templateUrl: './amparo-for-docs.component.html',
    host: {
        class: 'w-svw md:w-[40svw] max-sm:absolute max-sm:inset-0 md:max-w-xl p-2 flex justify-stretch animate__animated animate__slideInLeft animate__faster bg-[#e5e7eb]',
    },
})
export class AmparoForDocsComponent {
    readonly chatService = inject(AssistantChatService);

    @Input() cacheThread = false;
    @Input() content?: Blob;
    @Output() toggleRequest = new EventEmitter<boolean>();

    @ViewChild(ChatThreadComponent) chatThread?: ChatThreadComponent;

    constructor() {
        this.chatService.componentStates.editor = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['content'] && changes['content'].currentValue) {
            this.chatService.editorModeBlob = changes['content'].currentValue;
        }

        if (changes['cacheThread'] && changes['cacheThread'].currentValue) {
            if (this.cacheThread) {
                this.chatService.cacheThread();
            }
        }
    }

    toggleAmparoForDocs() {
        this.toggleRequest.emit(true);
    }

    ngAfterViewInit() {
        if (this.chatThread) {
            this.chatService.scrollToBottom();
        }
    }

    ngOnDestroy() {
        this.chatService.componentStates.editor = false;
    }
}
