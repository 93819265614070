import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    finalize,
    forkJoin,
    Subject,
    switchMap,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { STORAGE_OBJECT_TYPES } from 'src/app/features/law-firm/constants/storage-object-types.constants';
import {
    FilterStudioFile,
    StudioFiles,
} from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { StudioItemDataComponent } from './studio-item-data/studio-item-data.component';
import { DocPickerSelection, DocPickerSelectionType } from '@interfaces/doc-picker.interfaces';

@UntilDestroy()
@Component({
    selector: 'app-select-studio-file-tab',
    templateUrl: './select-studio-file-tab.component.html',
    standalone: true,
    imports: [
        StudioItemDataComponent,
        ReactiveFormsModule,
        CommonModule,
        NgxTippyModule,
    ],
})
export class SelectStudioFileTabComponent implements OnInit, OnChanges {
    private readonly api = inject(UisrApiServiceV2);
    private readonly docPickerService = inject(SimpleDocPickerService);

    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    items: any[] = [];
    selectedFolder?: StudioFiles;
    currentFolderId?: number;
    searchControl: FormControl<null | string> = new FormControl(null);
    filter: FilterStudioFile = {};
    filterSubject = new Subject<FilterStudioFile>();

    @Input() extensions?: string[] = [];
    @Input() sizeLimit?: number;
    /** Si se envía, significa que no se debe buscar todo el estudio, si no que se debe abrir una carpeta especificada */
    @Input() baseType?: number;
    /** Habilita un botón de regresar que emite un evento cuando se presiona */
    @Input() allowReturn = false;
    /** Es un override para el tipo de objeto que se construye para el select, se usa porque las plantillas del estudio se mandan con un tipo diferente */
    @Input() overrideType?: DocPickerSelectionType;
    /** Si se envía, significa que no se debe permitir agregar más de un elemento al seleccionar */
    @Input() singleSelection = false;
    /** Si se envía, significa que se debe emitir un evento cuando se selecciona un item */
    @Input() eventsOnly = false;
    /** Si se envía significa que se está sobreescribiendo el item seleccionado y no se debe buscar en el servicio de selección */
    @Input() selectedOverride?: any;

    /** Se emite cuando el botón de regresar se presiona */
    @Output() returnClicked = new EventEmitter<void>();
    /** Se emite cuando se selecciona un item y se configura el eventsOnly a true */
    @Output() itemClick = new EventEmitter<DocPickerSelection<StudioFiles>>();
    

    ngOnInit() {
        this.subscribeToSearch();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['baseType']) {
            const currentValue = changes['baseType'].currentValue;
            const previousValue = changes['baseType'].previousValue;
            
            // Si el valor anterior no existe o el valor actual es diferente al valor anterior
            if (!previousValue || currentValue !== previousValue) {
                if (currentValue) {
                    this.getBaseFolderData();
                } else {
                    this.getFolderData();
                }
            }
        } else {
            this.getFolderData();
        }
    }

    /** Al realizar la búsqueda, se obtienen las carpetas buscando en todo el estudio */
    subscribeToSearch() {
        this.searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                untilDestroyed(this)
            )
            .subscribe({
                next: (val) => {
                    if (val) {
                        this.onSearch();
                    } else {
                        this.getFolderData();
                    }
                },
            });
    }

    onSearch() {
        this.applyFilter({
            search_value: this.searchControl.value || undefined,
        });
    }

    subscribeToFilter() {
        this.filterSubject
            .pipe(
                switchMap((filter: FilterStudioFile) => {
                    if (filter.search_value) {
                        let parsedFilter: FilterStudioFile = {
                            ...filter,
                            parent_id: undefined,
                            page_number: undefined,
                            page_size: undefined,
                        };

                        parsedFilter =
                            UisrTools.removeEmptyProperties(parsedFilter);

                        return forkJoin([
                            this.api.get(RESOURCES.studioFiles, parsedFilter),
                        ]).pipe(loadingState(this.loading));
                    } else {
                        return this.api
                            .get(RESOURCES.studioFolderDetails, filter)
                            .pipe(loadingState(this.loading));
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (results) => {
                    if (Array.isArray(results)) {
                        const folderRes = results[0];

                        this.items = this.docPickerService.buildSelectableItems(
                            folderRes.data,
                            this.overrideType
                        );
                    } else {
                        const res: APIResponse<any> = results;
                        this.items = this.docPickerService.buildSelectableItems(
                            res.data.folders,
                            this.overrideType
                        );
                    }
                },
            });
    }

    applyFilter(filter: FilterStudioFile) {
        this.filter = {
            ...this.filter,
            ...filter,
            type_id: STORAGE_OBJECT_TYPES.FOLDER,
            exclude_thread_folders: true,
            exclude_machotes: true,
        };

        this.filter = UisrTools.removeEmptyProperties(this.filter);

        this.filterSubject.next(this.filter);
    }

    getFolderData(folder?: StudioFiles) {
        this.selectedFolder = undefined;
        const filter: FilterStudioFile = {
            type_id: STORAGE_OBJECT_TYPES.FOLDER,
            exclude_thread_folders: true,
            exclude_machotes: true,
        };

        if (folder) {
            this.selectedFolder = folder;
            filter.parentId = Number(this.selectedFolder.studio_file_id);
        }

        filter.fileExts = this.extensions;

        this.api
            .get(RESOURCES.studioFolderDetails, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.items = [
                        ...res.data.folders,
                        ...this.docPickerService.buildSelectableItems(
                            res.data.files,
                            this.overrideType
                        ),
                    ];

                    this.selectedFolder = undefined;
                },
            });
    }

    getBaseFolderData() {
        this.selectedFolder = undefined;
        const filter: any = {
            type_id: STORAGE_OBJECT_TYPES.FOLDER,
            exclude_thread_folders: true,
            user_folder_type_id: this.baseType,
        };

        this.api
            .get(RESOURCES.studioFolderDetails, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.items = [
                        ...res.data.folders,
                        ...this.docPickerService.buildSelectableItems(
                            res.data.files,
                            this.overrideType
                        ),
                    ];
                    this.currentFolderId = res.data.parent?.studio_file_id;
                    this.selectedFolder = undefined;
                },
            });
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al hacer click en una carpeta, se agrega o elimina del arreglo de elementos seleccionados */
    onItemClick(folder: DocPickerSelection<StudioFiles>) {
        if (folder.type_id == STORAGE_OBJECT_TYPES.FOLDER) {
            if (folder.folders && folder.open) {
                folder.open = false;
            } else if (!folder.folders) {
                this.openFolder(folder);
            } else {
                folder.open = true;
            }
        } else {
            if (this.eventsOnly) {
                this.itemClick.emit(folder);
            } else {
                this.docPickerService.updateSelection(folder, this.singleSelection);
            }
        }
    }

    /** Al solicitar abrir una carpeta, se busca su data y se abre */
    openFolder(folder: StudioFiles) {
        if (folder.open) {
            folder.open = false;
            return;
        }
        this.selectedFolder = folder;

        this.api
            .get(RESOURCES.studioFolderDetails, {
                parent_id: folder.studio_file_id,
                exclude_thread_folders: true,
                exclude_machotes: true,
            })
            .pipe(
                loadingState(this.loadingFolder),
                untilDestroyed(this),
                finalize(() => (this.selectedFolder = undefined))
            )
            .subscribe({
                next: (res: APIResponse<any>) => {
                    folder.folders = [
                        ...res.data.folders,
                        ...this.docPickerService.buildSelectableItems(
                            res.data.files,
                            this.overrideType
                        ),
                    ];
                    folder.open = true;
                },
            });
    }
}
