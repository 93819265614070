<div class="@container bg-white text-slate-800 rounded-lg border border-slate-200 shadow-sm mb-1 flex flex-col"
  *ngIf="doc && !(loading | async)">
  <div class="flex min-w-0 gap-4 p-4 ">
    <div class="rounded w-24 h-28 bg-slate-50 border border-slate-200 flex flex-col px-3 py-2 cursor-pointer shrink-0"
      (click)="openDocument()">
      <div class="flex items-center justify-center my-auto text-xl">
        📜👩🏽‍⚖️
      </div>
    </div>
    <div class="flex flex-col min-w-0 grow">
      <a [href]="redirectHref()" target="_blank" class="truncate text-left leading-5 text-slate-800 font-medium"
        [ngxTippy]="doc.augmented_name || doc.name">
        {{ doc.augmented_name || doc.name }}
      </a>
      <div class="text-sm text-slate-500">{{ doc.readableSource }} {{ doc.urlPDF ? '• PDF' : '' }}</div>
      <div class="flex items-center gap-2 mt-8 overflow-x-auto min-w-0">
        <button (click)="openDocument()"
          class="flex items-center gap-2 px-2 py-1 border border-indigo-600 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-eye"></i>
          <span class="hidden @md:inline-block">Visualizar</span>
        </button>
        <button (click)="downloadDocument()" *ngIf="doc.urlPDF"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-download"></i>
          <span class="hidden @md:inline-block">Descargar</span>
        </button>
        <button (click)="addToCollections()"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800"
          ngxTippy="Agregar a Colección">
          <i class="fa-regular fa-folder-plus"></i>
          <span class="hidden @md:inline-block">Agregar a Colección</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(loading | async)">
  <div class="border-2 border-slate-200 rounded-lg p-2 animate-pulse flex gap-2">
    <div class="w-32 h-36 rounded-lg bg-slate-200"></div>
    <div class="flex flex-col gap-1">
      <div class="w-40 h-6 rounded-md bg-slate-200"></div>
      <div class="w-20 h-6 rounded-md bg-slate-200"></div>
    </div>
  </div>
</div>