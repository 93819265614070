export const environment = {
    appEnv: 'dev',
    production: false,
    environmentsHosts: [
        'https://dev.midespacho.cloud/',
        'https://qa.midespacho.cloud/',
        'https://app.midespacho.cloud/',
    ],
    host: 'https://dev.midespacho.cloud/',
    cdnUrl: 'https://mi-despacho-app.nyc3.digitaloceanspaces.com/',
    apiUrl: 'https://dev.midespacho.cloud/api/',
    nestApiUrl: 'https://dev.midespacho.cloud/v1/',
    apiSocketUrl: 'https://dev.midespacho.cloud/',
    apiDocumentEditorUrl:
        'https://dev.midespacho.cloud/docsrv/api/documenteditor/',
    pathSocket: '/socket.notify.io',
    idGA: 'G-QC50ZL1MHZ',
    blogTutorialFinalDate: new Date('2024-03-19'),
    assistantTutorialFinalDate: new Date('2024-09-01'),
    assistantAccess: [],
    maxMembersRange: 20,
    trialDays: 15,
    trialDaysAnnual: 30,
    stripePK:
        'pk_test_51Mz13oHS0rBjiPeAW6D3djrXITO3M26OrT2amT9yasSKXTVqyDC4zaZJj0epI1IeWebkEVaZ5pxvaNYatEfSdh1n00vbp4tRI2',
    amparoIntroStartDate: '2024-10-18',
    amparoSizeLimit: 78643200,
    maxFileSize: 3221225472,
};
