<div [formGroup]="docForm" class="bg-white flex flex-row items-start p-2 px-4 sm:px-6 md:px-5 justify-between gap-2"
    [ngClass]="{'invisible': !editorReady}">
    <div class="w-full max-w-[30rem] flex items-center relative">
        <app-input-field formControlName="title" [inputType]="'text'" [placeholder]="'Agregar título'"
            [generalError]="'Por favor introduzca un título válido.'"></app-input-field>

        <!-- Contenedor principal del icono y los mensajes -->
        <div *ngIf="showAutoSaveIcon"
            class="absolute right-[-2rem] top-1/2 transform -translate-y-1/2 group cursor-pointer flex items-center w-[7rem] justify-end">

            <!-- Mostrar el spinner mientras se está guardando -->
            <i *ngIf="isAutoSaving" class="fa-solid fa-spinner animate-spin text-gray-500"></i>

            <!-- Mostrar el texto "Guardando..." mientras se está guardando -->
            <span *ngIf="isAutoSaving" class="absolute left-full ml-2 text-xs text-gray-500 whitespace-nowrap">
                Guardando...
            </span>

            <!-- Mostrar la nube gris cuando no se está guardando -->
            <i *ngIf="!isAutoSaving && !showSavedMessage"
                class="fa-regular fa-cloud-check text-gray-500 hover:text-purple-500"></i>

            <!-- Mostrar la nube con el mensaje de "Documento Guardado" -->
            <i *ngIf="!isAutoSaving && showSavedMessage"
                class="fa-regular fa-cloud-check text-gray-500 transition duration-300 hover:text-purple-500"></i>

            <!-- Mensaje "Documento guardado" que aparece durante 3 segundos -->
            <span *ngIf="showSavedMessage" class="absolute left-full ml-2 text-xs text-gray-500 whitespace-nowrap">
                Documento guardado
            </span>

            <!-- Mensaje de hover "Documento y cambios guardados" -->
            <div *ngIf="!isAutoSaving && !showSavedMessage"
                class="absolute top-1/2 left-full ml-2 transform -translate-y-1/2 bg-gray-900 text-white text-xs px-2 py-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                Documento y cambios guardados
            </div>
        </div>

    </div>
    <div class="flex gap-2">
        <button (click)="toggleAmparoForDocs()"
            class="transition-colors inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out bg-white border-slate-200 hover:border-slate-300 text-slate-500">
            <img src="/assets/images/amparo-isotipo.webp" alt="Amparo IA" class="size-6 -ml-1 shrink-0">
            <img src="/assets/images/amparo.webp" alt="Amparo IA" class="h-[0.85rem] -ml-3 max-xs:hidden">
        </button>
        <button (click)="beforeClosingEditor()"
            class="btn bg-pearl-100 border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 px-4 flex gap-1 items-baseline"
            [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Cerrar'">
            <i class="fa-solid fa-chevron-left leading-none text-xs text-slate-400"></i>
            <span>Volver</span>
        </button>
    </div>
</div>
<div class="grow document-component-wrapper flex min-h-0">
    <div class="relative overflow-auto w-full"
        [ngClass]="{'md:w-[calc(100svw-min(30svw,36rem))]': componentStates.assistant}" #docEditorContainer>
        <ejs-documenteditorcontainer #document_editor (created)="onEditorCreated()" [serviceUrl]="serviceLink"
            [toolbarItems]="toolbarItems" (toolbarClick)="onToolbarClick($event)"
            (contentChange)="onContentChange($event)" (documentChange)="onDocumentChange($event)"
            (beforeXmlHttpRequestSend)="beforeXmlHttpRequestSend($event)"></ejs-documenteditorcontainer>
    </div>
    <app-amparo-for-docs [ngClass]="{'hidden': !componentStates.assistant}" [content]="currentContentDocx"
        [cacheThread]="cacheThread" (toggleRequest)="toggleAmparoForDocs()" />

    <!-- Loader del documento -->
    <div *ngIf="!editorReady || loadingStates.loadingDocument.value"
        class="absolute flex top-0 left-0 w-full h-full bg-[#ffffff70] items-center justify-center">
        <svg class="animate-spin w-10 h-10 fill-current shrink-0" viewBox="0 0 16 16">
            <path
                d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z">
            </path>
        </svg>
    </div>
</div>