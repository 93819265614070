import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UisrAnalyticsService } from 'src/app/core/services/analytics.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UserJourneyModal } from 'src/app/features/updates/components/user-journey/user-journey.modal';
import { IntercomService } from '../../services/intercom.service';
import { AssistantChatService } from '../assistant-chat/services/assistant-chat.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
    selector: 'app-contact-info',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './contact-info.component.html',
})
export class ContactInfoComponent {
    private readonly store = inject(Store);
    private readonly analytics = inject(UisrAnalyticsService);
    private readonly dialog = inject(DialogService);
    readonly intercomService = inject(IntercomService);
    readonly assistantService = inject(AssistantChatService);

    userData = toSignal(this.store.select(UserDataFull));

    @Input() inline = false;

    /** Enviar el evento de clic en el botón de ayuda */
    helpCenterAnalytics() {
        if (this.userData()) {
            this.analytics.trackHelpCenterClick(this.userData()!);
        }
    }

    scheduleMeetingAnalytics() {
        if (this.userData()) {
            this.analytics.scheduleMeetingClick(this.userData()!);
        }
    }

    /** Permite abrir o cerrar la ventana principal del asistente */
    toggleAssistant() {
        if (this.userData()!) {
            this.analytics.trackAssistantClick(this.userData()!);
        }
        this.assistantService.toggle();
    }

    openUserJourney() {
        if (this.userData()) {
            this.analytics.userJourneyClick(this.userData()!);
        }

        this.dialog.openDialog(UserJourneyModal);
    }
}
