import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDeviceInformationService } from 'angular-device-information';
import { MentionModule } from 'angular-mentions';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { AddSoNoteComponent } from './components/add-so-note/add-so-note.component';
import { AutocompleteMultiSelectComponent } from './components/autocomplete-multi-select/autocomplete-multi-select.component';
import { AutocompleteSelectComponent } from './components/autocomplete-select/autocomplete-select.component';
import { InputGenericComponent } from './components/input-generic/input-generic.component';
import { InputTagNameMemberComponent } from './components/input-tag-name-member/input-tag-name-member.component';
import { PasswordValidationComponent } from './components/password-validation/password-validation.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PdfViewerOverlayComponent } from './components/pdf-viewer-overlay/pdf-viewer-overlay.component';
import { SoNotesComponent } from './components/so-notes/so-notes.component';
import { UisrStepperComponent } from './components/uisr-stepper/uisr-stepper.component';
import { AnimeJsDirective } from './directives/anime-js.directive';
import { ContenteditableModelDirective } from './directives/contentEditableModelDirective.directive';
import { LoadingElementDirective } from './directives/loading-element.directive';
import { MentionStylerDirective } from './directives/mention-styler.directive';
import { ModalOverlayDirective } from './directives/modal-overlay.directive';
import { ParallaxEffectDirective } from './directives/parallax-effect.directive';
import { StringConditionedStylingDirective } from './directives/string-conditioned-styling.directive';
import { AngularResizeElementModule } from './modules/angular-resize-element.module';
import { IconsModule } from './modules/icons.module';
import { MaterialCdkModule } from './modules/material-cdk.module';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterForAutocompletePipe } from './pipes/filter-for-autocomplete.pipe';
import { ValueFormDossierPipe } from './pipes/value-form-dossier.pipe';
import { VerifiedRegisterPipe } from './pipes/verified-register.pipe';
import { FilterByBoolPipe } from './pipes/filter-by-bool.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { HeaderComponent } from '../core/components/header/header.component';
import { SidebarComponent } from '../core/components/sidebar/sidebar.component';
import { ComentarioComponent } from './components/comentario/comentario.component';
import { ManageDossierAccessComponent } from './components/manage-dossier-access/manage-dossier-access.component';
import { AlphanumericValidatorDirective } from './directives/alphanumeric-validator.directive';
import { AssistantChatComponent } from './components/assistant-chat/assistant-chat.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatWindowComponent } from './components/assistant-chat/components/chat-window/chat-window.component';
import { ChatInputComponent } from './components/assistant-chat/components/chat-input/chat-input.component';
import { ChatThreadComponent } from './components/assistant-chat/components/chat-thread/chat-thread.component';
import { ChatThreadsComponent } from './components/assistant-chat/components/chat-threads/chat-threads.component';
import { ChatEmptyComponent } from './components/assistant-chat/components/chat-empty/chat-empty.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { RouterModule } from '@angular/router';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { DummyChatComponent } from './components/assistant-chat/components/dummy-chat/dummy-chat.component';
import { ValidateAccessDirective } from '../core/directives/validate-access.directive';
import { ChatWidgetsDirective } from './directives/chat-widgets.directive';
import { DemoWidgetComponent } from './components/assistant-chat/components/chat-widgets/demo-widget/demo-widget.component';
import { InternalRouterWidgetComponent } from './components/assistant-chat/components/chat-widgets/internal-router-widget/internal-router-widget.component';
import 'src/app/shared/utils/uisr-luxon/uisr.luxon';
import { InputFieldcheckOptionTemplateDirective } from './directives/input-field-check-option-template.directive';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { IndeterminateLoaderComponent } from './components/indeterminate-loader/indeterminate-loader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DocEditorComponent } from '../features/doc-editor/components/doc-editor/doc-editor.component';
import { DocumentEditorContainerAllModule } from '@syncfusion/ej2-angular-documenteditor';
import { StudioFolderSelectorComponent } from './components/studio-folder-selector/studio-folder-selector.component';
import { StudioFolderDataComponent } from './components/studio-folder-selector/studio-folder-data/studio-folder-data.component';
import { ClientFolderSelectorComponent } from './components/client-folder-selector/client-folder-selector.component';
import { ClientFolderDataComponent } from './components/client-folder-selector/client-folder-data/client-folder-data.component';
import { ComentarioInputComponent } from './components/comentario-input/comentario-input.component';
import { AmparoGeneratedFileComponent } from './components/assistant-chat/components/amparo-generated-file/amparo-generated-file.component';
import { DossierFolderSelectorComponent } from './components/dossier-folder-selector/dossier-folder-selector.component';
import { CrossAreaDocSelectorComponent } from './components/cross-area-doc-selector/cross-area-doc-selector.component';
import { AmparoForDocsComponent } from "../features/doc-editor/components/amparo-for-docs/amparo-for-docs.component";
import { ChatThreadHeaderComponent } from './components/assistant-chat/components/chat-thread-header/chat-thread-header.component';
import { TextSuggestionWidgetComponent } from './components/assistant-chat/components/chat-widgets/text-suggestion-widget/text-suggestion-widget.component';
import { AmparoSearchObjectComponent } from './components/assistant-chat/components/amparo-search-object/amparo-search-object.component';
import { AmparoDossierComponent } from './components/assistant-chat/components/amparo-dossier/amparo-dossier.component';
import { AddToCollectionComponent } from '../features/search/components/add-to-collection/add-to-collection.component';
import { AddCollectionComponent } from '../features/search/components/add-collection/add-collection.component';
import { PreviewResultComponent } from '../features/search/components/preview-result/preview-result.component';
import { ThreadItemComponent } from './components/assistant-chat/components/thread-item/thread-item.component';

const MODULES = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FormsModule,
  MaterialCdkModule,
  CdkAccordionModule,
  DragDropModule,
  IconsModule,
  TranslateModule,
  ToastrModule,
  ImageCropperModule,
  AngularResizeElementModule,
  NgxPaginationModule,
  NgxTippyModule,
  CKEditorModule,
  KtdGridModule,
  NgxDropzoneModule,
  MentionModule,
  LoadingElementDirective,
  ContenteditableModelDirective,
  StringConditionedStylingDirective,
  HeaderComponent,
  SidebarComponent,
  FilterByBoolPipe,
  ManageDossierAccessComponent,
  InfiniteScrollModule,
  DummyChatComponent,
  ValidateAccessDirective,
  ScheduleModule,
  IndeterminateLoaderComponent,
  NgSelectModule,
  UisrStepperComponent,
  DocumentEditorContainerAllModule,
  StudioFolderDataComponent,
  ClientFolderDataComponent,
  AmparoGeneratedFileComponent,
  DossierFolderSelectorComponent,
  ThreadItemComponent,
];

const COMPONENTS = [
  PasswordValidationComponent,
  InputGenericComponent,
  PdfViewerOverlayComponent,
  AutocompleteSelectComponent,
  AutocompleteMultiSelectComponent,
  SoNotesComponent,
  AddSoNoteComponent,
  PdfViewerModalComponent,
  InputTagNameMemberComponent,
  ComentarioComponent,
  AssistantChatComponent,
  ChatThreadComponent,
  ChatWindowComponent,
  ChatThreadsComponent,
  ChatInputComponent,
  ChatEmptyComponent,
  InputFieldComponent,
  DemoWidgetComponent,
  InternalRouterWidgetComponent,
  DocEditorComponent,
  StudioFolderSelectorComponent,
  ClientFolderSelectorComponent,
  ComentarioInputComponent,
  CrossAreaDocSelectorComponent,
  AmparoForDocsComponent,
  ChatThreadHeaderComponent,
  TextSuggestionWidgetComponent,
  AmparoSearchObjectComponent,
  AmparoDossierComponent,
  AddToCollectionComponent,
  AddCollectionComponent,
  PreviewResultComponent,
];

const DIRECTIVES = [
  AnimeJsDirective,
  ParallaxEffectDirective,
  ModalOverlayDirective,
  MentionStylerDirective,
  AlphanumericValidatorDirective,
  StopPropagationDirective,
  ResizeObserverDirective,
  ChatWidgetsDirective,
  InputFieldcheckOptionTemplateDirective,
];

const PIPES = [
  FilterForAutocompletePipe,
  VerifiedRegisterPipe,
  ValueFormDossierPipe,
  TruncatePipe,
];

const PROVIDERS = [TitleCasePipe, AngularDeviceInformationService];

@NgModule({
  declarations: [...DIRECTIVES, ...COMPONENTS, ...PIPES],
  imports: [...MODULES],
  providers: [...PROVIDERS, ...PIPES],
  exports: [...MODULES, ...DIRECTIVES, ...COMPONENTS, ...PIPES],
})
export class SharedModule { }
