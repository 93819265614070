<div
    class="px-5 py-3 border-b bg-pearl-100   border-slate-200   sticky top-0 flex justify-between items-center">
    <span class="font-semibold text-primary-100" [translate]="'REPORT_DOCUMENT_STRING'">
    </span>
    <!-- Cerrar el modal -->
    <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
        class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
        (click)="dialogRef.close()">
        <span class="sr-only" translate="CLOSE_STRING"></span>
        <i class="fa-solid text-[1rem] fa-xmark"></i>
    </button>
</div>
<div class="overflow-auto flex flex-col gap-3 p-5 max-h-[calc(85svh-11.4rem)]">
    <div class="font-medium text-slate-800 mb-3 text-sm">{{ 'REPORT_DOCUMENT_PROMPT' | translate }}</div>
    <div>
        <label class="block text-sm font-medium mb-1" for="feedback">{{'DESCRIPTION_STRING' | translate}}<span class="text-rose-500">*</span></label>
        <textarea id="feedback" [(ngModel)]="reportDescription" class="form-textarea w-full px-2 py-1 min-h-[3.6rem] max-h-[10rem]" rows="4" required=""></textarea>
    </div>
</div>
<div class="py-5 px-4 border-t bg-pearl-100   border-slate-200   sticky bottom-0 flex flex-wrap justify-end space-x-2">
    <button class="btn-sm uisr-secondary-button" (click)="dialogRef.close()" [loadingElement]="resources.submitReport" [disableOnly]="true">{{'CANCEL_STRING' | translate | titlecase}}</button>
    <button class="btn-sm uisr-primary-button" [loadingElement]="resources.submitReport" (click)="sendReport()">{{ 'REPORT_STRING' | translate }}</button>
</div>