import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';

export const LEYES_FILTER_CONTROLS: FormControlConfig[] = [
    {
        type: 'text',
        label: 'Última Reforma',
        name: 'date',
        placeholder: 'Última Reforma',
        disabled: true,
        order: 0,
    },
    {
        type: 'text',
        label: 'Tema',
        name: 'topic',
        placeholder: 'Tema',
        disabled: true,
        order: 1,
    },
];

export const ADD_COLLECTION_CONTROLS: FormControlConfig[] = [
    {
        type: 'text',
        label: 'Nombre',
        order: 0,
        name: 'name_collection',
        placeholder: 'Nombre',
        validators: [
            {
                type: 'required',
            },
            {
                type: 'maxLength',
                value: 50,
            },
        ],
    },
    {
        type: 'text',
        label: 'Descripción',
        order: 1,
        name: 'descript_collection',
        placeholder: 'Descripción',
        validators: [
            {
                type: 'required',
            },
        ],
    },
];

export const SCJN_FILTER_CONTROLS: FormControlConfig[] = [
    {
        type: 'text',
        label: 'Número de registro',
        order: 4,
        name: 'numero',
        placeholder: 'Número de registro',
        applicableTo: ['tesis', 'voto', 'ejecutoria'],
    },
    {
        type: 'text',
        label: 'Época',
        order: 2,
        name: 'epoca',
        placeholder: 'Época',
        applicableTo: ['tesis', 'voto', 'ejecutoria'],
    },
    {
        type: 'text',
        label: 'Año',
        name: 'año',
        order: 3,
        placeholder: 'Año',
        applicableTo: ['tesis', 'voto', 'ejecutoria'],
    },
    {
        type: 'text',
        order: 0,
        label: 'Instancia',
        name: 'instancia',
        placeholder: 'Instancia',
        applicableTo: ['tesis', 'voto', 'ejecutoria'],
    },
    {
        type: 'text',
        label: 'Materia',
        order: 1,
        name: 'materia',
        placeholder: 'Materia',
        applicableTo: ['tesis'],
    },
    {
        type: 'text',
        label: 'Tipo',
        order: 5,
        name: 'tipo',
        placeholder: 'Tipo',
        applicableTo: ['tesis', 'voto'],
    },
    {
        type: 'text',
        label: 'Asunto',
        order: 6,
        name: 'precedente.asunto',
        placeholder: 'Asunto',
        applicableTo: ['ejecutoria'],
    },
];

export const CJF_FILTER_CONTROLS: FormControlConfig[] = [
    {
        type: 'text',
        label: 'Número de expediente',
        name: 'numExpediente',
        order: 13,
        placeholder: 'Número de expediente',
    },
    {
        type: 'text',
        label: 'NEUN',
        name: '_id',
        order: 14,
        placeholder: 'NEUN',
    },
    {
        type: 'text',
        label: 'Estado',
        order: 11,
        name: 'estado',
        placeholder: 'Estado',
    },
    {
        type: 'text',
        label: 'Ciudad',
        order: 12,
        name: 'ciudad',
        placeholder: 'Ciudad',
    },
    {
        type: 'text',
        order: 6,
        label: 'Tipo de Asunto',
        name: 'asunto',
        placeholder: 'Tipo de asunto',
    },
    {
        type: 'text',
        label: 'Materia',
        order: 7,
        name: 'materia',
        placeholder: 'Materia',
    },
    {
        type: 'text',
        label: 'Sub Materia',
        order: 8,
        name: 'subMateria',
        placeholder: 'Sub materia',
    },
    {
        type: 'text',
        order: 5,
        label: 'Magistrados',
        name: 'magistrados',
        placeholder: 'Magistrados',
    },
    {
        type: 'text',
        label: 'Actos Reclamados',
        order: 9,
        name: 'actosReclamados',
        placeholder: 'Actos reclamados',
    },
    {
        type: 'text',
        label: 'Actos Reclamados Específicos',
        order: 10,
        name: 'actosReclamadosEspecificos',
        placeholder: 'Actos reclamados Específicos',
    },
    {
        type: 'text',
        label: 'Circuito',
        order: 1,
        name: 'circuito',
        placeholder: 'Circuito',
    },
    {
        type: 'text',
        label: 'Tipo de Órgano',
        order: 2,
        name: 'tipoOrgano',
        placeholder: 'Tipo de órgano',
    },
    {
        type: 'text',
        label: 'Órgano Jurisdiccional',
        order: 3,
        name: 'organo',
        placeholder: 'Órgano jurisdiccional',
    },
    {
        type: 'text',
        label: 'Especialidad del Órgano',
        name: 'especialidadOrgano',
        order: 4,
        placeholder: 'Especialidad del órgano',
    },
    {
        type: 'text',
        label: 'Sentido de la sentencia',
        order: 16,
        name: 'sentidoSentencia',
        placeholder: 'Sentido de la sentencia',
    },
    {
        type: 'date',
        label: 'Fecha de Presentación',
        order: 14,
        name: 'fechaPresentacion',
        placeholder: 'Fecha de presentación',
    },
    {
        type: 'date',
        label: 'Fecha de Sentencia',
        order: 15,
        name: 'fechaSentencia',
        placeholder: 'Fecha de sentencia',
    },
];

export const LEYES_ESTATALES_FILTER_CONTROLS: FormControlConfig[] = [
    {
        type: 'text',
        label: 'Estado',
        order: 11,
        name: 'estado',
        placeholder: 'Estado',
    },
];

export const CJF_FILTER_GROUPS = [
    {
        order: 0,
        name: 'Clasificación Judicial',
        filterNames: [
            'circuito',
            'tipoOrgano',
            'organo',
            'especialidadOrgano',
            'magistrados',
        ],
        opened: true,
    },
    {
        order: 1,
        name: 'Detalles del Asunto',
        filterNames: [
            'asunto',
            'materia',
            'subMateria',
            'actosReclamados',
            'actosReclamadosEspecificos',
        ],
        opened: false,
    },
    {
        order: 2,
        name: 'Ubicación Geográfica',
        filterNames: ['estado', 'ciudad'],
        opened: false,
    },
    {
        order: 3,
        name: 'Información del Caso',
        filterNames: [
            '_id',
            'numExpediente',
            'fechaPresentacion',
            'fechaSentencia',
            'sentidoSentencia',
        ],
        opened: false,
    },
];

export const CIRCUITO_CUSTOM_ORDER = [
    'primer circuito',
    'segundo circuito',
    'tercer circuito',
    'cuarto circuito',
    'quinto circuito',
    'sexto circuito',
    'séptimo circuito',
    'octavo circuito',
    'noveno circuito',
    'décimo circuito',
    'undécimo circuito',
    'duodécimo circuito',
    'decimotercer circuito',
    'decimocuarto circuito',
    'decimoquinto circuito',
    'decimosexto circuito',
    'decimoséptimo circuito',
    'decimoctavo circuito',
    'decimonoveno circuito',
    'vigésimo circuito',
    'vigésimo primer circuito',
    'vigésimo segundo circuito',
    'vigésimo tercer circuito',
    'vigésimo cuarto circuito',
    'vigésimo quinto circuito',
    'vigésimo sexto circuito',
    'vigésimo séptimo circuito',
    'vigésimo octavo circuito',
    'vigésimo noveno circuito',
    'trigésimo circuito',
];

export const CJF_VISIBLE_STATES = [
    'Ciudad de México',
    'Jalisco',
    'Estado de México',
    'Querétaro',
    'Puebla',
    'Nuevo León',
];

export const CJF_VISIBLE_SENTIDO_SENTENCIA = [
    'Ampara',
    'No ampara',
    'Sobresee',
];
