import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
    BehaviorSubject,
    distinctUntilChanged,
    Subject,
    switchMap,
} from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { DossierFilter } from 'src/app/features/law-firm/interfaces/dossier-filter.interface';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';
import { StorageObjectFilter } from 'src/app/features/law-firm/interfaces/storage-object-filter.interface';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { DossierIconStatusPipe } from 'src/app/features/law-firm/pipes/dossier-icon-status.pipe';
import { DossierStatusIconPipe } from 'src/app/features/law-firm/pipes/dossier-status-icon.pipe';
import { COLLECTION_TYPES } from 'src/app/features/search/constants/collections.constants';
import { CollectionLite } from 'src/app/features/search/interfaces/collections.interfaces';
import { MenuModule } from 'src/app/shared/modules/menu.module';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { SimpleDocPickerService } from '../simple-doc-picker.service';
import { SimpleSearchObjectDataComponent } from '../simple-search-object-data/simple-search-object-data.component';
import { SimpleStorageObjectDataComponent } from '../simple-storage-object-data/simple-storage-object-data.component';
import { DocPickerSelection } from '@interfaces/doc-picker.interfaces';

@UntilDestroy()
@Component({
    selector: 'app-select-dossier-tab',
    standalone: true,
    imports: [
        CommonModule,
        NgxTippyModule,
        ReactiveFormsModule,
        MenuModule,
        InfiniteScrollModule,
        DossierIconStatusPipe,
        DossierStatusIconPipe,
        SimpleStorageObjectDataComponent,
        SimpleSearchObjectDataComponent,
    ],
    templateUrl: './select-dossier-tab.component.html',
})
export class SelectDossierTabComponent {
    private readonly dossierUrl = '/law-firm/files/file-overview';
    private readonly api = inject(UisrApiServiceV2);
    private readonly translate = inject(TranslateService);
    private readonly router = inject(Router);
    private readonly collectionsService = inject(CollectionsService);
    readonly docPickerService = inject(SimpleDocPickerService);

    loadingCollectionsData = new BehaviorSubject(false);
    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    scrolling = new BehaviorSubject(false);
    selectedDossier?: DossierDTO;
    selectedDossierFileType: 'documents' | 'legislation' = 'documents';
    storageObjects: any[] = []; // Listado de documentos o carpetas de una asunto
    searchControl: FormControl<null | string> = new FormControl(null);
    selectedFolder?: StorageObject;
    dossiers: DocPickerSelection<DossierDTO>[] = [];
    dossierFilterSubject = new Subject<DossierFilter>();
    dossierFilter: DossierFilter = {};
    dossierPagination = structuredClone(PAGINATION_CONFIG);
    searchObjects: any[] = []; // Listado de documentos de una colección
    selectedCollection?: CollectionLite;
    filteredSearchObjects: any[] = [];

    @Input() extensions?: string[] = [];
    @Input() sizeLimit?: number;

    @Output() onDossierChange = new EventEmitter<DossierDTO | undefined>();

    collections = computed(() => {
        return this.collectionsService
            .userCollections()
            .filter(
                (c) => c.fk_id_type_collection == COLLECTION_TYPES.DOSSIERS
            );
    });

    ngOnInit() {
        this.subscribeToDossierFilter();
        this.applyDossierFilter({ pageNumber: 1, pageSize: 10 });
    }

    /** Se suscribe al observable del filtro de asuntos para realizar una búsqueda en cuanto hayan cambios */
    private subscribeToDossierFilter() {
        this.dossierFilterSubject
            .pipe(
                distinctUntilChanged(isEqual),
                switchMap((filter) => {
                    this.dossierPagination.currentPage = filter.pageNumber || 1;
                    this.dossierPagination.itemsPerPage = filter.pageSize || 10;

                    return this.api
                        .post(RESOURCES.dossierList, filter)
                        .pipe(loadingState(this.loading));
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res: APIResponse<DossierDTO[]>) => {
                    this.dossiers = this.docPickerService.buildSelectableItems(
                        res.data
                    );
                    this.dossierPagination.totalItems = res.total || 0;

                    this.setDefaultDossier();
                },
            });
    }

    /** Verifica si el componente se abrió en la ruta de detalle de un asunto y si es así, selecciona el asunto abierto como asunto por defecto */
    private setDefaultDossier() {
        const url = this.router.url;
        if (url.includes(this.dossierUrl)) {
            let id = url.split('?')[0].split('/').pop();

            let selected = this.dossiers.find((x) => x.idDossier == Number(id));
            if (!selected) return;

            this.selectDossier(selected);
        }
    }

    /** Permite seleccionar un asunto y consultar sus documentos y carpetas. Solo se ejecuta si el asunto seleccionado es diferente al actual */
    selectDossier(dossier: DossierDTO) {
        if (this.selectedDossier?.idDossier != dossier.idDossier) {
            this.selectedDossier = dossier;
            this.onDossierChange.emit(dossier);
            this.getFolderData();
        }
    }

    /** Permite hacer modificaciones al filtro de asuntos antes de realizar una búsqueda, elimina los campos vacíos */
    private applyDossierFilter(newFilter: DossierFilter) {
        this.dossierFilter = { ...this.dossierFilter, ...newFilter };

        this.dossierFilter = UisrTools.removeEmptyProperties(
            this.dossierFilter
        );

        this.dossierFilterSubject.next(this.dossierFilter);
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al realizar la búsqueda, si se ha seleccionado un asunto, buscar en storage objects, de lo contrario, filtrar los asuntos */
    onSearch() {
        if (this.selectedDossierFileType == 'documents') {
            if (this.selectedDossier) {
                if (this.searchControl.value) {
                    this.onStorageObjectSearch();
                } else {
                    this.storageObjects = [];
                    this.getFolderData();
                }
            } else {
                this.applyDossierFilter({
                    pageNumber: 1,
                    searchValue: this.searchControl.value || undefined,
                });
            }
        } else {
            this.filteredSearchObjects = this.searchObjects.filter(
                (c) =>
                    !this.searchControl.value ||
                    c.heading?.toLowerCase().includes(this.searchControl.value)
            );
        }
    }

    /** Realiza la búsqueda de storage object con el valor del input de búsqueda de carpetas y documentos */
    private onStorageObjectSearch() {
        const filter: StorageObjectFilter = {};
        filter.dossierId = Number(this.selectedDossier?.idDossier);
        filter.searchValue = this.searchControl.value || undefined;
        if (!filter.dossierId) return;

        filter.fileExts = this.extensions;

        this.api
            .get(RESOURCES.storageObjects, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.storageObjects =
                        this.docPickerService.buildSelectableItems(res.data);

                    this.selectedFolder = undefined;
                },
            });
    }

    /** Permite obtener los datos de una carpeta, obtiene sus documentos y sub-carpetas */
    private getFolderData(folder?: StorageObject) {
        const filter: StorageObjectFilter = {};
        if (folder) {
            this.selectedFolder = folder;
            filter.dossierId = Number(this.selectedFolder.dossierId);
            filter.parentId = Number(this.selectedFolder.id);
        } else {
            filter.dossierId = Number(this.selectedDossier?.idDossier);
        }

        filter.fileExts = this.extensions;

        if (!filter.dossierId) return;

        this.api
            .get(RESOURCES.storageObjectDetails, filter)
            .pipe(loadingState(this.loadingFolder), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    const selectableItems =
                        this.docPickerService.buildSelectableItems(
                            res.data.files
                        );
                    if (!folder) {
                        this.storageObjects = [
                            ...res.data.folders,
                            ...selectableItems,
                        ];
                    } else {
                        folder.storageObjects = [
                            ...res.data.folders,
                            ...selectableItems,
                        ];
                        folder.totalFiles = res.data.totalFiles || 0;
                    }

                    this.selectedFolder = undefined;
                },
            });
    }

    /** Buscar la siguiente página de asuntos al hacer scroll */
    nextPage() {
        if (
            this.dossierPagination.totalItems &&
            this.dossierPagination.totalItems > this.dossiers.length
        ) {
            this.dossierFilter.pageNumber =
                this.dossierPagination.currentPage + 1;
            this.api
                .post(RESOURCES.dossierList, this.dossierFilter)
                .pipe(loadingState(this.scrolling), untilDestroyed(this))
                .subscribe({
                    next: (res: APIResponse<DossierDTO[]>) => {
                        const dossiers =
                            this.docPickerService.buildSelectableItems(
                                res.data
                            );
                        this.dossiers.push(...dossiers);
                        this.dossierPagination.currentPage =
                            this.dossierFilter.pageNumber || 1;
                        this.dossierPagination.itemsPerPage =
                            this.dossierFilter.pageSize || 10;
                        this.dossierPagination.totalItems = res.total || 0;
                    },
                });
        }
    }

    /** Volver al listado principal ya sea de asuntos o de colecciones según selectedTab y reiniciar los listados de documentos */
    returnToList() {
        this.selectedCollection = undefined;
        this.selectedDossier = undefined;
        this.storageObjects = [];
        this.selectedDossierFileType = 'documents';
        this.onDossierChange.emit(undefined);
    }

    /** Obtiene el texto que se debe mostrar en el searchInput según la vista en la que está el usuario */
    getInputPlaceholder() {
        if (this.selectedDossierFileType == 'legislation') {
            return 'Busca por nombre del documento';
        }
        return this.selectedDossier
            ? 'Busca por nombre del documento o carpeta'
            : 'Busca por titulo, número u órgano del asunto';
    }

    trackByFn(index: any) {
        return index;
    }

    /** Permite cambiar el tipo de documentos del asunto entre documentos cargados por el usuario o legislación (search_objects agregados a la colección del selectedDossier) */
    changeDossierFileType(action: 'documents' | 'legislation') {
        if (this.selectedDossierFileType != action) {
            this.selectedDossierFileType = action;

            if (
                this.selectedDossierFileType == 'legislation' &&
                this.selectedDossier
            ) {
                const dossierCollection = this.collectionsService
                    .userCollections()
                    .find(
                        (c) =>
                            c.fk_id_dossier == this.selectedDossier?.idDossier
                    );
                if (dossierCollection) {
                    this.selectCollection(dossierCollection);
                }
            }
        }
    }

    /** Permite seleccionar una colección y consultar sus search_objects. Solo se ejecuta si la colección seleccionada es diferente a la actual */
    private selectCollection(collection: CollectionLite) {
        if (
            this.selectedCollection?.id_collection_user !=
            collection.id_collection_user
        ) {
            this.selectedCollection = collection;
            this.getCollectionData();
        }
    }

    /** Obtiene los searchObjects asociados a la colección seleccionada */
    private getCollectionData() {
        if (!this.selectedCollection) return;

        let data: any = {
            num: 10000,
            page: 1,
            id_collection_user: this.selectedCollection.id_collection_user,
        };

        this.api
            .get(RESOURCES.getCollectionDetails, data)
            .pipe(
                loadingState(this.loadingCollectionsData),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.searchObjects =
                            this.docPickerService.buildSelectableItems(
                                res.data
                            );
                        this.filteredSearchObjects = this.searchObjects;
                    } else {
                        let message = this.translate.instant(
                            'GENERIC_ERROR_MESSAGE'
                        );
                        if (res.code) {
                            let messageAux = this.translate.instant(res.code);
                            message =
                                messageAux != res.code ? messageAux : message;
                        }
                        Swal.fire({
                            ...ALERT_DEFAULTS,
                            text: message,
                        });
                    }
                },
            });
    }

    /** Al hacer click en un documento o carpeta, se determina si se debe consultar la información extra o si se agrega al arreglo de documentos seleccionados */
    onStorageObjectClick(storageObject: DocPickerSelection<StorageObject>) {
        if (storageObject.file) {
            this.docPickerService.updateSelection({
                ...storageObject.file,
                reference: storageObject.reference,
                type: storageObject.type,
            });
        } else {
            if (storageObject.storageObjects && storageObject.open) {
                storageObject.open = false;
            } else if (!storageObject.storageObjects) {
                storageObject.open = true;
                this.getFolderData(storageObject);
            } else {
                storageObject.open = true;
            }
        }
    }

    /** Al hacer click en un elemento de una colección, se agrega al arreglo de documentos seleccionados */
    onSearchObjectClick(object: any) {
        this.docPickerService.updateSelection(object);
    }

    toggleDossierSelection(dossier: DocPickerSelection<DossierDTO>) {
        this.docPickerService.updateSelection(dossier);
    }
}
