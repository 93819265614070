<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center"
  *ngIf="dialogRef">
  <span *ngIf="dialogData['origin'] != 'home'" class="font-semibold text-primary-100" translate="COPY_DOCUMENT"></span>
  <span *ngIf="dialogData['origin'] == 'home'" class="font-semibold text-primary-100" translate="SAVE_DOCUMENT_STRING"></span>
  <!-- Cerrar el modal -->
  <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
    class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
    (click)="dialogRef.close()" id="close-doc-picker">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid text-[1rem] fa-xmark"></i>
  </button>
</div>

<div class="pt-5 max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl" id="doc-picker-container">
  <p *ngIf="dialogData['origin'] != 'home'" class="px-5 text-slate-600 text-sm" translate="COPY_DOCUMENT_PROMPT"></p>
  <p *ngIf="dialogData['origin'] == 'home'" class="px-5 text-slate-600 text-sm" translate="SAVE_DOCUMENT_PROMPT"></p>
  

  <div class="px-5 flex my-2 gap-1.5 items-center text-slate-600 text-sm" *ngIf="canMove && dialogData['origin'] != 'home'">
    <input type="checkbox" id="move-control" name="move-control" class="form-checkbox" [(ngModel)]="move">
    <label class="flex items-center gap-2 cursor-pointer" for="move-control">
      Eliminar de la carpeta actual (Mover)
    </label>
  </div>

  <!-- Tabs -->
  <div class="flex pb-2 mt-4 border-b border-slate-200 min-w-0">
    <ng-container>
      <button *ngFor="let tab of tabs; let i = index;" (click)="changeTab(tab.action)"
        class="grid place-items-center first:pl-5 pr-5 max-md:py-2 text-slate-400 active:text-indigo-500 font-medium active:font-semibold"
        [id]="'tab-'+tab.action" [ngClass]="{'active': selectedTab == tab.action}">
        {{tab.label}}
      </button>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="selectedTab">
    <div *ngSwitchCase="targetTypes.Dossiers">
      <app-dossier-folder-selector [embedded]="true" (selectionChange)="onSelectionChange($event)" />
    </div>
    <div *ngSwitchCase="targetTypes.Clients">
      <app-client-folder-selector [embedded]="true" (selectionChange)="onSelectionChange($event)" />
    </div>
    <div *ngSwitchCase="targetTypes.Studio">
      <app-studio-folder-selector [embedded]="true" [originalId]="originalId" (selectionChange)="onSelectionChange($event)" />
    </div>
  </ng-container>

</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2"
  *ngIf="dialogRef">
  <button [disabled]="loading | async" class="uisr-secondary-button" (click)="dialogRef.close()">
    <span translate="CANCEL_STRING"></span>
  </button>
  <button  *ngIf="dialogData['origin'] != 'home'" class="uisr-primary-button flex justify-center items-center gap-1" (click)="onSubmit()"
    [disabled]="!selection.size">
    <span translate="COPY_STRING"></span>
  </button>
  <button  *ngIf="dialogData['origin'] == 'home'" class="uisr-primary-button flex justify-center items-center gap-1" (click)="onSubmit()"
  [disabled]="!selection.size">
  <span translate="SAVE_STRING"></span>
</button>
</div>