import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { StudioFolderSelectorComponent } from '../studio-folder-selector.component';

@Component({
    selector: 'app-studio-folder-data',
    standalone: true,
    imports: [CommonModule, TranslateModule, NgxTippyModule],
    templateUrl: './studio-folder-data.component.html',
    host: {
        class: 'contents',
    },
})
export class StudioFolderDataComponent {
    container = inject(StudioFolderSelectorComponent, { optional: true, skipSelf: true });
    @Input({ required: true }) folders: StudioFiles[] = [];
    @Input() parentId?: number;
    @Input() originalId?: number;

    trackByFn(index: any, item: any) {
        return index;
    }

    isLoading() {
        return this.container?.loading.value;
    }
}
