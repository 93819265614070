//ES NECESARIO ACTUALIZAR ESTA VARIABLE CUANDO SE HAGAN CAMBIOS COMO AGREGAR O ELIMINAR PASAS EN EL USER JOURNEY
export const VERSION_DATE = '2024-10-22'

export interface JourneyStep {
  step: string;
  status: string;
  label: string;
  optional: boolean;
  action: string;
  priority: string;
  route: string;
  queryParams?: {
    journey: boolean;
  };
  subtitle?: string;
  access?: string;
  helpLink?: string;
}

export const EXPLORADOR_DIGITAL_STEPS: JourneyStep[] = [
  {
    step: 'CLIENT',
    status: 'INCOMPLETE',
    label: 'Añade tu primer cliente',
    optional: false,
    action: 'Añade tu primer cliente',
    priority: '1',
    route: '/law-firm/clients',
    queryParams: {
      journey: true,
    },
    subtitle: '',
    helpLink:
      'https://midespacho.notion.site/Crea-tu-primer-cliente-5fd57f61f1ed42dea6123f7ef810626b',
    // access: 'clientes:crear'
  },
  {
    step: 'CLIENT_CONTACT',
    status: 'INCOMPLETE',
    label: 'Agrega correo electrónico y teléfono',
    optional: true,
    action: 'Completa los datos de contacto',
    priority: '2',
    route: '/law-firm/clients',
    subtitle: '',
    helpLink:
      'https://midespacho.notion.site/Administra-tus-clientes-de-inicio-a-fin-9c9a8eabbb1e489e82b8de18029e30c5',
    // access: 'clientes:crear'
  },
  {
    step: 'CLIENT_FILE',
    status: 'INCOMPLETE',
    label: 'Agrega documentos del cliente',
    optional: true,
    action: 'Sube documentos del cliente',
    priority: '2',
    route: '/law-firm/clients',
    subtitle: 'Ej. Identificación, contrato, etc.',
    helpLink:
      'https://midespacho.notion.site/Administra-tus-clientes-de-inicio-a-fin-9c9a8eabbb1e489e82b8de18029e30c5',
      // access: 'clientes:editar'
  },
  {
    step: 'LAW_FIRM_TEMPLATE',
    status: 'INCOMPLETE',
    label: 'Crea una nueva plantilla personalizada',
    optional: false,
    action: 'Crea o personaliza una plantilla',
    priority: '1',
    route: '/law-firm/settings/templates/add',
    helpLink: 'https://midespacho.notion.site/Selecciona-o-crea-una-Plantilla-para-tus-asuntos-5b13a2b7d1a44137a84fed532a7e19e1',
    subtitle:'',
    // access:'plantilla:crear'
  },
  {
    step: 'DOSSIER',
    status: 'INCOMPLETE',
    label: 'Añade tu primer asunto',
    optional: false,
    action: 'Registra tu primer asunto',
    priority: '1',
    route: '/law-firm/files/add',
    subtitle: 'Puede ser carga manual o masiva',
    helpLink:'https://midespacho.notion.site/Crea-tu-primer-asunto-ff52e5121d5a4d9eb0c74622e043b7a3',
    // access:'expedientes:crear'
  },
];
export const CONFIGURATION_STEPS: JourneyStep[] = [
  {
    step: 'LAW_FIRM_IMAGE',
    status: 'INCOMPLETE',
    label: 'Añade el logo de tu despacho',
    optional: true,
    action: 'Sube tu logo',
    priority: '1',
    route: '/law-firm/settings/info',
    helpLink:
      'https://midespacho.notion.site/Personaliza-tu-despacho-431265913dcc423293445cb8eac1ea48',
    // access:'despacho:editar'
  },
  {
    step: 'ADD_MEMBER',
    status: 'INCOMPLETE',
    label: 'Invita a tus colaboradores',
    optional: true,
    action: 'Invita a tus colaboradores',
    priority: '1',
    route: '/law-firm/settings/members',
    queryParams: {
      journey: true,
    },
    helpLink:'https://midespacho.notion.site/A-ade-tu-Equipo-Colaborador-a-un-asunto-89371555b9664615bc5ba67c110fd03e',
    // access: 'usuarios:invitar'
  },
  {
    step: 'ROLE',
    status: 'INCOMPLETE',
    label: 'Crea un rol para tus colaboradores',
    optional: true,
    action: 'Define roles para colaboradores',
    priority: '1',
    route: '/law-firm/settings/members',
    helpLink:
      'https://midespacho.notion.site/Gesti-n-de-Accesos-y-Permisos-e985a36738324a3aa1df60c70ac620c2',
    // access : 'rol:crear'
  },
  {
    step: 'USER_GROUP',
    status: 'INCOMPLETE',
    label: 'Crea un grupo para facilitar permisos',
    optional: true,
    action: 'Crea un grupo de permisos',
    priority: '1',
    route: '/law-firm/settings/groups',
    queryParams: {
      journey: true,
    },
    helpLink:'https://midespacho.notion.site/Crea-y-gestiona-Grupos-9d163bd812524f1b8da7959d68dce14a'

  },
];
export const INNOVADOR_LEGAL_STEPS: JourneyStep[] = [
  {
    step: 'INVOICE',
    status: 'INCOMPLETE',
    label: 'Crea tu primer cobro',
    optional: true,
    action: 'Registra tu primer cobro',
    priority: '1',
    route: '/law-firm/e-collections',
    queryParams: {
      journey: true,
    },
    helpLink:'https://midespacho.notion.site/Administra-tu-cobranza-Crea-cobros-00b1b0a4fb484a34a9f70c91f6271865',
    // access:'cobranzas:crear'
  },
  {
    step: 'SEND_INVOICE',
    status: 'INCOMPLETE',
    label: 'Envía tu primera factura por correo',
    optional: true,
    action: 'Envía la factura al cliente',
    priority: '1',
    route: '/law-firm/e-collections',
    helpLink:'https://midespacho.notion.site/Administra-tu-cobranza-Crea-cobros-00b1b0a4fb484a34a9f70c91f6271865',

  },
  {
    step: 'DOSSIER_FILE',
    status: 'INCOMPLETE',
    label: 'Añade un documento al expediente',
    optional: false,
    action: 'Sube un documento al expediente',
    priority: '1',
    route: '/law-firm/files',
    helpLink:
      'https://midespacho.notion.site/Gestiona-tus-asuntos-Documenta-y-A-ade-Fuentes-b82b856fa5684ce494672adcad41ae84',
    // access:'expedientes:crear'
  },
  {
    step: 'REMINDER',
    status: 'INCOMPLETE',
    label: 'Añade un recordatorio al expediente',
    optional: true,
    action: 'Programa un recordatorio',
    priority: '1',
    route: '/law-firm/files',
    helpLink:
      'https://midespacho.notion.site/Centraliza-tus-Eventos-y-a-ade-Recordatorios-12e997edf31e806c8e6dd9e828ba7de8',
  },
];

export const VISIONARIO_JURIDICO_STEPS: JourneyStep[] = [
  {
    step: 'REPORT',
    status: 'INCOMPLETE',
    label: 'Crea tu primer reporte',
    optional: true,
    action: 'Genera tu primer reporte',
    priority: '1',
    route: '/law-firm/reports/create',
    helpLink:
      'https://midespacho.notion.site/Crea-tu-primer-Reporte-para-visualizar-tus-asuntos-de-forma-ptima-e018851bb925481dada10bd6e9e7bca9',
  },
  {
    step: 'TEMPLATE_MACHOTE',
    status: 'INCOMPLETE',
    label: 'Agiliza tu redacción legal con una plantilla',
    optional: true,
    action: 'Usa una plantilla legal',
    priority: '1',
    route: '/law-firm/library/templates',
    helpLink:'https://midespacho.notion.site/Qu-tipos-de-plantillas-machotes-hay-disponibles-3112641c756c4054b707ebd2872c71d9'
  },
  {
    step: 'BLOG_POST',
    status: 'INCOMPLETE',
    label: 'Crea tu primera publicación del Blog',
    optional: true,
    action: 'Escribe una entrada de blog',
    priority: '1',
    route: '/law-firm/library/post',
    helpLink:'https://midespacho.notion.site/C-mo-sacarle-provecho-al-m-dulo-de-Biblioteca-5b5a8161e4e848f591883c5dd9143847'
  },
  {
    step: 'DOCUMENT_AI',
    status: 'INCOMPLETE',
    label: 'Carga tu primer documento a una conversación con Amparo IA',
    optional: true,
    action: 'Carga tu primer documento a una conversación con Amparo IA',
    priority: '1',
    route: '',
    helpLink:'https://midespacho.notion.site/C-mo-utilizar-Amparo-IA-para-hacer-mi-trabajo-m-s-eficiente-7b41d45c50e14b8ab2a044f6f43c51f9'
  },
];
