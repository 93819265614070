<div class="w-full group">
	<ng-content></ng-content>

	<div *ngIf="label || infoTooltip" class="flex items-center justify-between gap-2 mb-1">
		<label *ngIf="label" [attr.for]="inputID" class="block text-sm font-medium">
			{{ label }}
		</label>
		<i *ngIf="infoTooltip" [ngxTippy]="infoTooltip ? infoTooltip : null"
			class="fa-duotone fa-circle-info text-slate-400" style="font-size: 1rem"></i>
	</div>
	<div class="relative">
		<!-- NGSelect2 -->
		<ng-select *ngIf="inputType == 'ngselect'" [id]="inputID" class="ng-select-override"
			[ngClass]="{ invalid: showInvalidState && invalid && (dirty || touched), labelTemplate: selectLabelTemplate, optionTemplate: selectOptionTemplate, iconLeft: icon && iconPos == 'left', iconRight: icon && iconPos == 'right' }"
			[class]="class" [required]="required" [spellcheck]="spellCheck" [disabled]="disabled"
			[placeholder]="placeholder" [(ngModel)]="value" [items]="items" [searchable]="searchable"
			[clearable]="clearable" [multiple]="multiple" [bindLabel]="bindLabel" [bindValue]="bindValue"
			[searchFn]="searchFn ? searchFn : null" [closeOnSelect]="true" [clearSearchOnAdd]="true"
			(change)="onChange($event)" (input)="onInput($event)" (blur)="onBlur($event)" (focus)="onFocus($event)"
			(search)="onSearch($event)" (open)="onOpen($event)" (scrollToEnd)="onScrollToEnd()">
			<ng-template *ngIf="selectLabelTemplate" ng-label-tmp let-item="item" let-index="index"
				let-search="searchTerm">
				<ng-container
					*ngTemplateOutlet="selectLabelTemplate; context: { item: item, index: index, searchTerm: search }"></ng-container>
			</ng-template>
			<ng-template *ngIf="selectOptionTemplate" ng-option-tmp let-item="item" let-index="index"
				let-search="searchTerm">
				<ng-container
					*ngTemplateOutlet="selectOptionTemplate; context: { item: item, index: index, searchTerm: search }"></ng-container>
			</ng-template>
			<ng-template *ngIf="selectNotFoundTemplate" ng-notfound-tmp let-search="searchTerm">
				<ng-container
					*ngTemplateOutlet="selectNotFoundTemplate; context: { searchTerm: search }"></ng-container>
			</ng-template>
		</ng-select>

		<!-- Textarea -->
		<textarea *ngIf="['textarea'].includes(inputType)" [id]="inputID" class="form-textarea w-full block"
			[ngClass]="{ '!border-rose-300': showInvalidState && invalid && (dirty || touched), '!pr-[2.25rem]': iconPos == 'right' && icon, '!pl-[2.25rem]': iconPos == 'left' && icon }"
			[class]="class" [required]="required" [spellcheck]="spellCheck" [autocomplete]="autoComplete"
			[disabled]="disabled" [readOnly]="readOnly" [placeholder]="placeholder" [(ngModel)]="value"
			(keydown)="chatTextArea($event)" (input)="onInput($event)" (blur)="onBlur($event)"
			(focus)="onFocus($event)"></textarea>

		<!-- Input - 'text', 'number', 'email', 'password', 'date' -->
		<input *ngIf="['text', 'number', 'email', 'password', 'date'].includes(inputType)" [id]="inputID"
			[type]="inputType == 'date' ? 'text' : inputType" class="form-input w-full"
			[ngClass]="{ '!border-rose-300': showInvalidState && invalid && (dirty || touched), '!pr-[2.25rem]': iconPos == 'right' && icon, '!w-0 !pl-0' : iconPos == 'right' && icon && iconMode, '!pl-[2.25rem]': iconPos == 'left' && icon, '!w-0 !pr-0' : iconPos == 'left' && icon && iconMode }"
			[class]="class" [required]="required" [spellcheck]="spellCheck" [autocomplete]="autoComplete"
			[disabled]="disabled" [min]="min" [max]="max" [readOnly]="inputType == 'date' ? true : readOnly"
			[placeholder]="placeholder" [(ngModel)]="value" (input)="onInput($event)" (blur)="onBlur($event)"
			(focus)="onFocus($event)" />

		<!-- Input - 'radio' -->
		<label *ngIf="['radio'].includes(inputType)" [attr.for]="inputID"
			class="flex items-center gap-2 h-[2.375rem] w-full select-none cursor-pointer"
			[ngClass]="{ hidden: checkOptionTemplate, '!cursor-not-allowed': disabled }">
			<div *ngIf="leftLabel || checkOptionTemplate" class="text-sm text-slate-400 italic">
				{{ leftLabel }}
			</div>
			<input [id]="inputID" class="form-radio cursor-pointer" [ngClass]="{ '!cursor-not-allowed': disabled }"
				[type]="inputType" [class]="class" [required]="required" [disabled]="disabled" [readOnly]="readOnly"
				[value]="radioValue" [checked]="checked" [(ngModel)]="value" (change)="onChange($event)"
				(blur)="onBlur($event)" (focus)="onFocus($event)" />
			<div *ngIf="rightLabel || checkOptionTemplate" class="text-sm text-slate-400 italic">
				{{ rightLabel }}
			</div>
		</label>
		<label *ngIf="['radio', 'switch', 'checkbox'].includes(inputType) && checkOptionTemplate" [attr.for]="inputID"
			class="flex items-center w-full cursor-pointer min-h-[2.375rem]"
			[ngClass]="{ '!cursor-not-allowed': disabled }">
			<ng-container
				*ngTemplateOutlet="checkOptionTemplate; context: { checked: checked, disabled: disabled, readOnly: readOnly }"></ng-container>
		</label>

		<!-- Input - 'switch' - 'checkbox' -->
		<label *ngIf="['switch', 'checkbox'].includes(inputType)" [attr.for]="inputID"
			class="flex items-center gap-2 h-[2.375rem] w-full select-none cursor-pointer"
			[ngClass]="{ hidden: checkOptionTemplate, '!cursor-not-allowed': disabled }">
			<div *ngIf="leftLabel" class="text-sm text-slate-400 italic">
				{{ leftLabel }}
			</div>
			<div *ngIf="['switch'].includes(inputType)" class="form-switch pointer-events-auto">
				<input [id]="inputID" class="sr-only" [type]="'checkbox'" [class]="class" [required]="required"
					[disabled]="disabled" [readOnly]="readOnly" [checked]="checked" (change)="onChange($event)" />
			</div>
			<input *ngIf="['checkbox'].includes(inputType)" [id]="inputID" class="form-checkbox cursor-pointer"
				[ngClass]="{ '!cursor-not-allowed': disabled }" [type]="inputType" [class]="class" [required]="required"
				[disabled]="disabled" [readOnly]="readOnly" [checked]="checked" (change)="onChange($event)" />
			<div *ngIf="rightLabel" class="text-sm text-slate-400 italic">
				{{ rightLabel }}
			</div>
		</label>

		<!-- Icon - Loading -->
		<div *ngIf="(icon || loadingState) && !['radio'].includes(inputType)"
			class="absolute top-0 flex items-center justify-center h-[2.375rem] w-[2.375rem] pointer-events-none group"
			[ngClass]="{ 'right-0': iconPos == 'right', 'left-0': iconPos == 'left'}">
			<button type="button" *ngIf="!loadingState" [disabled]="disabled"
				[ngxTippy]="iconTooltip ? iconTooltip : null" class="btn !p-0 !border-none !shadow-none"
				[ngClass]="{ 'pointer-events-auto': enableIconClick || iconTooltip, 'cursor-pointer': enableIconClick }"
				(click)="enableIconClick ? onIconClick.emit($event) : null">
				<i class="fa-duotone text-slate-400 opacity-75 group-hover:opacity-100" [ngClass]="icon"
					style="font-size: 1.25rem"></i>
			</button>

			<div
				[ngClass]="{'pr-[2.25rem]':loadingState && !icon && iconPos == 'right', 'pl-[2.25rem]':loadingState && !icon && iconPos == 'left',}">
				<svg *ngIf="loadingState" viewBox="0 0 16 16" class="animate-spin w-4 h-4 fill-slate-400 shrink-0">
					<path
						d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z">
					</path>
				</svg>
			</div>

		</div>
	</div>
	<div *ngIf="generalError && showInvalidState && invalid && (dirty || touched)" class="text-xs mt-1 text-rose-500">
		{{ generalError }}
	</div>
</div>