import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, filter, lastValueFrom } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';
import { UisrAnalyticsService } from 'src/app/core/services/analytics.service';
import { ComponentBlocker } from 'src/app/core/utils/component-blocker';
import { JsEncode } from 'src/app/core/utils/js-encode';
import { LOGIN_CONTROLS } from 'src/app/features/security/constants/security-controls.constants';
import { ModalOverlayDirective } from 'src/app/shared/directives/modal-overlay.directive';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { UisrGenericFormControlsService } from 'src/app/shared/services/uisr-generic-form-controls.service';
import Swal from 'sweetalert2';
import { ALERT_DEFAULTS } from '../../../../core/constants/alert-defaults.constants';
import { UisrAuthService } from '../../../../core/services/uisr-auth.service';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { WebSocketService } from 'src/app/core/services/v2-socket.io.service';
@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, AfterViewInit {
  private readonly intercomService = inject(IntercomService);
  private readonly socketService = inject(WebSocketService);
  private readonly controlsService = inject(UisrGenericFormControlsService);
  private readonly componentBlocker = inject(ComponentBlocker);
  private readonly toastr = inject(ToastrService);
  private readonly apiService = inject(UisrApiServiceV2);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly socialAuthService = inject(SocialAuthService);
  private readonly route = inject(ActivatedRoute);
  private readonly elementRef = inject(ElementRef);
  private readonly analytics = inject(UisrAnalyticsService);
  private readonly authService = inject(UisrAuthService);
  readonly dialogData = inject(DIALOG_DATA, { optional: true });
  readonly dialogRef = inject(DialogRef, { optional: true });
  
  loginForm: FormGroup;
  formControls: FormControlConfig[] = LOGIN_CONTROLS;
  pendingProcess: boolean = false;
  showWelcomeMessage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mailVal: boolean | null | undefined = null;
  resources = RESOURCES;
  
  private twoFactorData: any;

  @ViewChild('tpl2FA', { read: ModalOverlayDirective })
  validateCodeModal!: ModalOverlayDirective;

  constructor() {
    this.translateService.setDefaultLang('es');
    this.loginForm = this.controlsService.createFormGroup(this.formControls);
    this.route.queryParams.subscribe((params) => {
      if (params['mailval']) {
        this.mailVal = params['mailval'] == 'true';
      }
    });
  }

  ngOnInit(): void {
    this.subscribeToGoogleSso();
    this.showMailValAlert();
  }

  ngAfterViewInit(): void {
    this.componentBlocker.loaderForm
      .pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el) {
          this.loginForm.disable();
        } else {
          this.loginForm.enable();
        }
      });
  }

  showMailValAlert() {
    if (this.mailVal) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: '¡Verificación Exitosa!',
        text: 'Has verificado tu cuenta de manera satisfactoria.',
        confirmButtonText: 'Aceptar',
        showConfirmButton: true,
      });
    }

    if (this.mailVal === false) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Enlace Expirado',
        text: `El enlace al que intentaste acceder ha caducado. Esto suele suceder cuando ha pasado mucho tiempo desde su generación. No hay problema: puedes solicitar un nuevo enlace si lo necesitas.`,
        confirmButtonText: 'Aceptar',
        showConfirmButton: true,
      });
    }
  }

  /** Captura la respuesta al continuar con google */
  subscribeToGoogleSso() {
    this.socialAuthService.authState
      .pipe(untilDestroyed(this))
      .subscribe((user: SocialUser | null) => {
        if (!user) {
          return;
        }
        this.processSsoLogic(user);
      });
  }

  async processSsoLogic(user: any) {
    if (this.pendingProcess) {
      let pendingProcessMessage = await lastValueFrom(
        this.translateService.get('PENDING_PROCESS')
      );
      this.toastr.info(pendingProcessMessage);
      return;
    }

    let data = { sso: JsEncode.encrypt(JSON.stringify(user)) };

    this.sendRequest(data, true, 'Google');
  }

  async sendRequest(data: any, hasSsoSignOut: boolean = false, method: string) {
    this.pendingProcess = true;
    this.apiService
      .post(this.resources.login, data, '', ['button', 'form'])
      .pipe(untilDestroyed(this))
      .pipe(
        filter((res: any) => {
          return (
            res.system.maintenance == false ||
            res.userData.email.includes('@uisr.io')
          );
        })
      )
      .subscribe({
        next: async (res: any) => {
          this.pendingProcess = false;
          if (res.authorization.two_steps) {
            setTimeout(() => {
              this.openValidationModal(res.authorization);
            }, 500);
            return;
          }
          if (hasSsoSignOut) {
            this.socialAuthService.signOut();
          }
          if (res.authorization.action != 'register') {
            this.analytics.trackLoginEvent(method, res.userData);
            localStorage.setItem('userData', JSON.stringify(res.userData));
            await this.getPermissions();
            this.onLogin();
          } else if (res.authorization.action == 'register') {
            this.showWelcomeMessage.next(true);
            return;
          }
        },
        error: (_error) => {
          this.toastr.clear();
          this.pendingProcess = false;
        },
      });
  }

  openValidationModal(res: any) {
    this.twoFactorData = res;
    this.validateCodeModal.open();
  }

  handleTwoFactor(code: number) {
    if (code) {
      let data = {
        hash: this.twoFactorData.hash,
        code: code,
      };
      this.pendingProcess = true;
      this.apiService
        .post(this.resources.validate2FACode, data, '', ['button', 'form'])
        .pipe(untilDestroyed(this))
        .subscribe({
          next: async (res: any) => {
            this.pendingProcess = false;
            if (res.success) {
              this.analytics.trackLoginEvent('2FA', res.userData);
              localStorage.setItem('userData', JSON.stringify(res.userData));
              await this.getPermissions();
              this.onLogin();
            }
            if (!res.success) {
              let message = 'Ocurrió un error inesperado.';
              if (res.code) {
                let messageAux = await lastValueFrom(
                  this.translateService.get(res.code)
                );
                message = messageAux != res.code ? messageAux : message;
              }
              Swal.fire({
                ...ALERT_DEFAULTS,
                text: message,
                confirmButtonText: 'Aceptar',
                showConfirmButton: true,
              });
            }
          },
          error: (_error) => {
            this.toastr.clear();
            this.pendingProcess = false;
          },
        });
    }
  }

  async onFormSubmit() {
    if (!this.loginForm.valid) {
      return this.controlsService.getGenericFormErrorMessage();
    }
    let data = this.loginForm.value;
    data.user = data.user.toLowerCase();
    this.sendRequest(data, undefined, 'Standard');
  }

  onLogin() {
    this.dialogRef?.close();
    const ruta = localStorage.getItem('ruta');
    localStorage.removeItem('ruta');
    if (!ruta?.includes('security') && ruta) {
      const queryParamsString = localStorage.getItem('queryParams');
      const queryParams = queryParamsString
        ? JSON.parse(queryParamsString)
        : null;
      localStorage.removeItem('queryParams');
      // Si hay queryParams, agregarlos a la ruta
      let fullUrl = ruta;
      if (queryParams) {
        const queryParamsArray = [];
        for (const [key, value] of Object.entries(queryParams)) {
          let valueString = value;
          if (Array.isArray(value)) {
            valueString = value
              .map((val) => encodeURIComponent(val))
              .join('&' + key + '=');
          } else {
            valueString = encodeURIComponent(value as any);
          }
          queryParamsArray.push(`${key}=${valueString}`);
        }
        const queryParamsString = queryParamsArray.join('&');
        fullUrl += `?${queryParamsString}`;
      }
      // Navegar a la ruta con los queryParams
      setTimeout(() => {
        this.router.navigateByUrl(fullUrl);
      }, 100);
    } else {
      this.router.navigate(['/main']);
    }

    this.intercomService.bootIntercom({});
    this.socketService.reconnect();
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  getButtonWidth(): string {
    const loginContainerEl =
      this.elementRef.nativeElement.querySelector('.login-container');
    const loginContainerStyle = window.getComputedStyle(loginContainerEl);
    const loginContainerWidth =
      loginContainerEl.offsetWidth -
      parseFloat(loginContainerStyle.paddingLeft) -
      parseFloat(loginContainerStyle.paddingRight);
    const width =
      loginContainerWidth < 400
        ? `${loginContainerWidth.toString()}px`
        : '399px';
    return width;
  }

  async getPermissions() {
    const userData = localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData')!)
      : null;
    if (userData && userData.id_members_workspace) {
      try {
        const permissionsResponse = await lastValueFrom(
          this.apiService.get(
            `${this.resources.permissions}/${userData.id_members_workspace}`
          )
        );
        if (permissionsResponse && permissionsResponse.data) {
          localStorage.setItem(
            'permissions',
            JSON.stringify(permissionsResponse.data)
          );
          this.authService.permissions.next(permissionsResponse.data);
        }
      } catch (error) {
        return;
      }
    }
  }
}
