import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from './app/core/core.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(
    'ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0NiWn5XcXFXQmJY'
);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(CoreModule)
    .then(() => {
        if ('serviceWorker' in navigator) {
            if (environment.production) {
                navigator.serviceWorker.register('ngsw-worker.js');
            }
        }
    })
    .catch((err) => console.error(err));
