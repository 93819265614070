<!-- Si ya se ha seleccionado un tipo, muestra el componente correspondiente -->
<ng-container *ngIf="selectedFolderType; else typeSelection">
  <ng-container [ngSwitch]="selectedFolderType">
    <!-- Si es personal o compartido se muestra el componente de estudio -->
    <app-select-studio-file-tab *ngSwitchCase="'personal'" [extensions]="extensions" [sizeLimit]="sizeLimit"
      [baseType]="machotesPersonalType" [allowReturn]="true" (returnClicked)="onReturnClicked()"
      [overrideType]="'machoteStudio'">
    </app-select-studio-file-tab>
    <app-select-studio-file-tab *ngSwitchCase="'compartido'" [extensions]="extensions" [sizeLimit]="sizeLimit"
      [baseType]="machotesDespachoType" [allowReturn]="true" (returnClicked)="onReturnClicked()"
      [overrideType]="'machoteStudio'">
    </app-select-studio-file-tab>
    <!-- Si es mi-despacho se muestra el componente de templates -->
    <app-select-template-tab *ngSwitchCase="'mi-despacho'" [sizeLimit]="sizeLimit" [allowReturn]="true"
      (returnClicked)="onReturnClicked()">
    </app-select-template-tab>
  </ng-container>
</ng-container>

<!-- Si no se ha seleccionado ningún tipo, se muestran los botones -->
<ng-template #typeSelection>
  <div class="pl-5 pr-4 overflow-auto">
    <button *ngFor="let type of machotesTypes" (click)="selectType(type.value)"
      class="hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent">
      <i class="fa-regular text-[1rem] text-slate-600 fa-folder"></i>
      <span class="font-medium text-slate-600 truncate text-sm">{{ type.label | titlecase }}</span>
      <div class="px-3 ml-auto">
        <div class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible">
          <i class="fa-solid fa-chevron-right text-sm"></i>
        </div>
      </div>
    </button>
  </div>

</ng-template>