<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center"
  *ngIf="dialogRef">
  <span class="font-semibold text-primary-100">Agregar Contexto</span>
  <!-- Cerrar el modal -->
  <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
    class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
    (click)="dialogRef.close()" id="close-doc-picker">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid text-[1rem] fa-xmark"></i>
  </button>
</div>

<div class="pt-5 flex flex-col min-h-0 w-[90svw] max-w-2xl" id="doc-picker-container">
  <p class="px-5 text-slate-600 text-sm" *ngIf="prompt">{{prompt}}</p>

  <!-- Tabs -->
  <div class="flex pb-2 mt-4 border-b border-slate-200 min-w-0 overflow-x-auto">
    <ng-container *ngIf="!selectedItem">
      <button *ngFor="let tab of tabs; let i = index; trackBy:trackByFn" (click)="changeTab(tab.action)"
        class="flex items-center justify-center first:pl-5 pr-5 max-md:py-2 text-slate-400 active:text-indigo-500 font-medium active:font-semibold gap-1"
        [id]="'tab-'+tab.action" [ngClass]="{'active': selectedTab == tab.action}">
        <i class="fa-regular" [ngClass]="tab.icon" *ngIf="tab.icon"></i>
        {{tab.label}}
      </button>
    </ng-container>
    <div *ngIf="selectedItem" class="flex justify-between items-center gap-3 min-w-0 grow px-5 max-md:py-2">
      <span class="text-indigo-500 font-semibold truncate">{{selectedItem}}</span>
      <button *ngIf="selectedDossier" (click)="addDossierToSelection()"
        [ngxTippy]="docPickerService.isSelected(selectedDossier) ? 'Eliminar de la conversación' : 'Agregar Asunto'"
        [ngClass]="docPickerService.isSelected(selectedDossier) ? 'bg-indigo-100 text-indigo-600' : ''"
        class="size-7 rounded-full hover:bg-indigo-100 grid place-content-center">
        <i class="fa-regular fa-folder-plus"
          [ngClass]="docPickerService.isSelected(selectedDossier) ? 'fa-check' : 'fa-folder-plus'"></i>
      </button>
    </div>
  </div>

  <ng-container [ngSwitch]="selectedTab">
    <app-select-dossier-tab (onDossierChange)="onDossierItemChange($event)" [sizeLimit]="sizeLimit"
      [extensions]="extensions" *ngSwitchCase="'dossiers'" />
    <app-select-collection-tab (onCollectionChange)="onItemChange($event)" *ngSwitchCase="'collections'" />
    <app-select-studio-file-tab [extensions]="extensions" [sizeLimit]="sizeLimit" *ngSwitchCase="'studio'" />
    <app-select-template-tab-wrapper [extensions]="extensions" [sizeLimit]="sizeLimit" *ngSwitchCase="'templates'" />
    <app-upload-file-tab *ngSwitchCase="'upload'" />
  </ng-container>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2"
  *ngIf="dialogRef">
  <button [disabled]="loading | async" class="uisr-secondary-button" (click)="dialogRef.close()">
    <span translate="CANCEL_STRING"></span>
  </button>
  <button class="uisr-primary-button flex justify-center items-center gap-1" (click)="onSubmit()"
    [disabled]="!docPickerService.selection.length">
    <span translate="ADD_STRING"></span>
    <span>({{docPickerService.selection.length}})</span>
  </button>
</div>