import { inject, Injectable } from '@angular/core';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ClientFile, ClientFolder } from 'src/app/features/law-firm/interfaces/client.interfaces';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { UisrApiServiceV2 } from './uisr-api.service-v2';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { from, map, Observable, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UserData } from 'src/app/core/models/user-data';
import { ACTIVITY_TYPES } from 'src/app/core/constants/activity-types.constants';

/** Los posibles destinos hacia los cuales se pueden copiar archivos */
export enum FileCopyTargetType {
    Studio = 'studio',
    Dossiers = 'dossier',
    Clients = 'client',
    EmptyDossier = 'emptyDossier',
}

/** Los posibles orígenes desde los cuales se pueden copiar archivos */
export enum FileCopySourceType {
    Studio = 'studio',
    Dossiers = 'dossiers',
    Clients = 'client',
    Templates = 'templates',
}

@Injectable({
    providedIn: 'root',
})
export class StudioFileService {
    private readonly api = inject(UisrApiServiceV2);
    private readonly store = inject(Store)

    userData?: UserData = {} as UserData;

    constructor(){
        this.store
            .pipe(select(UserDataFull))
            .subscribe((data) => {
                this.userData = data;
    })
}


    /** Solicita copiar un documento desde un origen (dossiers. templates, clients) a otra area */
    copyToAnotherArea(
        source_id: number,
        target: ClientFolder | StudioFiles | StorageObject | DossierDTO,
        source_type: FileCopySourceType,
        target_type: FileCopyTargetType,
        move: boolean = false
    ) {
        let target_id;
        let url = '';

        // Extraer el id del destino al que se va a copiar el documento, depende de la entidad a la cual se copia pero siempre se manda como target_id
        if ('studio_file_id' in target) {
            target_id = target.studio_file_id;
        } else if ('idClientDossier' in target) {
            target_id = target.idClientDossier;
        } else if ('id' in target) {
            target_id = target.id;
        } else if ('idDossier' in target) {
            target_id = target.idDossier;
        }

        // Dependiendo del destino, se debe enviar la solicitud a endpoints específicos
        if (target_type === 'studio') {
            url = RESOURCES.copyStudioFiles;
        } else if (
            target_type === 'dossier' ||
            target_type === 'emptyDossier'
        ) {
            url = RESOURCES.copyDossierFiles;
        } else if (target_type === 'client') {
            url = RESOURCES.copyClientFiles;
        }

        return this.api.patch(url, {
            source_id,
            target_id,
            source_type,
            target_type,
            move,
        });
    }

    /** Crea una url de la ubicación a la que se copió un archivo */
    getDestinationUrl(
        target: ClientFolder | StudioFiles | StorageObject | DossierDTO,
        target_type: FileCopyTargetType
    ) {
        let target_id;
        let url = '/law-firm/';

        // Extraer el id del destino al que se va a copiar el documento, depende de la entidad a la cual se copia pero siempre se manda como target_id
        if ('studio_file_id' in target) {
            target_id = target.studio_file_id;
        } else if ('idClientDossier' in target) {
            target_id = target.idClientDossier;
        } else if ('id' in target) {
            target_id = target.id;
        } else if ('idDossier' in target) {
            target_id = target.idDossier;
        }

        // Dependiendo del destino, se debe armar la url correspondiente
        if (target_type === 'studio') {
            url += `library/studio?folderId=${target_id}`;
        } else if (target_type === 'dossier') {
            url += `files/file-overview/${(target as StorageObject).dossierId}?action=documents&folderId=${target_id}`;
        } else if (target_type === 'emptyDossier') {
            url += `files/file-overview/${target_id}?action=documents`;
        } else if (target_type === 'client') {
            url += `clients/${target_id}?action=documents`;
        }

        return url;
    }

    
    saveInArea(
      target: any,
      target_type: FileCopyTargetType,
      move: boolean = false,
      file: File
    ): Observable<any> {
      let url = '';

      if (target_type === 'studio') {
        url = RESOURCES.insertStudioFiles;
        const data = new FormData();
        data.append('color',  'bg-indigo-500');
        data.append('type_id', '1');
        data.append('parent_id', target.studio_file_id);
        data.append('fileUploads', new File([file as Blob], `${file.name}.docx`, {
            type: (file as Blob).type,
        }));
        return this.api.post(url, data);
        
      } else if (target_type === 'dossier' || target_type === 'emptyDossier') {
        const data = new FormData();
        url = RESOURCES.activity;

        data.append('nameActivity', 'Documentos agregados');
        data.append('description',  `${this.userData?.first_name} ${this.userData?.last_name} ha agregado documentos al asunto.`);

        data.append('fkIdCatDossierActivityType', ACTIVITY_TYPES.SYSTEM_UPDATE.toString());
        data.append(
            'fkIdMemberWorkspace',
            this.userData?.id_members_workspace?.toString() || ''
        );
        if(target.id){
            data.append('storageObjectParentId', target.id)
            data.append('fkIdDossier', target.dossierId);
        } else{
            data.append('fkIdDossier',target.idDossier.toString())
        }

        data.append('fileUploads', file);

        return this.api.post(url, data);
    
      } else if (target_type === 'client') {
        return from(UisrTools.filesToBase64([file])).pipe(
          map(([base64]) => {
            const fileToUpload: ClientFile = {
              base64,
              extension: file.name.split('.').pop()!.toLowerCase(),
              name: file.name.split('.')[0],
              size: file.size,
              fkIdCatFileType: 1,
              fkIdClient: target.idClientDossier,
            };
            return { files: [fileToUpload] };
          }),
          switchMap((data) => this.api.post(RESOURCES.clientFiles, data))
        );
      }
    
      // fallback
      return this.api.post(url, {});
    }    
}
