import { Component, inject, Input } from '@angular/core';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { CommonModule } from '@angular/common';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { BehaviorSubject } from 'rxjs';

interface Thread {
    threadId: string;
    name: string;
    typedId?: string;
    created_at: string;
}

@Component({
    selector: 'app-thread-item',
    templateUrl: './thread-item.component.html',
    standalone: true,
    imports: [CommonModule, NgxTippyModule],
    host: {
        class: 'contents',
    },
})
export class ThreadItemComponent {
    private readonly assistantChatService = inject(AssistantChatService);

    deleting = new BehaviorSubject(false);

    @Input({ required: true }) thread!: Thread;
    @Input() isActive = false;

    onActivateThread() {
        this.assistantChatService.activateThread(this.thread.threadId);
    }

    onDeleteThread() {
        this.assistantChatService
            .silentThreadDelete(this.thread.threadId)
            .pipe(loadingState(this.deleting))
            .subscribe();
    }
}
