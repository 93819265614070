import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import mime from 'mime';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { DialogService } from 'src/app/core/services/dialog.service';
import {
    FormatFile,
    FormatFileFilter,
} from 'src/app/features/library/interfaces/format-files.interfaces';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';
import { SimpleDocPickerService } from '../../../simple-doc-picker/simple-doc-picker.service';
import { QuickPromptTemplatePreviewComponent } from './quick-prompt-template-preview/quick-prompt-template-preview.component';
import { SelectStudioFileTabComponent } from '../../../simple-doc-picker/select-studio-file-tab/select-studio-file-tab.component';
import { STUDIO_FOLDER_TYPES } from 'src/app/features/law-firm/constants/studio-folder-types.constants';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';

export interface QuickPromptTemplate {
    id: number;
    category_l1: string;
    document_type_l2: string;
    prompt_title: string;
    prompt_template: string;
    display_order: string;
    created_at?: Date;
    is_active?: boolean;
    format_file_id?: number;
}

@UntilDestroy()
@Component({
    selector: 'app-quick-prompt-template-selection',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        NgxTippyModule,
        SelectStudioFileTabComponent,
    ],
    templateUrl: './quick-prompt-template-selection.component.html',
})
export class QuickPromptTemplateSelectionComponent implements OnInit {
    private readonly docPickerService = inject(SimpleDocPickerService);
    private readonly api = inject(UisrApiServiceV2);
    private readonly dialog = inject(DialogService);
    readonly dialogRef = inject(DialogRef);

    rootFolderName = 'useful_formatos';
    loadingCategories = new BehaviorSubject(false);
    selectedCategory: string | null = null;
    categories: { category: string; count: number }[] = [];

    // Propiedades para el selector de documentos L1 y L2
    loadingDocumentTypes = new BehaviorSubject(false);
    documentTypes: QuickPromptTemplate[] = [];
    selectedDocTypeL2: QuickPromptTemplate | null = null;

    // Propiedades para la selección y búsqueda de plantillas
    selectedPromptTemplate: QuickPromptTemplate | null = null;
    templateSearchQuery: string = '';
    selectedTemplate: FormatFile | StudioFiles | null = null;
    loadingTemplates = new BehaviorSubject(false);
    templates: FormatFile[] = [];

    // Control del paso actual en el flujo
    currentStep: number = 1;

    // Add tab selection properties
    selectedTab: 'mi-despacho' | 'despacho' | 'personal' = 'mi-despacho';
    readonly machotesTypes: Array<{
        label: string;
        value: 'mi-despacho' | 'despacho' | 'personal';
    }> = [
        {
            label: 'Predefinidos MiDespacho',
            value: 'mi-despacho',
        },
        {
            label: 'Despacho',
            value: 'despacho',
        },
        {
            label: 'Personales',
            value: 'personal',
        },
    ];

    readonly machotesDespachoType = STUDIO_FOLDER_TYPES.SHARED_MACHOTES;
    readonly machotesPersonalType = STUDIO_FOLDER_TYPES.PERSONAL_MACHOTES;

    ngOnInit(): void {
        this.getCategories();
    }

    getCategories() {
        this.api
            .get(RESOURCES.quickPromptsCategories)
            .pipe(loadingState(this.loadingCategories), untilDestroyed(this))
            .subscribe({
                next: (res) => {
                    this.categories = res.data;
                },
            });
    }

    /**
     * Verifica si el paso actual está activo
     * @param step Número del paso a verificar
     */
    isStepActive(step: number): boolean {
        return this.currentStep === step;
    }

    /**
     * Verifica si un paso ya se completó (es anterior al actual)
     * @param step Número del paso a verificar
     */
    isStepComplete(step: number): boolean {
        return this.currentStep > step;
    }

    /**
     * Avanza al siguiente paso del flujo
     */
    nextStep(): void {
        if (this.currentStep < 3) {
            this.currentStep++;
        }
    }

    /**
     * Maneja la selección de un tipo de documento L1
     * @param docType Tipo de documento seleccionado
     */
    selectDocumentCategory(category: string) {
        this.selectedCategory = category;
        this.selectedDocTypeL2 = null;
        this.loadDocumentTypes(category);
        this.nextStep();
    }

    /**
     * Permite navegar hacia atrás en el flujo de selección
     */
    goBack() {
        if (this.currentStep === 3) {
            this.currentStep = 2;
        } else if (this.currentStep === 2) {
            this.currentStep = 1;
            this.selectedCategory = null;
            this.documentTypes = [];
        }
    }

    /**
     * Carga los tipos de documento L2 basado en la selección de L1
     * @param l1Id ID del tipo de documento L1
     */
    loadDocumentTypes(category_l1: string) {
        this.api
            .get(RESOURCES.quickPromptsDocumentTypes, { category_l1 })
            .pipe(loadingState(this.loadingDocumentTypes), untilDestroyed(this))
            .subscribe({
                next: (types) => {
                    this.documentTypes = types.data;
                },
            });
    }

    /**
     * Maneja la selección de un tipo de documento L2
     * @param docType Subtipo de documento seleccionado
     */
    selectDocumentType(docType: QuickPromptTemplate) {
        this.selectedDocTypeL2 = docType;
        this.fetchQuickPromptTemplate();
        this.nextStep();
    }

    /**
     * Obtiene la plantilla de prompt rápido basada en la selección L1 y L2
     */
    fetchQuickPromptTemplate() {
        if (!this.selectedCategory || !this.selectedDocTypeL2) return;

        const filter: FormatFileFilter = {};

        if (this.selectedDocTypeL2.format_file_id) {
            filter.idFile = this.selectedDocTypeL2.format_file_id;
        } else if (this.selectedDocTypeL2.document_type_l2) {
            filter.searchValue = this.selectedDocTypeL2.document_type_l2;
            this.templateSearchQuery = this.selectedDocTypeL2.document_type_l2;
        }

        this.api
            .get(RESOURCES.formatFile, filter)
            .pipe(loadingState(this.loadingTemplates), untilDestroyed(this))
            .subscribe({
                next: (res) => {
                    this.templates = res.data;
                },
            });
    }

    /**
     * Realiza búsqueda de plantillas basado en la consulta actual
     */
    searchTemplates() {
        if (!this.templateSearchQuery?.trim()) {
            this.templates = [];
            return;
        }

        this.api
            .get(RESOURCES.formatFile, {
                searchValue: this.templateSearchQuery,
            })
            .pipe(loadingState(this.loadingTemplates), untilDestroyed(this))
            .subscribe({
                next: (res) => {
                    this.templates = res.data;
                },
            });
    }

    /**
     * Selecciona una plantilla para usar
     * @param template Plantilla seleccionada
     */
    toggleTemplateSelection(template: FormatFile | StudioFiles) {
        // Si no hay una plantilla seleccionada, selecciona la nueva
        if (!this.selectedTemplate) {
            this.selectedTemplate = template;
            return;
        }

        // Si ambos son machotes predeterminados (se guardan en la tabla de FormatFile)
        if (
            this.isFormatFile(this.selectedTemplate) &&
            this.isFormatFile(template)
        ) {
            this.selectedTemplate =
                this.selectedTemplate.idFile === template.idFile
                    ? null
                    : template;
            return;
        }

        // Si ambos son machotes de despacho o personales (se guardan en la tabla de StudioFiles)
        if (
            this.isStudioFile(this.selectedTemplate) &&
            this.isStudioFile(template)
        ) {
            this.selectedTemplate =
                this.selectedTemplate.studio_file_id === template.studio_file_id
                    ? null
                    : template;
            return;
        }

        // Si los tipos no coinciden, selecciona la nueva plantilla
        this.selectedTemplate = template;
    }

    isFormatFile(template: FormatFile | StudioFiles): template is FormatFile {
        return 'idFile' in template;
    }

    isStudioFile(template: FormatFile | StudioFiles): template is StudioFiles {
        return 'studio_file_id' in template;
    }

    /**
     * Verifica si una plantilla está seleccionada, se tiene que tener en cuenta que puede ser un archivo cargado al asunto o un machote
     * @param template Plantilla a verificar
     */
    isSelectedTemplate(template: FormatFile | StudioFiles): boolean {
        if (!this.selectedTemplate) return false;

        // Si ambos son machotes predeterminados (se guardan en la tabla de FormatFile)
        if (
            this.isFormatFile(this.selectedTemplate) &&
            this.isFormatFile(template)
        ) {
            return this.selectedTemplate.idFile === template.idFile;
        }

        // Si ambos son machotes de despacho o personales (se guardan en la tabla de StudioFiles)
        if (
            this.isStudioFile(this.selectedTemplate) &&
            this.isStudioFile(template)
        ) {
            return (
                this.selectedTemplate.studio_file_id === template.studio_file_id
            );
        }

        // Si los tipos no coinciden, no está seleccionada
        return false;
    }

    /**
     * Cierra el modal y envía la selección realizada
     */
    continue() {
        const selection: any = {
            prompt: this.selectedDocTypeL2?.prompt_template,
        };

        if (this.selectedTemplate) {
            selection.template = this.docPickerService.buildSelectableItems(
                [this.selectedTemplate as any],
                this.isStudioFile(this.selectedTemplate)
                    ? 'machoteStudio'
                    : undefined
            )[0];

            console.log(selection);
        }
        this.dialogRef.close(selection);
    }

    /**
     * Abre el modal de vista previa de un archivo, los machotes se guardan en su versión PDF y DOCX, por lo que la visualización se realiza en el modal de PDF
     * @param file Archivo a visualizar
     */
    openPreview(file: FormatFile) {
        let pathAux = file.path;
        pathAux = pathAux.replace('.docx', '.pdf');
        pathAux = pathAux.replace('.doc', '.pdf');
        pathAux = pathAux.replace(this.rootFolderName, 'useful_formatos_pdf');

        if (file.pdfAvailable) {
            this.dialog
                .openSidePanel(PdfViewerModalComponent, {
                    data: { ...file, path: pathAux },
                })
                .subscribe((res: unknown) => {
                    if (res && typeof res === 'object' && 'download' in res) {
                        this.downloadFile(file);
                    }
                });
        }
    }

    /**
     * Descarga un archivo
     * @param file Archivo a descargar
     */
    downloadFile(file: FormatFile): void {
        this.api.get(`${RESOURCES.formatFileContent}${file.idFile}`).subscribe({
            next: (res: APIResponse<string>) => {
                const type = mime.getType(file.extension);
                const src = `data:${type};base64,${res.data}`;
                const link = document.createElement('a');
                link.href = src;
                link.download = `${file.name}${file.extension}`;
                link.click();
                link.remove();
            },
        });
    }

    openPromptInfo(docType: QuickPromptTemplate) {
        this.dialog.openDialog(QuickPromptTemplatePreviewComponent, {
            data: { promptInfo: docType },
        });
    }

    /**
     * Handles tab selection
     * @param tab The selected tab value
     */
    selectTab(tab: 'mi-despacho' | 'despacho' | 'personal') {
        this.selectedTab = tab;
        // Just reset the selected templates and the templates
        this.selectedTemplate = null;
        this.templates = [];

        if (tab === 'mi-despacho') {
            this.searchTemplates();
        }
    }
}
