<div class="flex justify-between p-5 pb-0" *ngIf="!contentOnly">
    <div class="w-full">
        <span class="text-2xl md:text-3xl text-slate-800 font-bold">⛰️Tour MiDespacho</span>
        <div class="flex flex-col gap-2">
            <div class="flex flex-wrap gap-2 mt-2 justify-between">
                <span>¡Conviértete en un 🚀 Jurista Digital y obtén tu Certificado de Digitalización! </span>
                <div
                    [ngxTippy]="!this.userJourneyService.completed ? 'Completa el Tour de MiDespacho para obtener tu Certificado de Digitalización' : '¡Felicidades has completado el Tour! Descarga tu Certificado'">
                    <button
                        [disabled]="this.userJourneyService.completed == null || this.userJourneyService.completed == false"
                        class="uisr-secondary-button flex gap-1">
                        <i class="fa-regular fa-download" style="font-size: 1rem;"></i>
                        <span>Descarga tu Certificado</span>
                    </button>
                </div>
            </div>
            <span class="text-sm text-slate-500">Haz click sobre el icono si desear omitir los pasos
                opcionales.</span>
        </div>
    </div>
    <div class="flex items-start left-0" *ngIf="dialogRef?.id">
        <button class="text-slate-400 hover:text-rose-500" (click)="dialogRef?.close()">
            <span class="sr-only">{{ 'CLOSE_STRING' | translate }}</span>
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
</div>


<div [ngClass]="{
    'md:grid-cols-2 w-[90svw] py-5 gap-6 md:grid': showImages,
    'px-3': home,
    'md:px-5 px-8 max-w-4xl': !home
  }">
        <div class="flex flex-col grow gap-4">
            
            <div class="flex flex-col" *ngIf="!contentOnly">
                <div class="flex justify-end">
                    <span class="text-sm text-slate-500 font-medium">
                        {{ userJourneyService.getCompletedPercentage() | number:'1.0-0' }}%
                    </span>
                </div>
                <div class="w-full bg-gray-200 rounded-full h-2.5"
                    [ngxTippy]="!this.userJourneyService.completed ? 'Completa el Tour de MiDespacho para obtener tu Certificado de Digitalización' : '¡Felicidades has completado el Tour! Descarga tu Certificado'">
                    <div class="bg-indigo-600 h-2.5 rounded-full"
                        [style.width.%]="userJourneyService.getCompletedPercentage()"></div>
                </div>
            </div>
            <div class="relative w-full bg-white shadow-md rounded-md border border-slate-200 min-h-[30rem]">
                <owl-carousel-o [classList]="'journey-steps'" [options]="tourCarouselOptions" (translated)="setActiveSlide($event)" #stepsCarousel>
                    <ng-container *ngFor="let slide of slides; let i = index">
                        <ng-template carouselSlide [id]="'slide-' + i">
                            <div class="bg-white rounded-md flex flex-col h-full">
                                <div class="flex items-center gap-2 pl-6 border-b py-4 bg-slate-50 rounded-t-md"
                                    style="font-size:20px">
                                    <span><i class="fa-solid mr-1" [ngClass]="slide.icon"></i>{{slide.title}}</span>
                                </div>
                                <div class="flex w-full flex-col py-3 px-6">
                                    <span class="font-medium text-slate-600 text-sm">
                                        {{getCompletedSteps()}} / {{getLevelLength()}} completado
                                    </span>
                                    <div class="w-full bg-gray-200 rounded-full h-2.5">
                                        <div class="bg-indigo-600 h-2.5 rounded-full"
                                            [style.width.%]="userJourneyService.getCompletedPercentageByStep(activeIndex)">
                                        </div>
                                    </div>
                                </div>
                                <div class="grow min-h-72">
                                    <ng-container *ngFor="let item of slide.steps">
                                        <div
                                            class="flex py-3 border-b first:border-t last:border-b-0 border-slate-200 items-center cursor-pointer hover:bg-slate-50">
                                            <button class="flex gap-2 items-center">
                                                <div *ngIf="!loadingIds.has(item.step) || !(updating | async)"
                                                    class="text-indigo-600 grid place-content-center "
                                                    [ngClass]="home ? 'pl-5' : 'pl-6'">
                                                    <i [ngClass]="getStyle(item.status)" [ngStyle]="{
                               'font-size': item.priority == '1' ? '1.25rem' : '0.9rem',
                               'padding-left': item.priority == '1' ? '0px' : '1.25rem'
                             }" [ngxTippy]="item.optional && item.status == 'INCOMPLETE' ? 'Omitir' : item.optional && item.status == 'OMITTED' ? 'Marcar incompleto': null "
                                                        (click)="item.optional ? markAsComplete(item) : null"></i>
                                                </div>
                                                <div *ngIf="loadingIds.has(item.step) && (updating | async)"
                                                    [ngClass]="item.priority == '1' ? 'pl-6' : 'pl-11'"
                                                    class="pointer-events-none ">
                                                    <i class="fa-spinner-third fa-solid animate-spin" [ngStyle]="{
                            'font-size': item.priority == '1' ? '1.25rem' : '0.9rem',
                          }"></i>
                                                </div>
                                                <div (click)="onStepClick(item)" class="flex flex-col items-start ">

                                                <div class="text-left w-full">
                                                    <span class="sm:text-sm text-xs"
                                                        [ngClass]="{'underline custom-lt decoration-indigo-500' : item.status == 'OMITTED' || item.status == 'COMPLETED'}">{{item.label}}</span>
                                                    <span *ngIf="item.optional" class="text-slate-400 text-xs">
                                                        (opcional)</span>
                                                </div>
                                                <span *ngIf="item.subtitle"
                                                    class="text-xs text-indigo-600">{{item.subtitle}}</span>
                                            </div>
                                        </button>
                                        <div class="flex gap-1 ml-auto pr-6">
                                            <a *ngIf="item.helpLink" [href]="item.helpLink" target="_blank"
                                                ngxTippy="Centro de Ayuda"
                                                class="size-7 rounded-md">
                                                <i class="fa-regular fa-question-circle"></i>
                                            </a>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>

                <button *ngIf="activeIndex > 0" type="button" (click)="carouselsPrev()"
                    [ngClass]="home ? '-left-3' : '-left-5'"
                    class="absolute top-0  z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none">
                    <span [ngClass]="home ? 'size-8' : 'size-10'"
                        class="inline-flex items-center justify-center rounded-full bg-indigo-500/30 group-hover:bg-indigo-500/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none text-white">
                        <i class="fa-solid fa-chevron-left stroke-2"></i>
                        <span class="sr-only">Anterior</span>
                    </span>
                </button>
                <button *ngIf="activeIndex < slides.length - 1" type="button" (click)="carouselsNext()"
                    [ngClass]="home ? '-right-3' : '-right-5'"
                    class="absolute top-0  z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none">
                    <span [ngClass]="home ? 'size-8' : 'size-10'"
                        class="inline-flex items-center justify-center rounded-full bg-indigo-500/30 group-hover:bg-indigo-500/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none text-white">
                        <i class="fa-solid fa-chevron-right stroke-2"></i>
                        <span class="sr-only">Siguiente</span>
                    </span>
                </button>
            </div>
        </div>
        <div class="max-md:hidden rounded-md overflow-hidden" *ngIf="showImages">
            <owl-carousel-o [options]="photoCarouselOptions" (translated)="setActiveSlide($event)" #photoCarousel
                [classList]="'journey-override h-full'">
                <ng-container *ngFor="let slide of slides; let i = index">
                    <ng-template carouselSlide [id]="'slide-' + i">
                        <div>
                            <img class="rounded-lg shadow-md w-full h-full object-cover transition-transform duration-500 ease-in-out"
                                [src]="slide.src" [src]="slide.src" alt="Imagen del slide">
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>

