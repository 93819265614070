import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { UisrGenericFormControlsService } from 'src/app/shared/services/uisr-generic-form-controls.service';
import Swal from 'sweetalert2';
import { ADD_COLLECTION_CONTROLS } from '../../constants/search-controls.constants';

@UntilDestroy()
@Component({
    selector: 'app-add-collection',
    templateUrl: './add-collection.component.html',
})
export class AddCollectionComponent implements OnInit {
    addCollectionForm: FormGroup;
    addCollectionControls: FormControlConfig[] = ADD_COLLECTION_CONTROLS;
    messages: any;
    resources = RESOURCES;

    constructor(
        private apiService: UisrApiServiceV2,
        private toastr: ToastrService,
        private controlsService: UisrGenericFormControlsService,
        private translateService: TranslateService,
        public dialogRef: DialogRef,
        public collectionsService: CollectionsService,
        @Inject(DIALOG_DATA)
        public data: any
    ) {
        this.addCollectionForm = this.controlsService.createFormGroup(
            this.addCollectionControls
        );
    }

    ngOnInit() {
        this.getMessages();
    }

    async getMessages() {
        this.messages = this.translateService.instant([
            'SUCCESSFUL_COLLECTION_CREATION',
            'GENERIC_ERROR_MESSAGE',
            'GENERIC_FORM_ERROR',
        ]);
    }

    async createCollection() {
        if (this.addCollectionForm.invalid) {
            this.addCollectionForm.markAllAsTouched();
            this.toastr.error(this.messages.GENERIC_FORM_ERROR);
            return;
        }

        let data = this.addCollectionForm.value;
        this.sendRequest(data);
    }

    sendRequest(data: any) {
        this.apiService
            .put(this.resources.createCollection, data, null, [
                'form',
                'button',
            ])
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.toastr.success(
                            this.messages.SUCCESSFUL_COLLECTION_CREATION
                        );
                        this.dialogRef.close(true);
                        this.collectionsService.loadUserCollections();
                    } else {
                        let message = this.messages.GENERIC_ERROR_MESSAGE;
                        if (res.code) {
                            let messageAux = this.translateService.instant(
                                res.code
                            );
                            message =
                                messageAux != res.code ? messageAux : message;
                        }
                        Swal.fire({
                            ...ALERT_DEFAULTS,
                            text: message,
                        });
                    }
                },
            });
    }
}
