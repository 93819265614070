import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appAlphanumericValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AlphanumericValidatorDirective),
      multi: true
    }
  ]
})
export class AlphanumericValidatorDirective implements Validator {
  validate(control: AbstractControl): null {
      if (control.value) {
        // Elimina todo lo que no sea alfanumérico o acentos
        const cleanedValue = control.value.replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ ]/g, '');
        // Verifica si el valor ha cambiado después de la limpieza
        if (control.value !== cleanedValue) {
          // Establece el valor limpiado
          control.setValue(cleanedValue);
        }
      }
      // No retorna errores de validación porque el objetivo es limpiar el valor
      return null;
    }
}
