import { AfterViewInit, Component, HostListener, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { ASSISTANT_STEPS } from 'src/app/core/constants/assistant-steps.steps';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { environment } from 'src/environments/environment';
import { AssistantChatService } from './services/assistant-chat.service';

@UntilDestroy()
@Component({
    selector: 'app-assistant-chat',
    templateUrl: './assistant-chat.component.html',
    host: {
        class: 'contents',
    },
})
export class AssistantChatComponent implements AfterViewInit {
    readonly tutorialService = inject(AppTutorialService);
    readonly assistantChatService = inject(AssistantChatService);

    steps = ASSISTANT_STEPS;
    assistantTutorialFinalDate = environment.assistantTutorialFinalDate;

    @HostListener('window:resize', []) updateMenuStatus() {
        if (window.innerWidth >= 1090) {
            this.assistantChatService.componentStates.threadsMenu = true;
        }
    }

    //////// AUN FALTA POR CULMINAR:
    //////// . https://www.notion.so/uisr/c75facf6cedc40c899892fc58e0a0b41?v=8d1ba0543eac4348abc96674cf57e23d&p=1ca8878c655842118bbd0201e75f38ab&pm=s

    ngAfterViewInit(): void {
        if (this.assistantChatService.assistantAvailable()) {
            this.assistantChatService.fetchModels();

            setTimeout(() => {
                if (
                    this.tutorialService.shouldStartTourFromDate(
                        'keepFirstTimeAssistant',
                        DateTime.fromJSDate(this.assistantTutorialFinalDate)
                    )
                ) {
                    this.tutorialService.startTour(
                        this.steps,
                        undefined,
                        'assistant-tour'
                    );
                }
            }, 0);
        }
    }

    /** Obtener el nombre de la conversación */
    public get threadName() {
        return this.assistantChatService.thread?.name || '';
    }
}
