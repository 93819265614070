import { Component, ContentChild, ElementRef, EventEmitter, Input, NgZone, Optional, Output, Self, TemplateRef, afterNextRender } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { NgLabelTemplateDirective, NgNotFoundTemplateDirective, NgOptionTemplateDirective } from '@ng-select/ng-select';
import AirDatepicker from 'air-datepicker';
import localeEs from 'air-datepicker/locale/es';
import { createPopper } from '@popperjs/core';
import { v4 as uuidv4 } from 'uuid';
import { InputFieldcheckOptionTemplateDirective } from '../../directives/input-field-check-option-template.directive';
import device from 'current-device';

@Component({
	selector: 'app-input-field',
	templateUrl: './input-field.component.html',
	styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements ControlValueAccessor {
	// Events
	@Output() onTouchedEvent = new EventEmitter<any>();
	@Output() onFocusEvent = new EventEmitter<any>();
	@Output() onBlurEvent = new EventEmitter<any>();
	@Output() onChangeEvent = new EventEmitter<any>();
	@Output() onInputEvent = new EventEmitter<any>();
	@Output() onToggleCheckEvent = new EventEmitter<any>();
	@Output() onCheckedEvent = new EventEmitter<any>();
	@Output() onUnCheckedEvent = new EventEmitter<any>();
	@Output() onChatSend = new EventEmitter<any>();
	@Output() onIconClick = new EventEmitter<any>();
	@Output() scrollToEnd = new EventEmitter<any>();

	// Events NgSelect
	@Output() onOpenEvent = new EventEmitter<any>();
	@Output() onSearchEvent = new EventEmitter<any>();

	// Objects
	@Output() onDatePickerInstanceReady = new EventEmitter<AirDatepicker<HTMLElement>>();
	@Output() onDatePickerInstanceShow = new EventEmitter<AirDatepicker<HTMLElement>>();
	@Output() onDatePickerInstanceHide = new EventEmitter<AirDatepicker<HTMLElement>>();

	// General
	@Input() inputType: 'text' | 'number' | 'email' | 'password' | 'ngselect' | 'date' | 'textarea' | 'radio' | 'checkbox' | 'switch' = 'text';
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() disabled: boolean = false;
	@Input() readOnly: boolean = false;
	@Input() required: boolean = false;
	@Input() autoComplete: boolean = false;
	@Input() spellCheck: boolean = true;
	@Input() class: string = '';
    @Input() showInvalidState: boolean = true;
	@Input() generalError: string = '';
	@Input() infoTooltip: string = '';
	@Input() loadingState: boolean = false;
	@Input() iconTooltip: string = '';
	@Input() iconPos: 'left' | 'right' = 'right';
	@Input() icon: string = '';
	@Input() enableIconClick: boolean = false;
    @Input() iconMode: boolean = false;

	// NgSelect
	@Input() items: any[] | null = [];
	@Input() multiple: boolean = false;
	@Input() bindLabel: string = '';
	@Input() bindValue: string = '';
	@Input() searchable: boolean = true;
	@Input() clearable: boolean = true;
	@Input() searchFn: any;
	@ContentChild(NgLabelTemplateDirective, { read: TemplateRef }) selectLabelTemplate!: TemplateRef<any>;
	@ContentChild(NgOptionTemplateDirective, { read: TemplateRef }) selectOptionTemplate!: TemplateRef<any>;
	@ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef }) selectNotFoundTemplate!: TemplateRef<any>;

	// Radio
	@Input() inputChecked: boolean | null = null;
	@Input() radioValue: string | number = '';
	@ContentChild(InputFieldcheckOptionTemplateDirective, { read: TemplateRef }) checkOptionTemplate!: TemplateRef<any>;

	// Switch - Checkbox - Radio
	@Input() leftLabel: string = '';
	@Input() rightLabel: string = '';

	// Switch - checkbox
	@Input() checkboxValue: string | number | boolean | null = null;
	@Input() checkArrayMode: boolean = false;

	// TextArea
	@Input() taAutoResize: boolean = false;
	@Input() chatMode: boolean = false;
	@Input() scrollBottom: boolean = true;

	// Number
	@Input() min: number | string = '';
	@Input() max: number | string = '';

	// Date
	@Input() timepicker: boolean = false;
    @Input() todayButton: boolean = true;
    @Input() clearButton: boolean = true;
	@Input() startDate!: Date;
	@Input() positionContainer: string = '';
	DatepickerInstance!: AirDatepicker<HTMLElement>;

	// Estados
	inputID: string = `input-${uuidv4()}`;
	value: any = null;

	_onTouched = () => {};
	_onChange = (value: any) => {};
    
	constructor(
		@Optional() @Self() private ngControl: NgControl,
		private elementRef: ElementRef
	) {
		if (this.ngControl) this.ngControl.valueAccessor = this;

		afterNextRender(() => {
			this.inputRadio();
			this.inputCheck();
			this.inputDate();
			this.resizeTextarea();
			//this.cdr.detectChanges();
		});
	}

	/**
	 * Registrar funcion de cambio del valor para el kernel de angular
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	/**
	 * Registrar funcion de cambio del estado para el kernel de angular
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	/**
	 * Esta funcion es llamada por el kernel de angular
	 * para actualizar el valor del modelo del componente
	 * @param value
	 */
	writeValue(value: any): void {
		this.value = value;
        setTimeout(() => {
			this.resizeTextarea();
		}, 0);
	}

	/**
	 * Esta funcion es llamada por el kernel de angular
	 * para actualizar el estado Disabled del componente
	 * @param isDisabled
	 */
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	///////////////////////////////////////////////////

	inputCheck() {
		if (['switch', 'checkbox'].includes(this.inputType)) {
			if (this.inputChecked !== null) {
				if (this.checkArrayMode) {
					let arr = [];

					if (Array.isArray(this.ngControl.control?.value)) {
						arr = this.ngControl.control?.value as Array<any>;
					}

					if (this.checkboxValue === null) {
						arr.push(this.inputChecked);
					} else {
						arr.push(this.checkboxValue);
					}

					this.ngControl.control?.setValue(arr);
				} else {
					if (this.checkboxValue === null) {
						this.ngControl.control?.setValue(this.inputChecked);
					}
					if (this.checkboxValue !== null) {
						this.ngControl.control?.setValue(this.inputChecked ? this.checkboxValue : '');
					}
				}
			}
		}
	}

	inputRadio() {
		if (this.inputType == 'radio' && this.inputChecked) {
			this.ngControl.control?.setValue(this.radioValue);
		}
	}

	inputDate() {
		if (this.inputType == 'date') {

            const buttons: any[] = [];
            
			// Boton para establecer la fecha actual
			const todayButton = {
				content: 'Hoy',
				onClick: (dp: AirDatepicker) => {
					let date = new Date();
					dp.setViewDate(date);
					dp.selectDate(date);
				}
			};

            // Si se habilita el boton de hoy
            if(this.todayButton){
                buttons.push(todayButton);
            }

            // Si se habilita el boton de limpiar
            if(this.clearButton){
                buttons.push('clear');
            }

			// Inicializar el datepicker
			this.DatepickerInstance = new AirDatepicker(`#${this.inputID}`, {
				locale: localeEs,
				timepicker: this.timepicker,
                dateFormat: 'dd/MM/yyyy',
				timeFormat: 'hh:mm AA',
				container: this.positionContainer ? this.positionContainer : '',
				buttons: buttons,
				startDate: this.startDate ? this.startDate : new Date(),
				range: this.multiple,
				position({ $datepicker, $target, $pointer, done }) {

					const remToPx = (rem: number): number => {
						let fontSizeInPixels = parseFloat(window.getComputedStyle(document.body).fontSize);
						return rem * fontSizeInPixels;
					};

					const popper = createPopper($target, $datepicker, {
						placement: 'top-start',
						modifiers: [
							{
								name: 'flip',
								options: {
									padding: {
										top: remToPx(4)
									}
								}
							},
							{
								name: 'offset',
								options: {
									offset: [0, remToPx(1.25)]
								}
							},
							{
								name: 'arrow',
								options: {
                                    centerOffset: 0,
									element: $pointer,
								},
                                fn({ state }) {
                                    const arrowData = state.modifiersData.arrow;
                                    if (arrowData) {
                                        arrowData.x = remToPx(0.9);
                                    }
                                }
							},
						],
					});

					/*
                    Return function which will be called when `hide()` method is triggered,
                    it must necessarily call the `done()` function
                    to complete hiding process 
                    */

					return function completeHide() {
						popper.destroy();
						done();
					};
				},
				onSelect: (e: any) => {
					this.ngControl.control?.setValue(e.formattedDate || '');
					this.onChange(e);
				},
				onShow: (e: any) => {
					this.onDatePickerInstanceShow.emit(this.DatepickerInstance);
				},
				onHide: (e: any) => {
					this.onDatePickerInstanceHide.emit(this.DatepickerInstance);
				}
			});

			// Si la fecha inicial se envia por parametro se establece como fecha inicial
			if (this.startDate) {
				this.DatepickerInstance.selectDate(this.startDate ? this.startDate : new Date());
			}

			// Emitir la instancia del datepicker
			this.onDatePickerInstanceReady.emit(this.DatepickerInstance);
		}
	}

	resizeTextarea() {
		if (this.taAutoResize) {
			// Convertir Rem a Px
			const textarea = this.elementRef.nativeElement.querySelector(`#${this.inputID}`);

			const remToPx = (rem: number): number => {
				let fontSizeInPixels = parseFloat(taStyle.fontSize);
				return rem * fontSizeInPixels;
			};

			const taStyle = window.getComputedStyle ? window.getComputedStyle(textarea) : textarea.currentStyle;
			
			textarea.style.height = '1px';

			if(this.value){
				textarea.style.height = textarea.scrollHeight + remToPx(0.125) + 'px';

				if (this.scrollBottom) {
					textarea.scrollTop = 9e9;
					
					// Fallback
					setTimeout(() => {
						textarea.scrollTop = 9e9;
					}, 0);
				}
	
			}
		}
	}

	chatTextArea(event: KeyboardEvent) {
		if(this.chatMode && device.desktop()) {
			if (event.key === 'Enter') {
				if (event.shiftKey) {
					// Permitir el comportamiento predeterminado (nueva línea)
					return;
				} else {
					// Prevenir el comportamiento predeterminado (nueva línea) y emitir un evento
					event.preventDefault();
					this.onChatSend.emit(true);
				}
			}
		}
	}

	///////////////////////////////////////////////////

	/**
	 * Registrar interaccion con el ui del componente
	 */
	onTouched() {
        this.resizeTextarea();
		if (!this.touched) {
			this._onTouched();
			this.onTouchedEvent.emit();
		}
	}

	/**
	 * Cuando el componente obtiene el foco
	 * Registrar interaccion
	 * @param $event
	 */
	onFocus($event: any) {
		//this.onTouched();
		this.onFocusEvent.emit($event);
	}

	/**
	 * Cuando el componente pierde el foco
	 * Registrar interaccion
	 * @param $event
	 */
	onBlur($event: any) {
		this.onTouched();
		this.onBlurEvent.emit($event);
	}

	/**
	 * Al cambiar el valor del componente
	 * @param $event
	 */
	onChange($event: any) {
		if (['radio'].includes(this.inputType)) {
			if (this.radioValue != $event.target.value) {
				this.ngControl.control?.setValue(this.radioValue);
				this.onCheckedEvent.emit($event);
			}
		}
		if (['checkbox', 'switch'].includes(this.inputType)) {
			const checked: boolean = $event.target.checked;

			if (this.checkArrayMode) {
				let arr = [];

				if (Array.isArray(this.ngControl.control?.value)) {
					arr = this.ngControl.control?.value as Array<any>;
					arr = [...new Set(arr)] as Array<any>;
				}

				if (checked) {
					if (this.checkboxValue !== null) {
						arr.push(this.checkboxValue);
					} else {
						arr.push(checked);
					}

					$event.target.checked = false;
				} else {
					if (this.checkboxValue !== null) {
						arr.indexOf(this.checkboxValue) !== -1 && arr.splice(arr.indexOf(this.checkboxValue), 1);
					} else {
						arr.indexOf(checked) !== -1 && arr.splice(arr.indexOf(checked), 1);
					}

					$event.target.checked = true;
				}

				this.ngControl.control?.setValue([...new Set(arr)]);
			} else {
				if (this.checkboxValue !== null) {
					this.ngControl?.control?.setValue(checked ? this.checkboxValue : '');
				} else {
					this.ngControl?.control?.setValue(checked);
				}
			}

			if (checked) {
				this.onCheckedEvent.emit($event);
			} else {
				this.onUnCheckedEvent.emit($event);
			}

			this.onToggleCheckEvent.emit(checked);
		}

		this.resizeTextarea();
		this._onChange(this.value);
		this.onChangeEvent.emit($event);
	}


	onScrollToEnd() {
		this.scrollToEnd.emit(true);
		}

	/**
	 * Al escribir en el componente
	 * @param $event
	 */
	onInput($event: any) {
		if (!['ngselect'].includes(this.inputType)) {
			this.onChange($event);
		}

		this.onInputEvent.emit($event);
		this.resizeTextarea();
	}

	/**
	 * Al abrir el ngselect
	 * @param $event
	 */
	onOpen($event: any) {
		this.onTouched();
		this.onOpenEvent.emit($event);
	}

	/**
	 * Al buscar en ngselect
	 * @param $event
	 */
	onSearch($event: any) {
		this.onTouched();
		this.onSearchEvent.emit($event);
	}

	public get checked() {
		if (['radio'].includes(this.inputType)) {
			return this.value == this.radioValue;
		}
		if (['checkbox', 'switch'].includes(this.inputType)) {
			if (this.checkArrayMode) {
				let arr = [];

				if (Array.isArray(this.ngControl.control?.value)) {
					arr = this.ngControl.control?.value as Array<any>;
				}

				if (this.checkboxValue !== null) {
					return arr.includes(this.checkboxValue);
				} else {
					return arr.includes(this.value);
				}
			} else {
				if (this.checkboxValue !== null) {
					return this.value == this.checkboxValue;
				} else {
					return this.value == true;
				}
			}
		}
		return false;
	}

	public get status() {
		return this.ngControl.status;
	}

	public get valid() {
		return this.ngControl.valid;
	}

	public get invalid() {
		return this.ngControl.invalid;
	}

	public get errors() {
		return this.ngControl.errors;
	}

	public get pending() {
		return this.ngControl.pending;
	}

	public get dirty() {
		return this.ngControl.dirty;
	}

	public get enabled() {
		return this.ngControl.enabled;
	}

	public get untouched() {
		return this.ngControl.untouched;
	}

	public get touched() {
		return this.ngControl.touched;
	}
}
