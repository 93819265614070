import { environment } from 'src/environments/environment';

const phpApiUrl = environment.apiUrl;
const nestApiUrl = environment.nestApiUrl;

export const RESOURCES = {
    rawPhp: `${phpApiUrl}`,
    rawNest: `${nestApiUrl}`,
    login: `${nestApiUrl}auth/login`,
    register: `${phpApiUrl}register`,
    disconnectGoogle: `${phpApiUrl}google-logout`,
    googlePasswordChange: `${phpApiUrl}google-logout-validate`,
    updateEmail: `${nestApiUrl}auth/request-user-email-update`,
    updateEmailValidation: `${nestApiUrl}auth/validate-email-update`,
    updatePassword: `${phpApiUrl}update-user-password`,
    updatePasswordValidation: `${phpApiUrl}password-change-validate`,
    passwordRecovery: `${nestApiUrl}auth/recover-password`,
    passwordRecoveryValidation: `${nestApiUrl}auth/complete-password-recovery`,
    preSignup: `${phpApiUrl}onboarding-save`,
    basicSearch: `${nestApiUrl}search/basic-search`,
    listCity: `${phpApiUrl}list-city`,
    listCityByCountry: `${phpApiUrl}list-city-coutry`,
    getStates: `${phpApiUrl}list-state`,
    searchDetail: `${nestApiUrl}search/search-detail`,
    searchNotes: `${phpApiUrl}search-notes`,
    createCollection: `${phpApiUrl}create-collection`,
    getCollections: `${phpApiUrl}show-collections`,
    getCollectionsLite: `${phpApiUrl}show-lite-collections`,
    getCollectionDetails: `${nestApiUrl}search-object/by-collection`,
    requestSummary: `${phpApiUrl}search-detail-summary`,
    submitReport: `${phpApiUrl}save-reports`,
    addDocumentToCollection: `${nestApiUrl}search-object/create-so-collection`,
    deleteDocumentFromCollection: `${phpApiUrl}delete-so-collection`,
    sendFeedback: `${phpApiUrl}save-calification`,
    getLastSurveyResponse: `${phpApiUrl}get-calification`,
    getFilterOptions: `${nestApiUrl}search/list-filters`,
    editCollection: `${phpApiUrl}edit-collection`,
    cloneCollection: `${phpApiUrl}clone-collection`,
    editSearchObject: `${phpApiUrl}edit-search-o`,
    deleteCollection: `${phpApiUrl}delete-collection`,
    addSoNote: `${phpApiUrl}save-notes-so`,
    deleteSoNote: `${phpApiUrl}delete-notes-so`,
    updatedSoNote: `${phpApiUrl}save-notes-position`,
    deleteNotesSo: `${phpApiUrl}delete-notes-so`,
    requestToken: `${nestApiUrl}auth/refresh-token`,
    validate2FACode: `${nestApiUrl}auth/validate-two-factor`,
    manage2FA: `${nestApiUrl}auth/toggle-2fa`,
    connectWithGoogle: `${phpApiUrl}sso-reconection`,
    subscriptionCreate: `${phpApiUrl}subscription-create`,
    listPlans: `${phpApiUrl}list-plans`,
    updateSubscription: `${phpApiUrl}update-suscription`,
    updateAutoRenewal: `${nestApiUrl}stripe/update-auto-renewal/`,
    cancellationReasons: `${nestApiUrl}cat/cancellation-reasons`,
    listCards: `${phpApiUrl}list-cards`,
    updatePreferences: `${phpApiUrl}update-user-preferences`,
    updateUserData: `${phpApiUrl}update-user-data`,
    updateUser: `${nestApiUrl}user/update-user`,
    inviteToWorkspace: `${nestApiUrl}members-workspace/invite`,
    getExtendedActivities: `${nestApiUrl}dossier-activity/extended`,
    getDossiers: `${nestApiUrl}dossier`,
    getCases: `${nestApiUrl}dossier/cases`,
    getDossiersDetail: `${nestApiUrl}dossier/detail`,
    getUserDossier: `${nestApiUrl}activity-comment/get-users`,
    activity: `${nestApiUrl}dossier-activity`,
    getDossierMembers: `${nestApiUrl}dossier-members`,
    activityFiles: `${nestApiUrl}activity-files`,
    postActivityComment: `${nestApiUrl}activity-comment`,
    workspace: `${nestApiUrl}workspace`,
    getFileContent: `${nestApiUrl}activity-files/content`,
    template: `${nestApiUrl}template`,
    extendedTemplate: `${nestApiUrl}template/extended`,
    rol: `${nestApiUrl}rol`,
    extendedRol: `${nestApiUrl}rol/extended`,
    templateFields: `${nestApiUrl}template-field`,
    templateFieldsMassiveInsert: `${nestApiUrl}template-field/massive-insert`,
    module: `${nestApiUrl}cat/modules`,
    members: `${nestApiUrl}members-workspace`,
    invitation: `${nestApiUrl}invitation`,
    invitationAll: `${nestApiUrl}invitation/all`,
    invitationUser: `${nestApiUrl}invitation/user`,
    declineInvitation: `${nestApiUrl}invitation/decline`,
    cancelInvitation: `${nestApiUrl}invitation/cancel`,
    resendInvitation: `${nestApiUrl}invitation/resend`,
    acceptInvitation: `${nestApiUrl}members-workspace/accept-invitation`,
    editRoles: `${nestApiUrl}rol/update-roles`,
    permissions: `${nestApiUrl}rol/member-permissions`,
    clients: `${nestApiUrl}clients/list`,
    dropDownType: `${nestApiUrl}drop-down-type`,
    organs: `${nestApiUrl}cat/organs`,
    updateOrderField: `${nestApiUrl}template-field/update-order`,
    invoice: `${nestApiUrl}invoice`,
    invoicePayment: `${nestApiUrl}invoice-payments`,
    clientFiles: `${nestApiUrl}client-files`,
    getClientFileContent: `${nestApiUrl}client-files/content`,
    getPaymentFileContent: `${nestApiUrl}invoice-payments/content`,
    dossierUpload: `${nestApiUrl}dossier/upload`,
    clientUpload: `${nestApiUrl}clients/upload`,
    clientDossiers: `${nestApiUrl}dossier/detail-client/`,
    clientNotes: `${nestApiUrl}client-notes`,
    dossierNotes: `${nestApiUrl}dossier-notes`,
    updateDossierStatus: `${nestApiUrl}dossier/update-status`,
    updateDossierStatusMultiple: `${nestApiUrl}dossier/update-multiple-status`,
    reminder: `${nestApiUrl}reminder`,
    resendInvoice: `${nestApiUrl}invoice/resend`,
    publicSignUp: `${phpApiUrl}gen-users`,
    subscribeLanding: `${phpApiUrl}subscribe-landing`,
    memberRol: `${nestApiUrl}rol/member-rol`,
    clientPrivate: `${nestApiUrl}clients`,
    registerWithStripe: `${phpApiUrl}register-with-stripe`,
    paymentMethods: `${nestApiUrl}stripe/payment-methods`,
    notificationExtended: `${nestApiUrl}notification/extended`,
    notificationStatus: `${nestApiUrl}notification/status`,
    recentSearches: `${phpApiUrl}recent-search-list`,
    recentSearchesByUser: `${nestApiUrl}recent-searches`,
    recentFiles: `${nestApiUrl}dossier/recent-dossiers`,
    deleteRecentFiles: `${nestApiUrl}dossier/delete-recent-dossiers/`,
    deleteRecentQuery: `${phpApiUrl}recent-search-delete`,
    getInitialLeads: `${phpApiUrl}list-leads`,
    resendValidationEmail: `${phpApiUrl}send-email-verification`,
    leaveWorkspace: `${nestApiUrl}members-workspace/out-workspace`,
    newPaymentMethod: `${nestApiUrl}stripe/payment-methods/new`,
    deletePaymentMethod: `${nestApiUrl}stripe/payment-methods/new`,
    backOfficeUsers: `${nestApiUrl}user`,
    impersonateUser: `${nestApiUrl}user/supplant/`,
    statisticsTeamDossier: `${nestApiUrl}statistics/team/dossier-summary`,
    typeOrgan: `${nestApiUrl}cat/type-organ`,
    listLibrary: `${nestApiUrl}dossier-publication`,
    blogPost: `${nestApiUrl}blog-post`,
    postsSummary: `${nestApiUrl}blog-post/summary`,
    circuits: `${nestApiUrl}cat/circuits`,
    blogPostDetails: `${nestApiUrl}blog-post/detail/`,
    blogPostLikes: `${nestApiUrl}blog-post/likes`,
    reportReasons: `${nestApiUrl}cat/report-reasons`,
    formatFolder: `${nestApiUrl}format-folder`,
    formatFile: `${nestApiUrl}format-file`,
    comments: `${nestApiUrl}blog-comments`,
    blogReport: `${nestApiUrl}blog-post/report`,
    formatFileDownload: `${nestApiUrl}file-download`,
    formatFileToggleFavorite: `${nestApiUrl}format-file/toggle-favorite/`,
    formatFileContent: `${nestApiUrl}format-file/content/`,
    blogReported: `${nestApiUrl}blog-post/reported`,
    commentReported: `${nestApiUrl}blog-comments/reported`,
    dossierList: `${nestApiUrl}dossier/list`,
    dossierClientFiles: `${nestApiUrl}dossier/list-client-files`,
    dossierClientFilesByWorkspace: `${nestApiUrl}dossier/list-client-files-by-workspace`,
    dossierByDimension: `${nestApiUrl}statistics/dossier/dossier-summary-by-dimension`,
    processNotification: `${nestApiUrl}dossier-publication/process-notification`,
    dossierTags: `${nestApiUrl}dossier-tag`,
    dossierStatus: `${nestApiUrl}cat/status`,
    dossierStatusBase: `${nestApiUrl}cat/status-base`,
    affiliate: `${nestApiUrl}user/affiliate`,
    privateAffiliateSignup: `${nestApiUrl}user/join-affiliate-program`,
    statisticsTeamActivity: `${nestApiUrl}statistics/team/activity-summary`,
    sendEmailUser: `${nestApiUrl}invitation/user/send-email`,
    acceptInvitationLink: `${nestApiUrl}invitation/user/acceptance-link`,
    invitationUserReject: `${nestApiUrl}invitation/user/reject`,
    acceptInvitationUser: `${nestApiUrl}invitation/user/accept`,
    cancelInvitationUser: `${nestApiUrl}invitation/user/cancel`,
    signup: `${nestApiUrl}auth/signup`,
    paymentsByWorkspace: `${nestApiUrl}stripe-events/payments/`,
    storageObjects: `${nestApiUrl}storage-objects`,
    storageObjectDetails: `${nestApiUrl}storage-objects/directory-details`,
    templateStatusWorkspace: `${nestApiUrl}template/status-workspace`,
    dossierAgreement: `${nestApiUrl}dossier-agreement`,
    leads: `${nestApiUrl}initial-leads`,
    invoiceEmailHistory: `${nestApiUrl}invoice-email-history`,
    getSubscription: `${nestApiUrl}stripe-events/expiration-date/`,
    plans: `${nestApiUrl}cat/plans`,
    stripeSignup: `${nestApiUrl}auth/signup/stripe`,
    completeProfile: `${nestApiUrl}auth/complete-profile`,
    organsInfo: `${nestApiUrl}organs/judges`,
    organsList: `${nestApiUrl}organs/`,
    assistantThread: `${nestApiUrl}assistant/thread`,
    assistantThreadDocuments: `${nestApiUrl}assistant/thread/documents`,
    assistantRun: `${nestApiUrl}assistant/run`,
    assistantModels: `${nestApiUrl}assistant/models`,
    assistantMessage: `${nestApiUrl}assistant/message`,
    assistantVectorize: `${nestApiUrl}assistant/vectorize`,
    assistantUserThreads: `${nestApiUrl}assistant/threads`,
    userGroup: `${nestApiUrl}user-group`,
    updateDossierTeamMember: `${nestApiUrl}dossier/update-team-members`,
    dossierReport: `${nestApiUrl}reports/dossiers-by-template`,
    invoiceReport: `${nestApiUrl}reports/invoices`,
    reports: `${nestApiUrl}reports`,
    reportFolderDetails: `${nestApiUrl}reports/directory-details`,
    stripeCustomerUrl: `${nestApiUrl}user/stripe-url/`,
    toggleValidationUser: `${nestApiUrl}user/toggle-user-validation/`,
    resendValidationEmailBackOffice: `${nestApiUrl}user/resend-validation-email/`,
    updatePasswordBackOffice: `${nestApiUrl}user/update-password/`,
    toggle2fa: `${nestApiUrl}user/toggle-2fa/`,
    updateEmailBackOffice: `${nestApiUrl}user/request-email-update/`,
    generateUserPassword: `${nestApiUrl}user/update-password-by-email/`,
    changeEmailBackoffice: `${nestApiUrl}auth/update-user-email`,
    userJourney: `${nestApiUrl}user-journey`,
    journeyByUser: `${nestApiUrl}user-journey/by-user`,
    journeyByUserStep: `${nestApiUrl}user-journey/step`,
    userJourneyCompleted: `${nestApiUrl}user-journey/completed`,
    planChange: `${nestApiUrl}stripe/plan-change`,
    subscriptionByUser: `${nestApiUrl}subscription/by-user`,
    lastSubscriptionByUser: `${nestApiUrl}subscription/last-subscription`,
    subscriptionSchedule: `${nestApiUrl}stripe/schedule`,
    sidebarPreferences: `${nestApiUrl}user-preferences/menu/`,
    sidebarOptions: `${nestApiUrl}user-preferences`,
    sidebarUpdatePreferences: `${nestApiUrl}user-preferences/update/`,
    sidebarUserPreferences: `${nestApiUrl}user-preferences/menu-preferences`,
    firmTypes: `${nestApiUrl}cat/firm-types`,
    usageReasons: `${nestApiUrl}cat/usage-reasons`,
    errorReasons: `${nestApiUrl}cat/error-reasons`,
    preferredModules: `${nestApiUrl}cat/preferred-modules`,
    updateDossierTeamMemberMassive: `${nestApiUrl}dossier/update-team-members-massive`,
    assistantTokens: `${nestApiUrl}assistant/tokens`,
    assistantSettingsByUser: `${nestApiUrl}assistant-settings/by-user`,
    featureConfig: `${nestApiUrl}feature-config`,
    featureAccessByWorkspace: `${nestApiUrl}feature-access/by-workspace`,
    assistantSettings: `${nestApiUrl}assistant-settings`,
    searchObjectWithAiAttributes: `${nestApiUrl}search-object/by-open-search-id`,
    vectorRun: `${nestApiUrl}vector-run`,
    calendarEvent: `${nestApiUrl}calendar/event`,
    calendarEvents: `${nestApiUrl}calendar/events`,
    calendarGoogleAuthLogin: `${nestApiUrl}calendar/auth/google/login`,
    calendarGoogleAuthLogout: `${nestApiUrl}calendar/auth/google/logout`,
    calendarSettings: `${nestApiUrl}calendar/settings`,
    updateResponseStatus: `${nestApiUrl}calendar/event-user/response-status`,
    intercomHash: `${nestApiUrl}auth/intercom/hash`,
    downloadCertificateJourney: `${nestApiUrl}user-journey/generate`,
    workspaceUserRoles: `${nestApiUrl}cat/workspace-user-roles`,
    documentEditorSfdtByUrl: `${nestApiUrl}document-editor/sfdt`,
    documentEditorSfdtFromHtml: `${nestApiUrl}document-editor/html-to-sfdt`,
    documentEditorSfdtByFile: `${nestApiUrl}document-editor/import`,
    workspaceByUser: `${nestApiUrl}workspace/by-user`,
    studioFiles: `${nestApiUrl}studio`,
    insertStudioFiles: `${nestApiUrl}studio/multiple`,
    copyStudioFiles: `${nestApiUrl}studio/copy-from`,
    studioFolderDetails: `${nestApiUrl}studio/directory-details`,
    studioFileContent: `${nestApiUrl}studio/content/`,
    copyClientFiles: `${nestApiUrl}client-files/copy-from`,
    copyDossierFiles: `${nestApiUrl}activity-files/copy-from`,
    dossierFavorites: `${nestApiUrl}dossier/favorites`,
    sendMessageFeedback: `${nestApiUrl}assistant/message/feedback/`,
    getCalendarSettings: `${nestApiUrl}calendar/settings`,
    amparoQuickActions: `${nestApiUrl}amparo-quick-action`,
    generateDossierPDF: `${nestApiUrl}dossier/generate-dossier-pdf/`,
    updateOptionField: `${nestApiUrl}template/update-options-field`,
    getStatusOptionField: `${nestApiUrl}template/list-options-estatus-descripcion`,
    newModules: `${nestApiUrl}cat/new-modules`,
    bannerClose: `${nestApiUrl}user-preferences/close-banner`,
    userPreferences: `${nestApiUrl}user-preferences/mongo`,
    videoList: `${nestApiUrl}cat/video-list`,
    videoSectionList: `${nestApiUrl}cat/video-section`,
    playlistVideo: `${nestApiUrl}cat/playlist`,
    quickPromptsCategories: `${nestApiUrl}quick-prompt-template/categories`,
    quickPromptsDocumentTypes: `${nestApiUrl}quick-prompt-template`,
    insertContactGetResponse: `${nestApiUrl}get-response`,
};
