import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { JourneyCompleteModal } from 'src/app/features/updates/components/journey-complete/journey-complete.modal';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { RESOURCES } from '../constants/resource-service.constants';
import {
  CONFIGURATION_STEPS,
  EXPLORADOR_DIGITAL_STEPS,
  INNOVADOR_LEGAL_STEPS,
  VERSION_DATE,
  VISIONARIO_JURIDICO_STEPS,
} from '../constants/user-journey.constants';
import { APIResponse } from '../interfaces/api.interface';
import { DialogService } from './dialog.service';
import { UisrAuthService } from './uisr-auth.service';
import { WebSocketService } from './v2-socket.io.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class UserJourneyService {
    private readonly socketService = inject(WebSocketService);
    private readonly apiService = inject(UisrApiServiceV2);
    private readonly dialogService = inject(DialogService);
    private readonly authService = inject(UisrAuthService);
    private readonly subscriptionService = inject(SubscriptionService);

    loadingCertificate = new BehaviorSubject(false);
    levelOneSteps = EXPLORADOR_DIGITAL_STEPS;
    levelTwoSteps = CONFIGURATION_STEPS;
    levelThreeSteps = INNOVADOR_LEGAL_STEPS;
    levelFourSteps = VISIONARIO_JURIDICO_STEPS;
    loadingUserJourney = new BehaviorSubject(false);
    journeySteps: any[] = [];
    completed: boolean = false;
    completedArray = ['COMPLETED', 'OMITTED'];
    allSteps = [
        ...this.levelOneSteps,
        ...this.levelTwoSteps,
        ...this.levelThreeSteps,
        ...this.levelFourSteps,
    ];
    stepsByLevel = [
        this.levelOneSteps,
        this.levelTwoSteps,
        this.levelThreeSteps,
        this.levelFourSteps,
    ];
    stepsLength = this.allSteps.length.toString();

    constructor() {
        this.authService.permissions
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.levelOneSteps = this.levelOneSteps.filter(
                    (step) =>
                        !step.access || this.authService.hasAccess(step.access)
                );
                this.levelTwoSteps = this.levelTwoSteps.filter(
                    (step) =>
                        !step.access || this.authService.hasAccess(step.access)
                );
                this.levelThreeSteps = this.levelThreeSteps.filter(
                    (step) =>
                        !step.access || this.authService.hasAccess(step.access)
                );
                this.levelFourSteps = this.levelFourSteps.filter(
                    (step) =>
                        !step.access || this.authService.hasAccess(step.access)
                );
            });

        const sub = this.subscriptionService.subscription$.value;
        if (sub?.bolDelete === false) {
            this.getUserJourney();
        }
    }

    onUserJourneyChange() {
        this.socketService.socket?.on('user_update', (message: any) => {
            this.getUserJourney();
        });
    }

    getUserJourney() {
        this.apiService
            .get(RESOURCES.journeyByUser)
            .pipe(loadingState(this.loadingUserJourney), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    if (res.data) {
                        // Guarda la respuesta en this.journeySteps
                        this.journeySteps = res.data.steps;
                        this.completed = res.data.completed;

                        // Itera sobre los pasos de this.journeySteps
                        const completedSteps = [];
                        this.journeySteps.forEach((step: any) => {
                            const foundStep = this.allSteps.find(
                                (s) => s.step == step.step
                            );
                            if (foundStep) {
                                foundStep.status = step.status;
                            }
                            if (
                                step.status === 'COMPLETED' ||
                                step.status === 'OMITTED'
                            ) {
                                completedSteps.push(step);
                            }
                        });
                        if (
                            completedSteps.length == this.allSteps.length &&
                            !res.data.completed
                        ) {
                            this.openCompletedModal();
                        }

                        if (this.journeySteps.length) {
                            this.updateUserJourney();
                        }
                    }
                },
            });
    }

    openCompletedModal() {
        this.completeUserJourney();
        this.dialogService.closeAll();

        this.dialogService.openDialog(JourneyCompleteModal, {
            panelClass: 'app-custom-panel',
        });
    }

    updateUserJourney() {
        const data = {
            progress: {
                version: VERSION_DATE,
                completedPercentage:
                    this.getCompletedPercentage(false).toFixed(2),
                completedPercentageStrict: this.getCompletedPercentage(
                    false,
                    true
                ).toFixed(2),
            },
        };
        this.apiService
            .put(RESOURCES.userJourney, data)
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    completeUserJourney() {
        this.apiService.patch(RESOURCES.userJourneyCompleted, null).subscribe({
            next: () => this.getUserJourney(),
        });
    }

    getCompletedPercentage(parse = true, strict = false) {
        const completedSteps = [];
        if (this.journeySteps) {
            this.journeySteps.forEach((step) => {
                if (
                    step.status === 'COMPLETED' ||
                    (step.status === 'OMITTED' && !strict)
                ) {
                    completedSteps.push(step);
                }
            });
        }
        return (
            (completedSteps.length / this.allSteps.length) * (parse ? 100 : 1)
        );
    }

    getCompletedPercentageByStep(activeIndex: number) {
        const steps = this.stepsByLevel[activeIndex];
        const completed = steps.filter((e) =>
            this.completedArray.includes(e.status)
        );

        return (completed.length / steps.length) * 100;
    }

    filterUserJourneySteps() {
        return (
            this.journeySteps?.filter((step) => step.status != 'INCOMPLETE')
                .length || 0
        );
    }

    downloadCertificate() {
        this.apiService
            .getCertificate(RESOURCES.downloadCertificateJourney, {
                responseType: 'blob',
            })
            .pipe(loadingState(this.loadingCertificate), untilDestroyed(this))
            .subscribe({
                next: (res: Blob) => {
                    const blob = new Blob([res], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Certificado.pdf';
                    a.click();

                    window.URL.revokeObjectURL(url);
                },
            });
    }
}
