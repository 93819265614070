import { Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { UserData } from 'src/app/core/models/user-data';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { InfoDossier } from 'src/app/features/law-firm/interfaces/info-dossier.interfaces';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';

@UntilDestroy()
@Component({
    selector: 'app-amparo-dossier',
    templateUrl: './amparo-dossier.component.html',
    host: {
        class: 'text-slate-800 mb-1',
    },
})
export class AmparoDossierComponent implements OnInit {
    private readonly api = inject(UisrApiServiceV2);
    loading = new BehaviorSubject(false);
    dossier: InfoDossier | null = null;

    @Input({ required: true }) dossier_id!: number;
    @Input() userData?: UserData;

    ngOnInit() {
        this.getDossierDetails();
    }

    /** Obtiene los detalles del asunto */
    private getDossierDetails() {
        this.api
            .get(`${RESOURCES.getDossiersDetail}/${this.dossier_id}`)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: any) => {
                    if (res.success && res.data && res.data[0]) {
                        const valid = this.validateWorkspace(
                            res.data[0].fkIdWorkspace
                        );

                        if (valid) {
                            this.dossier = res.data[0];
                        }
                    }
                },
            });
    }

    /** Comparte el enlace al asunto */
    shareDossier() {
        if (!this.dossier) return;

        const url = `${window.location.origin}/law-firm/files/file-overview/${this.dossier.idDossier}`;

        // Usar la API de Clipboard para copiar al portapapeles
        navigator.clipboard.writeText(url).then(
            () => {
                Swal.fire({
                    ...ALERT_TOAST_DEFAULTS,
                    text: 'Enlace copiado',
                    icon: 'success',
                });
            },
            () => {
                // Fallback para navegadores que no soportan clipboard API
                const textarea = document.createElement('textarea');
                textarea.textContent = url;
                textarea.style.position = 'fixed';
                document.body.appendChild(textarea);
                textarea.select();

                try {
                    document.execCommand('copy');
                    Swal.fire({
                        ...ALERT_TOAST_DEFAULTS,
                        text: 'Enlace copiado',
                        icon: 'success',
                    });
                } catch (err) {
                    Swal.fire({
                        ...ALERT_TOAST_DEFAULTS,
                        text: 'No se pudo copiar el enlace',
                        icon: 'error',
                    });
                }

                document.body.removeChild(textarea);
            }
        );
    }

    validateWorkspace(idWorkspace: string) {
        const userWorkspace =
            this.userData?.idWorkspace || this.userData?.id_workspace_member;

        return idWorkspace == userWorkspace?.toString();
    }

    trackByFn(index: any) {
        return index;
    }

    onImageError(event: any) {
        event.target.src = 'assets/images/profile-placeholder.png';
    }
}
