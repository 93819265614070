import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ResultSourceService } from 'src/app/shared/services/result-source.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';

@UntilDestroy()
@Component({
  selector: 'app-report-result-modal',
  templateUrl: './report-result-modal.component.html',
  host: {
    class: 'contents',
  },
})
export class ReportResultModalComponent implements OnInit {
  reportDescription: string | null = null;
  messages: any = {};
  resources = RESOURCES;

  constructor(
    private apiService: UisrApiServiceV2,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public sourceService: ResultSourceService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: any
  ) {}

  async ngOnInit() {
    this.getMessages();
  }

  sendReport() {
    if (!this.reportDescription) {
      this.toastr.error(this.messages.GENERIC_FORM_MESSAGE);
      return;
    }
    let data = {
      id_index_result: this.sourceService.getSourceId(this.data.index),
      fk_id_result: this.data.id,
      descript_error: this.reportDescription,
    };
    this.apiService
      .post(this.resources.submitReport, data, null, ['button'])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: any) => {
          this.toastr.clear();
          if (res.success) {
            this.toastr.success(this.messages.REPORT_SENT_MESSAGE);
            this.dialogRef.close(true);
          } else {
            let message = this.messages.GENERIC_ERROR_MESSAGE;
            if (res.code) {
              let messageAux = this.translateService.instant(res.code);
              message = messageAux != res.code ? messageAux : message;
            }
            Swal.fire({
              ...ALERT_DEFAULTS,
              text: message,
            });
          }
        },
        error: () => {
          this.toastr.clear();
        },
      });
  }

  async getMessages() {
    this.messages = this.translateService.instant([
      'GENERIC_ERROR_MESSAGE',
      'GENERIC_FORM_MESSAGE',
      'REPORT_SENT_MESSAGE',
    ]);
  }
}
