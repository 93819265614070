import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClientFolder } from 'src/app/features/law-firm/interfaces/client.interfaces';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import {
    FilterStudioFile,
    StudioFiles,
} from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { FileCopyTargetType } from '../../services/studio-file.service';
import { DossierDTO } from 'src/app/features/law-firm/interfaces/dossier.interfaces';

@UntilDestroy()
@Component({
    selector: 'app-cross-area-doc-selector',
    templateUrl: './cross-area-doc-selector.component.html',
})
export class CrossAreaDocSelectorComponent {
    public readonly dialogData = inject(DIALOG_DATA, { optional: true });
    readonly dialogRef = inject(DialogRef);

    /** Indica si el usuario puede mover los documentos (eliminarlos del origen) */
    canMove = false;
    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    folders: any[] = [];
    selection = new Set();
    selectedFolder?: StudioFiles;
    searchControl: FormControl<null | string> = new FormControl(null);
    existingIds: number[] = [];
    filter: FilterStudioFile = {};
    filterSubject = new Subject<FilterStudioFile>();
    targetTypes = FileCopyTargetType;
    tabs: {
        label: string;
        action: FileCopyTargetType;
    }[] = [
        { label: 'Asuntos', action: FileCopyTargetType.Dossiers },
        { label: 'Clientes', action: FileCopyTargetType.Clients },
        { label: 'Estudio', action: FileCopyTargetType.Studio },
    ];
    selectedTab?: FileCopyTargetType;
    move = false;
    sameOrigin = false;
    /** Es el id del contenedor actual del documento, por ejemplo el id de la carpeta contenedora en el estudio. Actualmente solo se usa para el estudio */
    originalId?: number;

    ngOnInit() {
        if (this.dialogData?.origin && !this.dialogData?.sameOrigin) {
            this.tabs = this.tabs.filter(
                (tab) => tab.action != this.dialogData.origin
            );
        }
        this.originalId = this.dialogData?.originalId;
        if (this.dialogData?.canMove) {
            this.canMove = this.dialogData.canMove;
        }
        this.selectedTab = this.tabs[0].action;
    }

    /** Permite cambiar la pestaña seleccionada en el componente solo si la nueva pestaña es diferente a la actual y restablece la selección */
    changeTab(action: FileCopyTargetType) {
        if (this.selectedTab != action) {
            this.selectedTab = action;
            this.selection.clear();
        }
    }

    onSelectionChange(
        selection: Set<StudioFiles | ClientFolder | StorageObject | DossierDTO>
    ) {
        this.selection = selection;
    }

    /** Se ejecuta cuando el usuario confirma la selección, emite la selección de carpetas actual*/
    onSubmit() {
        if (this.selectedTab) {
            // Listado de todas las carpetas seleccionadas
            const folders: any[] = Array.from(this.selection);
            let destination: FileCopyTargetType = this.selectedTab;

            // Si el destino seleccionado es el asunto, sin carpetas, cambiar el destino a EmptyDossier
            if (folders.some((f) => 'idDossier' in f)) {
                destination = FileCopyTargetType.EmptyDossier;
            }
            this.dialogRef?.close({ folders, destination, move: this.move });
        }
    }
}
