<div class="bg-pearl-100 border border-slate-200 shadow-xl"
  [ngClass]="!inline ? 'mt-1 rounded animate__animated animate__fadeIn animate__faster max-w-4xl w-[90svw]':'px-4 sm:px-6 lg:px-8'">
  <div cdkMenu class="grid sm:grid-cols-2 px-3 py-4 text-gray-900 md:px-4 max-w-9xl mx-auto">
    <div class="font-semibold">Autogestión</div>
    <div class="font-semibold max-sm:order-5">Contáctanos</div>
    <div cdkMenuItem class="py-1 px-2">
      <a (click)="helpCenterAnalytics()"
        href="https://midespacho.notion.site/Centro-de-Ayuda-2b498667b40943079f573c9b9e1ab539"
        class="block hover:bg-slate-50 dark:hover:bg-gray-700" target="_blank" rel="noopener noreferrer">
        <div
          class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          🆘 Centro de Ayuda
        </div>
        <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
          Te ofrecemos recursos para optimizar tu uso de la plataforma. </div>
      </a>
    </div>
    <div cdkMenuItem class="py-1 px-2 max-sm:order-6">
      <button id="intercom-launcher" (click)="intercomService.showIntercom()"
        class="block hover:bg-slate-50 dark:hover:bg-gray-700">
        <div
          class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          💬 Chat en vivo</div>
        <div class="text-xs text-left text-gray-500 dark:text-gray-400">
          Encuentra un asesor para resolver tus dudas.
        </div>
      </button>
    </div>
    <div cdkMenuItem *ngIf="assistantService.assistantAvailable()" class="py-1 px-2">
      <button (click)="toggleAssistant()" class="block hover:bg-slate-50 dark:hover:bg-gray-700">
        <div
          class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          <div class="mr-2 -ml-1">
            <img src="/assets/images/isotipo.png" alt="Amparo IA" class="w-6 h-6">
          </div>
          Asistencia con Amparo IA
        </div>
        <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
          Tu asistente legal para gestionar y simplificar tu trabajo.</div>
      </button>
    </div>
    <div cdkMenuItem class="py-1 px-2 max-sm:order-7">
      <a href="https://calendly.com/valearm/bienvenido-agenda-una-implementacion-de-midesp-clon-1?month=2024-09"
        target="_blank" rel="noopener noreferrer" (click)="scheduleMeetingAnalytics()"
        class="block hover:bg-slate-50 dark:hover:bg-gray-700">
        <div
          class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          🗓️ Agenda una reunión con nosotros</div>
        <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
          Agendemos una reunión, queremos ayudarte a resolver tus dudas.</div>
      </a>
    </div>
    <div cdkMenuItem class="py-1 px-2">
      <button (click)="openUserJourney()" class="block hover:bg-slate-50 dark:hover:bg-gray-700">
        <div
          class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          ⛰️ Tour MiDespacho</div>
        <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
          Aprende más sobre las funciones que tenemos para ti.</div>
      </button>
    </div>
    <div cdkMenuItem class="py-1 px-2 max-sm:order-8">
      <div class="block hover:bg-slate-50 dark:hover:bg-gray-700">
        <div
          class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
          📞 Llámanos</div>
        <div class="text-xs text-left text-gray-500 dark:text-gray-400">+52 (55) 62 83
          03 01
        </div>
      </div>
    </div>
  </div>
</div>