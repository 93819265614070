<div class="max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl" id="doc-picker-container">
  <div class="py-2 px-5 flex border-b border-slate-200">
    <!-- Botón para volver a la lista principal -->
    <button
      class="grid size-[2.375rem] shrink-0 place-content-center rounded enabled:hover:bg-slate-300/60 enabled:hover:shadow-sm enabled:hover:text-slate-500 disabled:opacity-50 text-slate-400 mr-1"
      (click)="returnClicked.emit()" *ngIf="allowReturn" [ngxTippy]="'Volver'">
      <i class="fa-solid fa-arrow-left"></i>
    </button>
    <!-- Input de búsqueda -->
    <div class="relative grow">
      <label for="simple-doc-picker-search" class="sr-only" translate="SEARCH_PLACEHOLDER_STRING"></label>
      <input (keydown.enter)="$event.preventDefault();onSearch()" id="simple-doc-picker-search"
        [formControl]="searchControl" class="form-input w-full !pr-14" type="search"
        placeholder="Busca una carpeta por su nombre">
      <div class="absolute flex gap-2 right-3 top-2/4 -translate-y-2/4">
        <button type="submit" *ngIf="searchControl.value" aria-label="Limpiar" ngxTippy data-tippy-content="Limpiar"
          (click)="clearSearch()" class="px-2 border-r border-slate-200" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone error-icon fa-xmark text-[0.8rem]" style="--fa-secondary-opacity: 0.8 !important"></i>
        </button>
        <button (click)="onSearch()" type="submit" aria-label="Buscar" ngxTippy
          data-tippy-content="Clic aquí para buscar" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone fa-magnifying-glass secondary-icon text-[0.8rem]"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Empty state si no hay carpetas/documentos -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
    *ngIf="!items[0] && !(loading | async)">
    <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[2rem]"></i>
    </div>
    <span class="text-center font-medium" translate="NO_FOLDERS_FOUND"></span>
  </div>

  <div class="pl-5 pr-4 overflow-auto space-y-2">
    <!-- Listado de favoritos -->
    <div *ngIf="favoritesConfig.totalItems && (!(loading | async) || selectedFolder)" class="flex flex-col gap-2 mb-2 py-2 border-b border-slate-200">
      <h2 class="font-medium text-slate-600 text-sm">Favoritos</h2>
      <app-template-data [items]="favorites" [sizeLimit]="sizeLimit" [selectedFolder]="selectedFolder"
        *ngIf="favorites.length" />
      <div *ngIf="favorites.length < favoritesConfig.totalItems">
        <button (click)="viewMoreFavorites()" class="flex items-center gap-1.5">
          <i class="fa-solid fa-spinner-third animate-spin" *ngIf="loadingFavorites | async"></i>
          <span class="text-sm">Ver Más</span>
        </button>
      </div>
    </div>
    <!-- Listado de carpetas/documentos -->
    <h2 class="font-medium text-slate-600 text-sm mb-2" *ngIf="items.length">Otros Machotes</h2>
    <app-template-data [items]="items" [sizeLimit]="sizeLimit" [selectedFolder]="selectedFolder" />
    <div *ngIf="searchPaginationConfig.totalItems && items.length < searchPaginationConfig.totalItems">
      <button (click)="viewMoreSearchResults()" class="flex items-center gap-1.5">
        <i class="fa-solid fa-spinner-third animate-spin" *ngIf="loading | async"></i>
        <span class="text-sm">Ver Más</span>
      </button>
    </div>
  </div>

  <!-- Loader de carpetas -->
  <div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5" *ngIf="(loading | async) && !selectedFolder">
    <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
      <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
      <div class="flex flex-col gap-1 *:bg-gray-200 grow">
        <div class="w-9/12 h-3 rounded"></div>
        <div class="w-5/12 h-3 rounded"></div>
      </div>
    </div>
  </div>
</div>