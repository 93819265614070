<!-- Sección de selección de tipo de documento L1 -->
<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center">
  <span class="font-semibold text-primary-100">Redactar Escrito</span>
  <!-- Cerrar el modal -->
  <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
    class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
    (click)="dialogRef.close()" id="close-doc-picker">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid text-[1rem] fa-xmark"></i>
  </button>
</div>

<div class="py-5 flex flex-col min-h-0 w-[90svw] max-w-2xl gap-4 text-sm" id="doc-picker-container">
  <!-- Instrucciones al usuario con breadcrumb visual del proceso -->
  <div class="px-5 flex flex-col gap-2">
    <p class="text-slate-600 text-sm">✍️💡Optimiza tu redacción con instrucciones predefinidas y machotes que guían a la
      IA según tus necesidades.</p>


    <!-- Breadcrumb/stepper visual para indicar progreso -->
    <div class="flex items-center text-xs text-slate-500 gap-1.5 mt-1 overflow-x-auto">
      <div class="flex items-center gap-1">
        <span class="inline-flex items-center justify-center size-5 rounded-full"
          [ngClass]="{'bg-indigo-100 text-indigo-600': !selectedCategory || isStepActive(1), 'bg-indigo-600 text-white': isStepComplete(1)}">
          <i *ngIf="isStepComplete(1)" class="fa-solid fa-check text-xs"></i>
          <span *ngIf="!isStepComplete(1)">1</span>
        </span>
        <span class="font-medium"
          [ngClass]="{'text-indigo-600': isStepActive(1), 'text-slate-400': !isStepActive(1)}">Categoría</span>
      </div>
      <i class="fa-solid fa-chevron-right text-slate-300"></i>
      <div class="flex items-center gap-1">
        <span class="inline-flex items-center justify-center size-5 rounded-full"
          [ngClass]="{'bg-indigo-100 text-indigo-600': isStepActive(2), 'bg-indigo-600 text-white': isStepComplete(2), 'bg-slate-100 text-slate-400': !isStepActive(2) && !isStepComplete(2)}">
          <i *ngIf="isStepComplete(2)" class="fa-solid fa-check text-xs"></i>
          <span *ngIf="!isStepComplete(2)">2</span>
        </span>
        <span class="font-medium"
          [ngClass]="{'text-indigo-600': isStepActive(2), 'text-slate-400': !isStepActive(2)}">Instrucción
          predefinida</span>
      </div>
      <i class="fa-solid fa-chevron-right text-slate-300"></i>
      <div class="flex items-center gap-1">
        <span class="inline-flex items-center justify-center size-5 rounded-full"
          [ngClass]="{'bg-indigo-100 text-indigo-600': isStepActive(3), 'bg-slate-100 text-slate-400': !isStepActive(3)}">3</span>
        <span class="font-medium"
          [ngClass]="{'text-indigo-600': isStepActive(3), 'text-slate-400': !isStepActive(3)}">Machote</span>
      </div>
    </div>
  </div>

  <!-- Sección de categorías - PASO 1 -->
  <div class="flex flex-col gap-2 px-5 overflow-y-auto max-h-[50vh]" *ngIf="isStepActive(1)">
    <div class="space-y-2">
      <button *ngFor="let category of categories" (click)="selectDocumentCategory(category.category)"
        class="w-full py-3 px-4 rounded-md border border-slate-200 text-sm font-medium hover:bg-slate-50 transition-colors flex justify-between items-center shadow-sm"
        [ngClass]="{'bg-indigo-50 border-indigo-300 text-indigo-700 ring-1 ring-indigo-200': selectedCategory === category.category}">
        <div class="flex items-center gap-2">
          <i class="fa-regular fa-folder text-amber-400"></i>
          {{ category.category }}
        </div>

        <div class="flex items-center gap-2 text-xs text-slate-500">
          <span class="bg-slate-100 px-2 py-0.5 rounded-full">{{category.count}}</span>
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </button>
    </div>
  </div>

  <!-- Sección de selección de subtipo de documento L2 - PASO 2 -->
  <div *ngIf="isStepActive(2)" class="mt-2 px-5">
    <div class="flex items-center gap-1.5 mb-3 overflow-x-auto">
      <button (click)="goBack()"
        class="flex items-center gap-1 text-xs text-indigo-600 hover:text-indigo-800 py-1 px-2 rounded-full hover:bg-indigo-50">
        <i class="fa-solid fa-chevron-left"></i> Volver
      </button>
      <span class="text-sm text-slate-500">/ {{ selectedCategory }}</span>
    </div>

    <div class="flex flex-col gap-2 overflow-y-auto max-h-[50vh]">
      <div class="relative group rounded-md border border-slate-200 shadow-sm min-w-0 hover:bg-indigo-50 flex"
        *ngFor="let docType of documentTypes"
        [ngClass]="{'bg-indigo-50 border-indigo-300 text-indigo-700': selectedDocTypeL2 === docType}">
        <button (click)="selectDocumentType(docType)"
          class="text-sm font-medium flex items-center gap-2 min-w-0 grow py-3 px-4">
          <i class="fa-regular fa-file-lines text-indigo-400"></i>
          <span class="truncate">{{ docType.prompt_title }}</span>
        </button>
        <div
          class="absolute right-0 top-0 bottom-0 flex items-center justify-center bg-gradient-to-l from-indigo-50 from-90% to-transparent z-[1] px-5"
          [ngClass]="{'invisible group-hover:visible': selectedDocTypeL2 !== docType}">
          <button (click)="openPromptInfo(docType)"
            class="size-8 rounded-full text-slate-600 group-hover:text-slate-800" ngxTippy="Ver"
            [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>

    </div>
  </div>

  <!-- Sección de selección de machote en Predefinidos MiDespacho - PASO 3  -->
  <div *ngIf="isStepActive(3)" class="flex flex-col gap-4">
    <div class="flex items-center gap-1.5 px-5 mb-1 overflow-x-auto">
      <button (click)="goBack()"
        class="flex items-center gap-1 text-xs text-indigo-600 hover:text-indigo-800 py-1 px-2 rounded-full hover:bg-indigo-50">
        <i class="fa-solid fa-chevron-left"></i> Volver
      </button>
      <span class="text-sm text-slate-500">/ {{ selectedCategory }} / {{ selectedDocTypeL2?.prompt_title }}</span>
    </div>

    <!-- Tabs -->
    <div class="flex gap-1 border-b border-slate-200 -mx-5 px-5" *ngIf="isStepActive(3)">
      <button *ngFor="let type of machotesTypes" (click)="selectTab(type.value)"
        class="py-2 px-4 text-sm font-medium transition-colors relative" [ngClass]="{
              'text-indigo-600': selectedTab === type.value,
              'text-slate-500 hover:text-slate-700': selectedTab !== type.value
            }">
        {{ type.label }}
        <div class="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-600 transition-all"
          [ngClass]="{'opacity-100': selectedTab === type.value, 'opacity-0': selectedTab !== type.value}">
        </div>
      </button>
    </div>

    <!-- Buscador de machotes -->
    <div class="px-5 border-slate-200" [ngClass]="{'pb-3 border-b': selectedTab === 'mi-despacho'}">
      <p class="text-xs text-slate-500" [ngClass]="{'mb-2': selectedTab === 'mi-despacho'}">Opcional - Puedes continuar
        sin seleccionar un machote</p>
      <div class="flex gap-2" *ngIf="selectedTab === 'mi-despacho'">
        <div class="relative flex-1">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <i class="fa-solid fa-search text-slate-400"></i>
          </div>
          <input type="text" [(ngModel)]="templateSearchQuery" placeholder="Buscar machotes..."
            (keyup.enter)="searchTemplates()"
            class="w-full py-2 pl-10 pr-3 border border-slate-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm" />
        </div>
        <button (click)="searchTemplates()"
          class="py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors font-medium">
          Buscar
        </button>
      </div>
    </div>

    <!-- Lista de machotes encontradas -->
    <div class="px-5 overflow-y-auto max-h-[40vh]" *ngIf="selectedTab === 'mi-despacho'">
      <div *ngIf="templates.length === 0 && !loadingTemplates.value"
        class="text-center py-8 text-slate-500 flex flex-col items-center">
        <i class="fa-solid fa-file-circle-xmark text-4xl mb-3 text-slate-300"></i>
        <p class="text-slate-600 font-medium">No se encontraron machotes</p>
        <p class="text-sm mt-1">Prueba con otros términos de búsqueda o continúa sin un machote</p>
      </div>

      <div *ngIf="templates.length > 0 && !loadingTemplates.value" class="flex flex-col gap-2 py-1">
        <div class="relative group rounded-md border border-slate-200 shadow-sm min-w-0 hover:bg-indigo-50 flex"
          *ngFor="let template of templates"
          [ngClass]="{'bg-indigo-50 border-indigo-300 text-indigo-700': isSelectedTemplate(template)}">
          <button (click)="toggleTemplateSelection(template)"
            class="text-sm font-medium flex items-center gap-2 min-w-0 grow py-3 px-4"
            [ngClass]="{'bg-indigo-50 border-indigo-200 ring-1 ring-indigo-200': isSelectedTemplate(template)}">
            <i class="fa-regular fa-file-word text-indigo-500 text-lg mt-0.5"></i>
            <h4 class="font-medium text-slate-800 truncate">{{ template.displayName }}</h4>
          </button>
          <div
            class="absolute right-0 top-0 bottom-0 flex items-center justify-center bg-gradient-to-l from-indigo-50 from-90% to-transparent z-[1] px-5"
            [ngClass]="{'invisible group-hover:visible': !isSelectedTemplate(template)}">
            <button (click)="openPreview(template)"
              class="size-8 rounded-full text-slate-600 group-hover:text-slate-800" ngxTippy="Ver"
              [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Contenido para Despacho -->
  <ng-container *ngIf="selectedTab === 'despacho' && isStepActive(3)">
    <app-select-studio-file-tab [extensions]="['docx', 'doc']" [baseType]="machotesDespachoType"
      [singleSelection]="true" [allowReturn]="false" (returnClicked)="selectTab('mi-despacho')"
      [overrideType]="'machoteStudio'" [selectedOverride]="selectedTemplate" [eventsOnly]="true"
      (itemClick)="toggleTemplateSelection($event)">
    </app-select-studio-file-tab>
  </ng-container>

  <!-- Contenido para Personales -->
  <ng-container *ngIf="selectedTab === 'personal' && isStepActive(3)">
    <app-select-studio-file-tab [extensions]="['docx', 'doc']" [baseType]="machotesPersonalType"
      [singleSelection]="true" [allowReturn]="false" (returnClicked)="selectTab('mi-despacho')"
      [overrideType]="'machoteStudio'" [selectedOverride]="selectedTemplate" [eventsOnly]="true"
      (itemClick)="toggleTemplateSelection($event)">
    </app-select-studio-file-tab>
  </ng-container>

  <!-- Loader común para todos los pasos -->
  <div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5"
    *ngIf="(loadingCategories | async) || (loadingDocumentTypes | async) || (loadingTemplates | async)">
    <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
      <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
      <div class="flex flex-col gap-1 *:bg-gray-200 grow">
        <div class="w-9/12 h-3 rounded"></div>
        <div class="w-5/12 h-3 rounded"></div>
      </div>
    </div>
  </div>

  <!-- Acciones en el pie del modal -->
  <div *ngIf="isStepActive(3) || (isStepActive(2) && selectedDocTypeL2)"
    class="px-5 mt-auto pt-3 border-t border-slate-200 flex justify-between">
    <button (click)="goBack()"
      class="py-2 px-4 border border-slate-300 text-slate-700 rounded-md hover:bg-slate-50 transition-colors font-medium">
      Volver
    </button>
    <button (click)="continue()"
      class="py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors font-medium">
      {{ isStepActive(3) && selectedTemplate ? 'Continuar con machote' : 'Continuar sin machote' }}
    </button>
  </div>
</div>