<!-- Indicator de carga de conversaciones -->
<app-indeterminate-loader *ngIf="assistantChatService.loadingStates.loadingThreads.value" [position]="'relative'"
    [top]="'0'" [left]="'0'"></app-indeterminate-loader>

<!-- Conversaciones -->
<div #threadsWrapper
    class="px-5 py-4 flex-1 flex flex-col relative max-h-full overflow-x-hidden overflow-y-auto no-scrollbar"
    infiniteScroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="150" [scrollWindow]="false"
    (scrolled)="assistantChatService.fetchNextThreads()" appResizeObserver (onResizeObserver)="checkThreadsScroll()">
    <div class="flex-1 flex flex-col max-h-full z-0">
        <div id="assistant-threads">
            <div class="flex justify-between items-center mb-3">
                <div class="text-xs font-semibold text-slate-400 uppercase">
                    Conversaciones
                </div>
                <button (click)="assistantChatService.deactivateThread()"
                    class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
                    [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Nueva Conversación'">
                    <i class="fa-duotone fa-plus opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
                </button>
            </div>
            <div class="mb-6">
                <ul *ngIf="assistantChatService.threads[0]" class="mb-3">
                    <!-- Thread falsa para el tutorial  -->
                    <li class="-mx-2 -intro-x pointer-events-none"
                        *ngIf="tutorialService.isTutorialActive('assistant-tour')">
                        <div class="flex items-center justify-between w-full p-2 rounded bg-indigo-500/30">
                            <div class="flex items-center truncate w-full justify-between">
                                <div class="truncate">
                                    <span class="text-sm font-medium text-slate-800"></span>
                                    &nbsp;
                                </div>

                                <div class="truncate">
                                    <span class="text-sm font-medium text-slate-800">
                                        Nueva conversación
                                    </span>
                                    &nbsp;
                                </div>

                                <button
                                    class="w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded ml-2">
                                    <i class="fa-solid fa-ellipsis opacity-75 hover:opacity-100"
                                        style="font-size: 1rem;"></i>
                                </button>
                            </div>
                        </div>
                    </li>
                    <ng-container *ngFor="let thread of assistantChatService.threads; let i = index;">
                        <!-- Date separator -->
                        <li *ngIf="isNewDayThread(thread.created_at, assistantChatService.threads[i - 1]?.created_at).condition"
                            class="mt-3 first:mt-0 inline-flex text-xs text-slate-500 font-medium py-2"
                            style="overflow-anchor: none">
                            {{ isNewDayThread(thread.created_at, assistantChatService.threads[i - 1]?.created_at).date }}
                        </li>

                        <!-- Thread Item -->
                        <app-thread-item 
                            [thread]="thread"
                            [isActive]="thread.threadId == assistantChatService.thread?.threadId">
                        </app-thread-item>
                    </ng-container>
                </ul>
                <div *ngIf="assistantChatService.loadingStates.loadingThreads.value"
                    class="w-full h-auto flex items-center justify-center text-slate-400">
                    <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
                        <path
                            d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z">
                        </path>
                    </svg>
                </div>
            </div>
        </div>
        <div *ngIf="assistantChatService.totalThreads == 0"
            class="flex-1 flex flex-col gap-4 items-center justify-center text-center">
            <i class="fa-solid fa-message-bot text-slate-400" style="font-size: 3rem"></i>
            <p class="text-sm">Parece que aún no has iniciado ninguna conversación</p>
        </div>
    </div>
</div>