import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, lastValueFrom, of, switchMap } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ResultSourceService } from 'src/app/shared/services/result-source.service';
//@ts-ignore
import * as mark from 'node_modules/mark.js';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ComponentBlocker } from 'src/app/core/utils/component-blocker';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { CollectionsService } from '../../../../shared/services/collections.service';
import { COLLECTION_TYPES } from '../../constants/collections.constants';
import {
  CollectionLite,
  SearchResult,
} from '../../interfaces/collections.interfaces';
import { AddCollectionComponent } from '../add-collection/add-collection.component';

@UntilDestroy()
@Component({
  selector: 'app-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  host: {
    class: 'contents',
  },
})
export class AddToCollectionComponent implements OnInit {
  userCollections = computed(() => {
    return this.collectionsService
      .userCollections()
      .filter(
        (col) =>
          col.fk_id_type_collection == COLLECTION_TYPES['CUSTOM'] ||
          col.fk_id_type_collection == COLLECTION_TYPES['FAVORITES']
      );
  });
  dossierCollections = computed(() => {
    return this.collectionsService
      .userCollections()
      .filter(
        (col) => col.fk_id_type_collection == COLLECTION_TYPES['DOSSIERS']
      );
  });
  favorites = this.collectionsService.favorites;
  resources = RESOURCES;
  matchingCollections = 0;
  matchingDossierCollections = 0;
  collectionsSearchValue = '';
  messages: any = {};
  public loadingCollections = new BehaviorSubject(true);
  loading = toSignal(
    this.blocker.loaderAll.pipe(
      switchMap((val) =>
        of(
          val.button.count &&
            val.button.paths.includes(this.resources.getCollections)
        )
      )
    )
  );

  constructor(
    private apiService: UisrApiServiceV2,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private sourceService: ResultSourceService,
    private blocker: ComponentBlocker,
    private dialogService: DialogService,
    public dialogRef: DialogRef,
    public collectionsService: CollectionsService,
    @Inject(DIALOG_DATA)
    public resultToAdd: SearchResult
  ) {
    this.subscribeToLoader();
  }

  subscribeToLoader() {
    this.blocker.loaderAll.subscribe((data) => {
      if (data.global.count == 0) {
        this.loadingCollections.next(false);
        return;
      }

      this.loadingCollections.next(
        data.global.paths.includes(this.resources.getCollectionsLite)
      );
    });
  }

  collectionSearch(value: string) {
    this.matchingDossierCollections = 0;
    this.matchingCollections = 0;
    const searchInCollections = (collections: any[]) => {
      collections.forEach((collection: any, index: number) => {
        let html = document.getElementById(
          'collection-search-' + collection.id_collection_user
        );
        let showView = html?.textContent
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
        if (showView) {
          if (collections === this.dossierCollections()) {
            this.matchingDossierCollections += 1;
          } else if (collections === this.userCollections()) {
            this.matchingCollections += 1;
          }
          (html as any).style.display = 'grid';
          var markInstance = new mark(html as any);
          var searchTerm = value.toLowerCase();
          markInstance.unmark().mark(searchTerm);
        } else {
          (html as any).style.display = 'none';
        }
      });
    };

    searchInCollections(this.dossierCollections());
    searchInCollections(this.userCollections());
  }

  async ngOnInit() {
    this.messages = await this.getMessages();
    this.collectionsService.loadUserCollections();
  }

  async getMessages() {
    let allMessages = await lastValueFrom(
      this.translateService.get([
        'GENERIC_ERROR_MESSAGE',
        'ADDING_TO_COLLECTION_STRING',
        'DOCUMENT_ADDED_TO_COLLECTION_STRING',
        'NO_COLLECTION_SELECTED_STRING',
      ])
    );
    return allMessages;
  }

  isFavorite(): boolean {
    if (!this.resultToAdd) {
      return false;
    }
    let isFavorite = this.favorites().some(
      (favorite) => favorite.id_open_search == this.resultToAdd.id
    );
    return isFavorite;
  }

  addDocumentToCollection() {
    let selectedCollection = this.userCollections().find(
      (collection: any) => collection.active
    );

    if (!selectedCollection) {
      selectedCollection = this.dossierCollections().find(
        (collection: any) => collection.active
      );
    }

    if (!selectedCollection) {
      this.toastr.error(this.messages.NO_COLLECTION_SELECTED_STRING);
      return;
    }
    let isFavorite = selectedCollection.fk_id_type_collection == 1;

    this.toastr.info(this.messages.ADDING_TO_COLLECTION_STRING);
    let data = {
      heading: this.sourceService.parseResultTitle(this.resultToAdd.name),
      id_open_search: this.resultToAdd.id,
      fk_id_index: this.sourceService.getSourceId(this.resultToAdd.index),
      fk_id_type_collection: isFavorite ? 1 : null,
      fk_id_collection_user: isFavorite
        ? null
        : selectedCollection.id_collection_user,
    };
    this.apiService
      .put(this.resources.addDocumentToCollection, data, null, ['button'])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastr.clear();
          this.dialogRef.close(true);
          this.toastr.success(
            this.messages.DOCUMENT_ADDED_TO_COLLECTION_STRING
          );
        },
        error: (_error) => {
          this.toastr.clear();
        },
      });
  }

  markCollectionAsActive(collection: any) {
    this.userCollections().forEach(
      (collection: any) => (collection.active = false)
    );
    this.dossierCollections().forEach(
      (collection: any) => (collection.active = false)
    );
    collection.active = true;
  }

  addCollection() {
    this.dialogService.openDialog(AddCollectionComponent).subscribe((res) => {
      if (res) {
        this.collectionsSearchValue = '';
        this.collectionsService.loadUserCollections();
      }
    });
  }

  includesResult(collection: CollectionLite) {
    return collection.search_objects.some((searchObject: any) => {
      return (
        searchObject.id_open_search == this.resultToAdd.id &&
        searchObject.fk_id_index ==
          this.sourceService.getSourceId(this.resultToAdd.index)
      );
    });
  }
}
