<div class="overflow-auto bg-pearl-100">
  <ng-container *ngFor="let item of items; let i = index; trackBy:trackByFn">
    <!-- Carpeta o archivo -->
    <div class="-mt-px first:mt-0" [ngxTippy]="getTooBigTooltip(item)" [tippyProps]="{ allowHTML: true}">
      <button type="button"
        class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent relative"
        (click)="container.onItemClick(item)"
        [disabled]="(container.loadingFolder | async) || docPickerService.isExisting(item) || (item && isTooBig(item))"
        [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': docPickerService.isSelected(item) || docPickerService.isExisting(item), 'opacity-50': isTooBig(item)}">
        <ng-container *ngIf="!(container.loadingFolder | async) || selectedFolder?.idFolder != item.idFolder">
          <i class="fa-regular text-[1rem] text-slate-600 fa-folder" *ngIf="isFolder(item)"></i>
        </ng-container>
        <ng-container
          *ngIf="isFolder(item) && (container.loadingFolder | async) && selectedFolder?.idFolder == item.idFolder">
          <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
        </ng-container>
        <span *ngIf="!isFolder(item)" class="font-medium text-slate-600 truncate text-sm">{{ item.displayName |
          titlecase
          }}</span>
        <span *ngIf="isFolder(item)" class="font-medium text-slate-600 truncate text-sm">{{ item.name.split("/").pop()
          }}</span>
        <div class="px-3 ml-auto" *ngIf="isFolder(item)">
          <div class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible">
            <i class="fa-solid fa-chevron-right text-sm"></i>
          </div>
        </div>
        <div
          class="bg-gradient-to-l from-indigo-50 from-90% to-transparent right-0 absolute flex gap-2 items-center justify-end group-hover:visible z-[1] transition-opacity duration-300 top-0 bottom-0 px-5"
          [ngClass]="docPickerService.isSelected(item) || docPickerService.isExisting(item) ? 'visible': 'invisible'"
          *ngIf="!isFolder(item)">
          <button class="size-6 rounded-full grid place-content-center hover:text-indigo-500 hover:bg-indigo-50"
            (click)="openPreview(item)" *ngIf="!isFolder(item)" ngxTippy="Previsualizar">
            <i class="fa-solid fa-magnifying-glass" style="font-size: 0.9rem;"></i>
          </button>
          <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
            *ngIf="docPickerService.isSelected(item) || docPickerService.isExisting(item)">
            <i class="fa-solid fa-check text-[0.9rem]"></i>
          </div>
          <div *ngIf="isTooBig(item)" class="text-xs font-medium text-slate-500 text-nowrap">
            <i class="fa-regular fa-triangle-exclamation mr-1"></i>
          </div>
        </div>
      </button>
    </div>

    <!-- Espacio para mostrar las sub-carpetas o documentos dentro una carpeta -->
    <div class="pl-5">
      <app-template-data [items]="getFolderItems(item)" *ngIf="isFolder(item) && item.open" [parentId]="item.idFolder"
        [selectedFolder]="selectedFolder" />
    </div>
  </ng-container>

  <!-- Empty state si no hay carpetas o documentos dentro del asunto -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-2"
    *ngIf="!items[0] && (!isLoading() || parentId && selectedFolder?.idFolder != parentId)">
    <div class="bg-indigo-100 size-8 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[0.8rem]"></i>
    </div>
    <span class="text-center font-medium text-sm">No encontramos documentos en esta ubicación</span>
  </div>
</div>