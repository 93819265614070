<div class="@container bg-white text-slate-800 rounded-lg border border-slate-200 shadow-sm mb-1 flex flex-col"
  *ngIf="dossier && !(loading | async)">
  <div class="flex min-w-0 gap-4 p-4 ">
    <a class="rounded w-24 h-28 bg-slate-50 border border-slate-200 flex flex-col px-3 py-2 cursor-pointer shrink-0"
      [routerLink]="['/law-firm/files/file-overview', dossier.idDossier]" target="_blank">
      <div class="flex items-center justify-center my-auto text-xl">
        💼🏛️
      </div>
    </a>
    <div class="flex flex-col min-w-0 grow">
      <span class="truncate text-left leading-5 text-slate-800 font-semibold gap-4 text-xl" [ngxTippy]="dossier.name">
        {{ dossier.name }}
      </span>
      <div class="text-sm text-slate-500" [ngxTippy]="'Última modificación'">
        {{dossier.updatedAt | date:"dd.MM.yyyy"}}
      </div>

      <!-- Fotos del equipo encargado -->
      <div class="flex shrink-0 -space-x-3 -ml-px">
        <ng-container *ngFor="let member of dossier.memberDossier; let i = index;trackBy:trackByFn">
          <ng-container> <!-- Mostrar solo los primeros 4 elementos -->
            <img [ngxTippy]="member.member.name"
              class="rounded-full border-2 border-white box-content bg-white shadow-inner size-7 shrink-0 grid place-content-center "
              [ngClass]="'cursor-pointer user-comment-' + member.member.fkIdUsersMember"
              [src]="member.member.selfie ? member.member.selfie : '/assets/images/profile-placeholder.png'" width="70"
              height="70" alt="Foto de Perfil" *ngIf="member.member" (error)="onImageError($event)" />
            <div
              class="rounded-full border-2 border-white box-content bg-white shadow-inner size-7 shrink-0 grid place-content-center text-gray-400"
              [ngxTippy]="member.group.name" *ngIf="member.group">
              <i class="fa-regular fa-user-group text-[1.1rem]"></i>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="flex items-center gap-2 mt-4 overflow-x-auto min-w-0">
        <a [routerLink]="['/law-firm/files/file-overview', dossier.idDossier]" target="_blank" [ngxTippy]="'Abrir'" class="flex items-center gap-2 px-2 py-1 border border-indigo-600 rounded-lg text-slate-600 text-sm
          hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-folder-open"></i>
          <span class="hidden @md:inline-block">Abrir</span>
        </a>
        <button *ngIf="false"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-400 text-sm cursor-not-allowed" disabled>
          <i class="fa-regular fa-download"></i>
          <span class="hidden @md:inline-block">Descargar</span>
        </button>
        <button (click)="shareDossier()"
          class="flex items-center gap-2 px-2 py-1 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800"
          ngxTippy="Compartir enlace">
          <i class="fa-regular fa-link-simple rotate-45"></i>
          <span class="hidden @md:inline-block">Compartir</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(loading | async)">
  <div class="border-2 border-slate-200 rounded-lg p-2 animate-pulse flex gap-2">
    <div class="w-32 h-36 rounded-lg bg-slate-200"></div>
    <div class="flex flex-col gap-1">
      <div class="w-40 h-6 rounded-md bg-slate-200"></div>
      <div class="w-20 h-6 rounded-md bg-slate-200"></div>
    </div>
  </div>
</div>