import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, of, switchMap } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { ComponentBlocker } from 'src/app/core/utils/component-blocker';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PdfViewerModalComponent } from 'src/app/shared/components/pdf-viewer-modal/pdf-viewer-modal.component';
import { CollectionsService } from 'src/app/shared/services/collections.service';
import { ResultSourceService } from 'src/app/shared/services/result-source.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { DialogService } from '../../../../core/services/dialog.service';
import { INDEX_LIST } from '../../constants/sources-with-ids.constants';
import { SearchResult } from '../../interfaces/collections.interfaces';
import { AddToCollectionComponent } from '../add-to-collection/add-to-collection.component';
import { ReportResultModalComponent } from '../report-result-modal/report-result-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-preview-result',
    templateUrl: './preview-result.component.html',
    host: {
        class: 'contents',
    },
})
export class PreviewResultComponent {
    favorites = this.collectionsService.favorites;
    searchObjectId: number | undefined;
    resultId: string | null = null;
    index: string | null = null;
    resultDetail: any = null;
    leyesFederales = INDEX_LIST.LEYES;
    leyesEstatales = INDEX_LIST.LEYES_ESTATALES;
    currentTab: number = 0;
    resources = RESOURCES;
    messages: any = {};
    cycle: number = 0;
    userData?: UserData;
    visibleAttributes: number | null = 4;
    loading = toSignal(
        this.blocker.loaderAll
            .asObservable()
            .pipe(
                switchMap((val) =>
                    of(
                        val.global.count &&
                            val.global.paths.includes(
                                this.resources.searchDetail
                            )
                    )
                )
            )
    );
    invalidSummaries = new Set([
        '--null--',
        '--processing--',
        '--same_as_doc--',
    ]);

    constructor(
        private translateService: TranslateService,
        private apiService: UisrApiServiceV2,
        private toastr: ToastrService,
        private store: Store,
        private dialogService: DialogService,
        private blocker: ComponentBlocker,
        private collectionsService: CollectionsService,
        public sourceService: ResultSourceService,
        public dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: SearchResult
    ) {
        this.store
            .pipe(select(UserDataFull), untilDestroyed(this))
            .subscribe((data) => {
                this.userData = data;
            });
        if (this.data) {
            this.index = this.data.index;
            this.resultId = this.data.id;
            this.searchObjectId = this.data.idSearchObject;
            this.currentTab = this.data.currentTab || 0;
        }
    }

    async ngOnInit(): Promise<void> {
        this.messages = await this.getMessages();
        this.getResultData();
    }

    changeVisibleAttributes() {
        if (this.visibleAttributes) {
            this.visibleAttributes = null;
        } else {
            this.visibleAttributes = 4;
        }
    }

    addToCollections(result: any) {
        let name = '';

        if (result.augmented_name) {
            name = result.augmented_name;
        }
        if (result?._source?.augmented_name) {
            name = result?._source?.augmented_name;
        }
        if (result.name) {
            name = result.name;
        }
        if (result?._source?.name) {
            name = result?._source?.name;
        }
        const parsedResult = {
            id: this.resultId,
            index: this.index,
            name: name,
        };
        this.dialogService
            .openDialog(AddToCollectionComponent, {
                data: parsedResult,
            })
            .subscribe((res) => {
                if (res) {
                    this.collectionsService.loadUserCollections();
                }
            });
    }

    startReport() {
        this.dialogService.openDialog(ReportResultModalComponent, {
            data: { id: this.resultId, index: this.index },
        });
    }

    changeTab(newTab: number): void {
        if (newTab === this.currentTab) {
            return;
        }
        this.currentTab = newTab;
    }

    parseHeader(result: any) {
        let obj: any = {};
        result.header.split('\n').forEach((line: string) => {
            const [key, value] = line.split(': ');
            obj[key] = value;
        });

        obj = UisrTools.removeDuplicateProperties(obj, this.resultDetail);
        obj = UisrTools.removeEmptyProperties(obj);

        return obj;
    }

    parseDate(originalDate: string) {
        let date = DateTime.fromFormat(originalDate, 'yyyy-mm-dd').toFormat(
            'mm/dd/yyyy'
        );
        return date != 'Invalid DateTime' ? date : originalDate;
    }

    getResultData() {
        let data = {
            opensearchId: this.resultId,
            index: this.index,
            collection: true,
            id_search_object: this.searchObjectId,
        };
        this.apiService
            .post(this.resources.searchDetail, data, null, ['global'])
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (res: any) => {
                    this.resultDetail = res.data;

                    if (this.resultDetail.attributes) {
                        this.resultDetail.attributes =
                            this.sourceService.parseAttributes(
                                this.resultDetail
                            );
                    }
                    if (this.resultDetail.keywords) {
                        this.resultDetail.keywords =
                            this.resultDetail.keywords.split(/\n/g);
                        this.resultDetail.keywords.forEach(
                            (keyword: string) => {
                                keyword.trim();
                                if (keyword && keyword.endsWith('.')) {
                                    keyword = keyword.slice(0, -1);
                                }
                            }
                        );
                    }
                    this.parseMagistrados();
                    if (this.resultDetail.header) {
                        this.resultDetail.header = this.parseHeader(
                            this.resultDetail
                        );
                    }
                    this.resultDetail = UisrTools.removeEmptyProperties(
                        this.resultDetail
                    );
                },
            });
    }

    parseMagistrados() {
        if (
            !this.resultDetail.magistrados ||
            typeof this.resultDetail.magistrados != 'string'
        )
            return;
        this.resultDetail.magistrados = this.resultDetail.magistrados.split([
            ',',
        ]);
    }

    getVarTypeOf(value: any): string {
        return typeof value;
    }

    async getMessages() {
        let messages: any = await lastValueFrom(
            this.translateService.get([
                'GENERIC_ERROR_MESSAGE',
                'SUMMARY_ERROR_TITLE',
                'GENERIC_FORM_MESSAGE',
                'MISSING_RESULT_DATA_STRING',
                'DOCUMENT_REMOVED_FROM_COLLECTION',
                'DELETING_ENTRY_STRING',
                'DOCUMENT_ADDED_TO_COLLECTION_STRING',
            ])
        );

        return messages;
    }

    isFavorite(): boolean {
        let isFavorite = this.favorites().some(
            (favorite) =>
                favorite.id_open_search == this.resultId &&
                favorite.fk_id_index ==
                    this.sourceService.getSourceId(this.index!)
        );
        return isFavorite;
    }

    deleteResultFromFavorites() {
        this.toastr.info(this.messages.DELETING_ENTRY_STRING);
        const favMatch = this.favorites().find(
            (fav: any) => fav.id_open_search == this.resultId
        );
        if (favMatch) {
            let data = {
                id_search_object: favMatch.id_search_object,
            };
            this.apiService
                .delete(
                    this.resources.deleteDocumentFromCollection,
                    data,
                    null,
                    ['button']
                )
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (res: any) => {
                        this.toastr.clear();
                        if (res.success) {
                            this.toastr.success(
                                this.messages.DOCUMENT_REMOVED_FROM_COLLECTION
                            );
                            this.collectionsService.loadUserCollections();
                        } else {
                            let message = this.messages.GENERIC_ERROR_MESSAGE;
                            if (res.code) {
                                let messageAux = this.translateService.instant(
                                    res.code
                                );
                                message =
                                    messageAux != res.code
                                        ? messageAux
                                        : message;
                            }
                            Swal.fire({
                                ...ALERT_DEFAULTS,
                                text: message,
                            });
                        }
                    },
                    error: () => this.toastr.clear(),
                });
        }
    }

    manageFavorite(result: any) {
        if (this.isFavorite()) {
            this.deleteResultFromFavorites();
            return;
        }
        const data = {
            heading: result.augmented_name || result.name,
            id_open_search: this.resultId,
            fk_id_index: this.sourceService.getSourceId(this.index!),
            fk_id_type_collection: 1,
        };
        this.apiService
            .put(this.resources.addDocumentToCollection, data, null, ['button'])
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (res: any) => {
                    if (res.success) {
                        this.toastr.success(
                            this.messages.DOCUMENT_ADDED_TO_COLLECTION_STRING
                        );
                        this.collectionsService.loadUserCollections();
                    } else {
                        let message = this.messages.GENERIC_ERROR_MESSAGE;
                        if (res.code) {
                            let messageAux = this.translateService.instant(
                                res.code
                            );
                            message =
                                messageAux != res.code ? messageAux : message;
                        }
                        Swal.fire({
                            ...(ALERT_DEFAULTS as any),
                            text: message,
                        });
                    }
                },
            });
    }

    openPdf(urlPdf: any): void {
        this.dialogService.openSidePanel(PdfViewerModalComponent, {
            data: urlPdf,
        });
    }
}
