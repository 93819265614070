<div
  class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center min-w-0 gap-4">
  <span class="font-semibold text-primary-100 truncate">Instrucciones para Amparo IA</span>
  <div class="flex">
    <!-- Cerrar el modal -->
    <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
      class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
      (click)="close()">
      <span class="sr-only" translate="CLOSE_STRING"></span>
      <i class="fa-solid text-[1rem] fa-xmark"></i>
    </button>
  </div>
</div>

<div class="overflow-auto p-5 max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl" *ngIf="promptInfo">
  <div class="flex flex-col gap-2">
    <h2 class="font-medium text-lg">
      <i class="fa-regular fa-file"></i>
      {{ promptInfo.prompt_title }}
    </h2>

    <pre class="text-slate-600 whitespace-pre-wrap font-sans" *ngIf="promptInfo.prompt_template">{{ promptInfo.prompt_template }}</pre>

  </div>

</div>
<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2">
  <button class="uisr-secondary-button bg-slate-100" (click)="close()" translate="BACK_STRING">
  </button>
</div>