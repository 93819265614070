<div *ngIf="!assistantChatService.componentStates.editor"
	class="@container z-[100] absolute shadow-lg overflow-hidden max-h-[calc(100svh-3.625rem)] sm:max-h-[calc(100svh-5.625rem)] transition-all"
	[ngClass]="{
		'w-full h-full right-0 bottom-0 rounded-none sm:border sm:border-slate-200 sm:right-8 sm:bottom-8 sm:rounded-xl sm:h-[80vh] sm:w-[70svw] xl:w-[60svw] 2xl:w-[55svw]': !assistantChatService.componentStates.close,
		'!w-0 !h-0 !right-8 !bottom-8 !rounded-full !border !border-slate-200': assistantChatService.componentStates.close,
		'sm:!w-full sm:!h-full sm:!right-0 sm:!bottom-0 !rounded-none !border-none !max-h-[calc(100svh-3.625rem)]': assistantChatService.componentStates.maximize,
	}">
	<div class="relative w-full h-full">
		<div (click)="assistantChatService.open()"
			class="absolute w-full h-full top-0 left-0 bg-white z-[1] transition-all flex items-center justify-center cursor-pointer select-none"
			[ngClass]="assistantChatService.componentStates.close ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'">
			<img class="size-10 rounded-full" src="/assets/images/amparo-isotipo.webp" alt="MiDespacho Chat Logo" />
		</div>
		<div class="absolute w-full h-full top-0 left-0">
			<div class="relative flex h-full bg-slate-100">

				<!-- Messages sidebar -->
				<div
					class="absolute top-0 bottom-0 left-0 h-full w-full @xl:w-auto @xl:static @xl:top-auto @xl:bottom-auto -mr-px transition-all"
					[ngClass]="{ '-translate-x-full pointer-events-none': !assistantChatService.componentStates.threadsMenu }">
					<div class="sticky bg-white overflow-hidden shrink-0 border-r border-slate-200 @xl:w-72 h-full">

						<div class="h-full flex flex-col">
							<!-- Header -->
							<div class="sticky top-0 z-10">
								<div class="flex items-center bg-white border-b border-slate-200 px-5 h-16">
									<div class="w-full flex items-center justify-between">

										<!-- Nombre Asistente -->
										<img class="h-12 rounded-full mr-2" src="/assets/images/amparo-logo.webp" height="32"
											alt="Amparo IA" />

										<div class="flex">
											<!-- botón de información -->
											<button id="assistant-tour-button" (click)="assistantChatService.showInfoContent()"
												class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
												ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Información'">
												<i class="fa-duotone fa-info-circle opacity-75 group-hover:opacity-100"
													style="font-size: 1rem"></i>
											</button>
											<!-- Ocultar sidebar de conversaciones -->
											<div class="flex">
												<button
													(click)="assistantChatService.componentStates.threadsMenu = !assistantChatService.componentStates.threadsMenu"
													class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
													ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }"
													[ngxTippy]="'Ocultar Conversaciones'">
													<i class="fa-duotone fa-arrow-left-from-line group-hover:opacity-100"
														style="font-size: 1rem"></i>
												</button>
											</div>

										</div>
									</div>
								</div>
							</div>

							<app-chat-threads></app-chat-threads>

						</div>
					</div>
				</div>

				<!-- Messages body -->
				<div class="absolute top-0 bottom-0 w-full right-0 h-full flex flex-col overflow-hidden transition-all"
					[ngClass]="assistantChatService.componentStates.threadsMenu ? 'translate-x-full @xl:translate-x-0 @xl:w-[calc(100%-18rem)]' : 'w-full'">

					<!-- Header -->
					<app-chat-thread-header [threadName]="threadName" (closeClick)="assistantChatService.close()" />

					<!-- Thread -->
					<app-chat-thread></app-chat-thread>

				</div>
			</div>
		</div>
	</div>
</div>