<div
    class="px-5 py-3 border-b bg-pearl-100   border-slate-200   sticky top-0 flex justify-between items-center">
    <span class="font-semibold text-primary-100" [translate]="'CREATE_NEW_COLLECTION_STRING'">
    </span>
    <!-- Cerrar el modal -->
    <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
        class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
        (click)="dialogRef.close()">
        <span class="sr-only" translate="CLOSE_STRING"></span>
        <i class="fa-solid text-[1rem] fa-xmark"></i>
    </button>
</div>
<div class="overflow-auto flex flex-col gap-3 p-5 max-h-[calc(85svh-11.4rem)]">
    <p class="text-sm">{{ 'CREATE_COLLECTION_PROMPT' | translate }}</p>
    <form [formGroup]="addCollectionForm" class="mt-3 flex flex-col gap-4">
        <div *ngFor="let control of addCollectionControls">
            <app-input-generic [loadingElement]="resources.createCollection" [formControlName]="control.name" [objectConfig]="control"></app-input-generic>
        </div>
    </form>
</div>
<div class="py-5 px-4 border-t bg-pearl-100   border-slate-200   sticky bottom-0 flex flex-wrap justify-end space-x-2">
    <button [loadingElement]="resources.createCollection" [disableOnly]="true" (click)="dialogRef.close()" class="uisr-secondary-button">{{'CANCEL_STRING' | translate | titlecase}}</button>
    <button [loadingElement]="resources.createCollection" (click)="createCollection()" class="uisr-primary-button">{{ 'CREATE_STRING' | translate }}</button>
</div>