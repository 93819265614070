import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
  delay,
  distinctUntilKeyChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import Step from 'shepherd.js/src/types/step';
import { Logout } from 'src/app/core/reducer/user-data/user-data.actions';
import { USER_GROUP_STEPS } from 'src/app/features/law-firm-settings/constants/groups.steps';
import { ADD_MEMBER_STEPS } from 'src/app/features/law-firm-settings/constants/law-firm-settings.steps';
import { getCreateDossierSteps } from 'src/app/features/law-firm/constants/create-dossier.steps';
import {
  CLIENTS_STEPS,
  DOSSIER_STEPS,
  FILE_TABS_STEPS,
  INVOICE_STEPS,
} from 'src/app/features/law-firm/constants/law-firm.steps';
import { BLOG_STEPS } from 'src/app/features/library/constants/blog.steps';
import { LIBRARY_STEPS } from 'src/app/features/library/constants/library.steps';
import {
  COLLECTIONS_STEPS,
  getSearchSteps,
} from 'src/app/features/search/constants/search.steps';
import { Payment } from 'src/app/features/settings/interfaces/payment.interfaces';
import { JourneyCompleteModal } from 'src/app/features/updates/components/journey-complete/journey-complete.modal';
import { UserJourneyModal } from 'src/app/features/updates/components/user-journey/user-journey.modal';
import { AssistantChatService } from 'src/app/shared/components/assistant-chat/services/assistant-chat.service';
import { MenuModule } from 'src/app/shared/modules/menu.module';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { FilterByBoolPipe } from 'src/app/shared/pipes/filter-by-bool.pipe';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { environment } from 'src/environments/environment';
import { IntercomService } from '../../../shared/services/intercom.service';
import { RESOURCES } from '../../constants/resource-service.constants';
import { CONFIGURATION_STEPS, EXPLORADOR_DIGITAL_STEPS, INNOVADOR_LEGAL_STEPS, VERSION_DATE, VISIONARIO_JURIDICO_STEPS } from '../../constants/user-journey.constants';
import { ValidateAccessDirective } from '../../directives/validate-access.directive';
import { APIResponse } from '../../interfaces/api.interface';
import { Notification } from '../../models/notification.model';
import { UserData } from '../../models/user-data';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { UisrAnalyticsService } from '../../services/analytics.service';
import { ContextService } from '../../services/context.service';
import { AppTutorialService } from '../../services/tutorial.service';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { WebSocketService } from '../../services/v2-socket.io.service';
import { UserJourneyService } from '../../services/user-journey.service';
import { DialogService } from '../../services/dialog.service';
import { REPORTS_STEPS } from 'src/app/features/reports/constants/report.steps';
import { CREATE_REPORT_STEPS } from 'src/app/features/reports/constants/create-report.steps';
import { ContactInfoComponent } from "../../../shared/components/contact-info/contact-info.component";

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxTippyModule,
    RouterModule,
    MenuModule,
    FormsModule,
    FilterByBoolPipe,
    ContactInfoComponent
],
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly subscriptionService = inject(SubscriptionService);

  userData?: UserData;
  isDarkTheme = false;
  showInfoMenu = true;
  showSpaceChange = false;
  isMenuOpen = false;
  showNotificationsMenu = false;
  resources = RESOURCES;
  photoPlaceholder = '/assets/images/profile-placeholder.png';
  steps: Step.StepOptions[] = [];
  doCdnUrl = environment.cdnUrl;
  public newNotifications: boolean = false;
  public notifications: Notification[] = [];
  completed: boolean = false;
  stepsByUrl: { [key: string]: { exact: boolean; steps: Step.StepOptions[], name?: string } } =
    {
      '/law-firm/files/file-overview/': {
        exact: false,
        steps: FILE_TABS_STEPS,
      },
      '/law-firm/clients': { exact: true, steps: CLIENTS_STEPS },
      '/search/b-search/results': { exact: true, steps: [] },
      '/law-firm/settings/members': { exact: true, steps: ADD_MEMBER_STEPS },
      '/law-firm/settings/groups': { exact: true, steps: USER_GROUP_STEPS },
      '/law-firm/library/blog': { exact: true, steps: BLOG_STEPS },
      '/law-firm/e-collections': { exact: true, steps: INVOICE_STEPS },
      '/law-firm/files/add': { exact: true, steps: [] },
      '/search/collections': { exact: true, steps: COLLECTIONS_STEPS },
      '/law-firm/files': { exact: true, steps: DOSSIER_STEPS },
      '/law-firm/library': { exact: true, steps: LIBRARY_STEPS },
      '/law-firm/reports': { exact: true, steps: REPORTS_STEPS, name: 'reports-tutorial' },
      '/law-firm/reports/create': { exact: true, steps: CREATE_REPORT_STEPS },
    };
  path: any;
  onlyShow: boolean = true;
  notificationUpdate: number[] = [];
  lastPayment?: Payment;
  idLastPayment: number | undefined;
  showPaymentErrorBanner: boolean = true;
  pageSize = 10;
  pageNumber = 1;
  isPaginationComplete = false;
  isLoading = false;
  private cancelPreviousRequest$ = new Subject<void>();

  journeySteps: any[] = [];

  @Output() openSidebarRequest: EventEmitter<boolean> = new EventEmitter();

  readonly assistantChatService = inject(AssistantChatService);
  readonly userJourneyService = inject(UserJourneyService);
  private readonly intercomService = inject(IntercomService);
  private readonly socketService = inject(WebSocketService);
  private dialogService = inject(DialogService);
  tutorialName: string | undefined;

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
    private authService: UisrAuthService,
    private apiService: UisrApiServiceV2,
    public tutorialService: AppTutorialService,
    private contextService: ContextService,
    private analytics: UisrAnalyticsService
  ) {
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilKeyChanged('id_users'),
        untilDestroyed(this)
      )
      .subscribe(async (data) => {
        this.userData = data;
        this.translateService.setDefaultLang(
          this.userData.id_languages
            ? this.userData.id_languages == 1
              ? 'es'
              : 'en'
            : 'es'
        );
        this.isDarkTheme = this.userData.id_themes_preference == 2;
      });
  }

  ngOnInit(): void {
    this.getLastPayment();
    this.subscribeToComponentContexts();
    this.path = this.router.url.split('?')[0] as keyof typeof this.stepsByUrl;
    this.setStepsByUrl();
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.path = event.urlAfterRedirects.split(
          '?'
        )[0] as keyof typeof this.stepsByUrl;
        this.setStepsByUrl();
      });
    this.setThemeClass();
    this.getNotify();

    this.socketService.getNotificationUpdateUser
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => {
        if (data) {
          this.pageSize = 10;
          this.pageNumber = 1;
          this.notifications = [];
          this.isPaginationComplete = false;
          this.getNotify();
        }
      });

    const sub = this.subscriptionService.subscription$.value;
    if (sub?.bolDelete === false) {
      this.userJourneyService.onUserJourneyChange();
      this.userJourneyService.getUserJourney();
    }
  }

  closePaymentErrorBanner() {
    this.showPaymentErrorBanner = false;
  }

  getLastPayment() {
    const code = this.userData?.id_users;
    if (code) {
      this.apiService
        .get(`${RESOURCES.paymentsByWorkspace}${code}`, {
          pageNumber: 1,
          pageSize: 1,
          orderBy: 'id',
          order: 'DESC',
        })
        .subscribe({
          next: (res: APIResponse<Payment[]>) => {
            this.lastPayment = res.total ? res.data[0] : undefined;
            if (this.lastPayment) {
              this.idLastPayment = this.lastPayment.id;
              // Trabajar con localStorage para guardar o actualizar idLastPayment
              const storedId = localStorage.getItem('idLastPayment');
              if (!storedId) {
                // No hay un ID almacenado, guardar el nuevo ID
                localStorage.setItem(
                  'idLastPayment',
                  this.idLastPayment.toString()
                );
              } else if (this.idLastPayment.toString() !== storedId) {
                // El ID almacenado es diferente, actualizarlo con el nuevo ID
                localStorage.setItem(
                  'idLastPayment',
                  this.idLastPayment.toString()
                );
                this.showPaymentErrorBanner = true;
              }
            }
          },
        });
    }
  }

  /** Método para suscribirse a contextos de componentes que no son children directos de HeaderComponent
   *  pero que se usan para los tutoriales.
   */
  subscribeToComponentContexts() {
    // Este valor se emite cuando el componente de búsqueda de asuntos se inicializa
    this.contextService
      .getSearchComponentContext()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (val) => {
          this.stepsByUrl['/search/b-search/results'].steps =
            getSearchSteps(val);
          this.setStepsByUrl();
        },
      });

    // Este valor se emite cuando el componente de creación de asuntos se inicializa
    this.contextService
      .getDossierAddComponentContext()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (val) => {
          this.stepsByUrl['/law-firm/files/add'].steps =
            getCreateDossierSteps(val);

          this.setStepsByUrl();
        },
      });
  }

  /** Método para obtener los pasos de un tutorial según la URL actual, si es que existen.*/
  setStepsByUrl() {
    const routeKey = Object.keys(this.stepsByUrl).find(
      (key) =>
        (this.path.includes(key) && !this.stepsByUrl[key].exact) ||
        this.path === key
    );

    if (routeKey) {
      this.steps = this.stepsByUrl[routeKey].steps || [];
      this.tutorialName = this.stepsByUrl[routeKey].name;
    } else {
      this.steps = [];
      this.tutorialName = '';
    }
  }

  getNotify() {
    if (this.isPaginationComplete) return;
    this.isLoading = true;

    // Emitir una señal de cancelación para la solicitud anterior
    this.cancelPreviousRequest$.next();

    this.apiService
      .get(this.resources.notificationExtended, {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      })
      .pipe(
        delay(500), // Esperar 500ms antes de continuar con la consulta
        takeUntil(this.cancelPreviousRequest$)
      )
      .subscribe({
        next: (res: APIResponse<Notification[]>) => {
          // Combinar las notificaciones de this.notifications y res.data, dando prioridad a las de res.data
          const combinedNotifications = [
            ...this.notifications,
            ...res.data.map((item: Notification) => {
              const regex = /\$\(\|\d+\|\)/g;
              const match = item.notification.text.match(regex);

              if (match && item.notification.idNotifyType != '6') {
                match.forEach((element) => {
                  const id = element
                    .replace(/\$\(\|/g, '')
                    .replace(/\|\)/g, '');
                  item.idDossier = id;
                  item.notification.text = item.notification.text.replace(
                    element,
                    ''
                  );
                });
              }

              const regexAgent = /\(\?\)event=([a-f0-9]+)\(\?\)/g;
              const matchAgent = item.notification.text.match(regexAgent);

              if (matchAgent && item.notification.idNotifyType == '6') {
                matchAgent.forEach((element) => {
                  const id = element.replace(/\(\?\)event=/g, '').replace(/\(\?\)/g, '');
                  item.idEvent = id;
                  item.notification.text = item.notification.text.replace(element, '');
                });
              }
              const regexParams = /%\|.*?\|%/g;
              const matchParams = item.notification.text.match(regexParams);

              if (matchParams) {
                // Initialize an object to store actions and parameters
                item.params = {};

                matchParams.forEach((element) => {
                  const actionString = element
                    .replace(/%\|/g, '')
                    .replace(/\|%/g, '');
                  const parameters = actionString.split(';');
                  parameters.forEach((param) => {
                    const [key, value] = param.split(':'); // Split each pair by `:`
                    item.params[key.trim()] = value.trim(); // Store the key-value pair in the object
                  });

                  // Remove the entire action placeholder from the notification text
                  item.notification.text = item.notification.text.replace(
                    element,
                    ''
                  );
                });
              }
              // Nuevo regex para identificar el patrón '(?)event=${createdEvent._id}(?)'
              const regexEvent = /\(\?\)(.*?)\(\?\)/g;
              const matchEvent = item.notification.text.match(regexEvent);

              if (matchEvent) {
                matchEvent.forEach((element) => {
                  // Extraer el contenido dentro de '(?)' y '(?)'
                  const eventId = element.replace(/\(\?\)/g, '');
                  // Crear el enlace al calendario
                  item.linkCalendar = `calendar?${eventId.trim()}`;

                  // Remove the entire event placeholder from the notification text
                  item.notification.text = item.notification.text.replace(element, '');
                });
              }

              return item;
            }),
          ];
          // Usar un objeto para almacenar las notificaciones únicas, dando prioridad a las de res.data
          const uniqueNotifications = combinedNotifications.reduce(
            (acc: any, notification: Notification) => {
              acc[notification.idNotificationDetail] = notification;
              return acc;
            },
            {}
          );
          // Convertir el objeto de notificaciones únicas de nuevo a un array
          this.notifications = Object.values(uniqueNotifications);
          let total: any = res.total;
          if (this.notifications.length >= total && total != 0) {
            this.isPaginationComplete = true;
          }
          // Incrementa el número de página para la próxima solicitud
          this.pageNumber++;
          this.newNotifications = this.notifications.some(
            (item: Notification) => item.bolNotify == false
          );
        },
        error: (err) => {
          console.error('Error fetching notifications:', err);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  onlyMarkAsRead(Notification: Notification) {
    if (Notification.bolNotify === true) return;
    Notification.bolNotify = true;
    this.notificationUpdate.push(Notification.idNotificationDetail);
    this.apiService
      .patch(
        `${this.resources.notificationStatus}/${Notification.idNotificationDetail}`,
        {}
      )
      .subscribe({
        next: (value) => {
          this.onlyShow = !this.onlyShow;
          this.onlyShow = !this.onlyShow;
          this.notificationUpdate = this.notificationUpdate.filter(
            (item) => item !== Notification.idNotificationDetail
          );
        },
        error: (err) => {
          Notification.bolNotify = true;
        },
      });
  }

  isNotificationUpdate(Notification: Notification): boolean {
    return this.notificationUpdate.filter(
      (el) => el == Notification.idNotificationDetail
    ).length > 0
      ? true
      : false;
  }

  onMenuClosed() {
    let getIsBolNotify = this.notifications.some(
      (item: Notification) => item.bolNotify === false
    );
    if (getIsBolNotify) {
      this.onlyShow = true;
    } else {
      this.onlyShow = false;
    }
  }

  markAsRead(notification: Notification) {
    if (
      notification.notification.idNotifyType === '4' ||
      notification.notification.idNotifyType === '1'
    ) {
      this.router.navigate(
        [`/law-firm/files/file-overview/${notification.idDossier}`],
        {
          queryParams: notification.params,
        }
      );
    } else if (notification.notification.idNotifyType === '2') {
      this.router.navigate([`/law-firm/settings/info`]);
    } else if (notification.notification.idNotifyType === '3') {
      if (notification.idDossier) {
        this.router.navigate(
          [`/law-firm/files/file-overview/${notification.idDossier}`],
          {
            queryParams: notification.params,
          }
        );
      } else {
        this.router.navigate([`/law-firm/invitations`]);
      }
    } else if (notification.notification.idNotifyType === '5') {
      this.router.navigate([`/main`]);
    } else if (notification.notification.idNotifyType === '6') {

      if (notification.idEvent) {
        this.router.navigate(
          [`/calendar`],
          {
            queryParams: {
              event: notification.idEvent,
            }
          }
        );
      } else {
        console.error('No se pudo extraer el eventId del linkCalendar');
      }


    }

    if (notification.bolNotify === true) return;
    notification.bolNotify = true;
    this.apiService
      .patch(
        `${this.resources.notificationStatus}/${notification.idNotificationDetail}`,
        {}
      )
      .subscribe({
        error: (err) => {
          notification.bolNotify = true;
        },
      });
  }

  setThemeClass() {
    if (this.isDarkTheme === true) {
      document.getElementsByTagName('body')[0].classList.add('dark');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('dark');
    }
  }

  signOut() {
    this.intercomService.shutdownIntercom();
    this.authService.clearStorage();
    this.store.dispatch(new Logout());
    this.router.navigateByUrl('/security/login');
  }

  ngOnDestroy(): void {
    this.socketService.disconnectFromServer();
    this.authService.tokenUnsubscribe();
    window.removeEventListener(
      'online',
      this.socketService.handleConnectionChanged
    );
    window.removeEventListener(
      'offline',
      this.socketService.handleConnectionChanged
    );
  }

  parsePhoto() {
    let photoUrl = this.userData?.selfie
      ? this.userData?.selfie
      : this.photoPlaceholder;

    return photoUrl;
  }

  sanitizeText(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  sanitizeText2(text: string): string {
    let getOnlyText = text.replace(/<[^>]*>/g, '');
    return getOnlyText;
  }

  calculateTimePassed(date: string): string {
    // Obtener la zona horaria del usuario
    const userTimeZone = this.userData?.time_zone || 'UTC';

    // Convertir las fechas a la zona horaria del usuario
    const dateNow = DateTime.now().setZone(userTimeZone);
    const dateToCompare = DateTime.fromISO(date).setZone(userTimeZone);

    // Calcular la diferencia en milisegundos
    const diff = dateNow.diff(dateToCompare, [
      'years',
      'months',
      'days',
      'hours',
      'minutes',
      'seconds',
    ]);

    const { years, months, days, hours, minutes, seconds } = diff.toObject();

    if (years && years > 0) {
      return `${Math.floor(years)} ${Math.floor(years) === 1 ? 'año' : 'años'}`;
    }
    if (months && months > 0) {
      return `${Math.floor(months)} ${Math.floor(months) === 1 ? 'mes' : 'meses'
        }`;
    }
    if (days && days > 0) {
      return `${Math.floor(days)} ${Math.floor(days) === 1 ? 'día' : 'días'}`;
    }
    if (hours && hours > 0) {
      return `${Math.floor(hours)} ${Math.floor(hours) === 1 ? 'hora' : 'horas'
        }`;
    }
    if (minutes && minutes > 0) {
      return `${Math.floor(minutes)} ${Math.floor(minutes) === 1 ? 'minuto' : 'minutos'
        }`;
    }
    if (seconds && seconds < 60) {
      return 'un momento';
    }
    return 'un momento';
  }
  getImageUrl(notification: Notification): string {
    if (
      notification.notification.idNotifyType !== '3' &&
      notification.notification.member?.user?.selfie
    ) {
      return `${this.doCdnUrl}${notification.notification.member?.user?.selfie}`;
    } else {
      return '/assets/icons/isotipo.jpg';
    }
  }

  showTutorial() {
    this.tutorialService.startTour(this.steps, this.path, this.tutorialName);
  }
  isLoadingMarkAsRead = false;
  markAllAsRead(event: Event) {
    if (this.isLoadingMarkAsRead) return;
    this.isLoadingMarkAsRead = true;
    const ids = this.notifications
      .filter((n) => n.bolNotify === false)
      .map((n) => n.idNotificationDetail);
    this.apiService
      .patch(`${this.resources.notificationStatus}/mark-all-as-read`, { ids })
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.notifications.forEach((n) => (n.bolNotify = true));
            // this.getNotify();
            this.onlyShow = false;
            event.stopPropagation();
          }
        },
        complete: () => {
          this.isLoadingMarkAsRead = false;
        },
      });
  }

  /** Método para abrir o cerrar el asistente */
  toggle() {
    if (this.userData) this.analytics.trackAssistantClick(this.userData);
    this.assistantChatService.toggle();
  }

  openUserJourney() {
    if (this.userData) this.analytics.userJourneyClick(this.userData);

    this.dialogService.openDialog(UserJourneyModal);
  }

  trackCalendarClick() {
    if (this.userData)
      this.analytics.trackCalendarClick(this.userData.id_users, 'header');
  }

  parseCalendarEventNotifyText(text: string): any {
    let string = text.split('(?)')[0];
    let event = text.split('(?)')[1].replace('event=', '');

    return {
      string: string,
      event: event
    }
  }

}
